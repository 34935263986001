<template>
  <transition name="fade" mode="out-in">
    <slot v-if="loaded"></slot>
  </transition>
</template>

<script>
export default {
  name: "PageTransition",
  props: {
    direction: {
      type: String,
      default: "bottom",
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    this.$nextTick(() => (this.loaded = true));
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
