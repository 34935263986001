<template>
  <div class="d-flex flex-column">
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Import custom emission factors</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="start" class="d-flex align-center">
      <v-col md="2">
        <input
          type="file"
          ref="uploadInput"
          class="d-none"
          accept=".xlsx,.xls,.csv"
          @change="handleFileUpload"
        />
        <v-btn
          color="primary"
          class="fill-width"
          @click="uploadSpreadsheet"
          :disabled="parseLoading"
        >
          Upload
        </v-btn>
      </v-col>
      <v-col md="2">
        <v-btn
          color="primary"
          class="fill-width"
          @click="importFactors"
          :disabled="importDisabled"
        >
          Import
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="statusMessage" class="pa-3">
      <v-alert
        :type="statusType ?? 'info'"
        elevation="3"
        class="fill-width white-space-pre-line"
      >
        {{ statusMessage }}
      </v-alert>
    </v-row>
    <v-data-table
      v-if="factors?.length && !parseLoading && !importLoading"
      :headers="headers"
      :items="factors"
      dense
      disable-sort
      fixed-header
      :options.sync="tableOptions"
      :footer-props="tableFooterProps"
    >
      <template
        v-for="header in headers"
        v-slot:[`item.${header.value}`]="{ item }"
      >
        <div
          :key="header.value"
          class="cell-container"
          :class="item.columnErrorMessages[header.index] ? 'error' : ''"
        >
          <v-tooltip
            v-if="header.value === 'validation'"
            :disabled="!item.errorMessages?.length"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                :color="item.hasErrors ? 'error' : 'success'"
              >
                {{ item.hasErrors ? "mdi-alert-circle" : "mdi-check-circle" }}
              </v-icon>
            </template>
            <div>
              <ul>
                <li
                  v-for="message in item.errorMessages"
                  :key="message"
                  class="white-space-pre-line"
                >
                  {{ message }}
                </li>
              </ul>
            </div>
          </v-tooltip>
          <span v-else>{{ getValue(item, header.value) }}</span>
          <div
            v-if="item.columnErrorMessages[header.index]"
            class="flex-grow-1"
          ></div>
          <v-tooltip v-if="item.columnErrorMessages[header.index]" top>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <div>{{ item.columnErrorMessages[header.index] }}</div>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <div
      v-else-if="parseLoading"
      class="justify-center align-center text-center mt-16"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <p class="mt-4">Processing spreadsheet...</p>
    </div>
    <div
      v-else-if="importLoading"
      class="justify-center align-center text-center mt-16"
    >
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <p class="mt-4">Importing emission factors...</p>
    </div>
  </div>
</template>
<script>
import { footerProps } from "@/util/dataTable";
import { appLayout } from "@/util/layout";
import { serialize } from "object-to-formdata";
import get from "lodash/get";

export default {
  layout: appLayout({ title: "Import custom emission factors" }),
  props: {
    activityUnits: Array,
    emissionSectors: Array,
    emissionCategories: Array,
    emissionRegions: Array,
  },
  data() {
    return {
      headerMappings: [],
      factors: [],

      statusType: null,
      statusMessage: null,
      validationErrors: false,

      tableOptions: {
        page: 1,
        itemsPerPage: 500,
      },
      tableFooterProps: {
        ...footerProps,
        itemsPerPageOptions: [50, 100, 500],
      },

      parseLoading: false,
      importLoading: false,
    };
  },
  computed: {
    headers() {
      if (!this.headerMappings) return [];

      const headers = this.headerMappings.map((x) => ({
        text: x.field ?? "Unmatched field",
        value: `values.${x.index}`,
        class: `${x.field ? "" : "factor-import-unmatched-header"}`,
        index: x.index,
      }));

      if (this.validationErrors) {
        headers.unshift({
          text: "Validation",
          value: "validation",
        });
      }

      return headers;
    },
    importDisabled() {
      return (
        this.statusType === "error" ||
        !this.factors.length ||
        this.importLoading
      );
    },
  },
  methods: {
    uploadSpreadsheet() {
      this.$refs.uploadInput.click();
    },
    handleFileUpload(event) {
      if (!event.target.files?.length) return;

      const body = {
        importSpreadsheet: event.target.files[0],
      };

      this.parseLoading = true;

      fetch(this.route("api.emission-factors.parse"), {
        method: "POST",
        body: serialize(body, {
          noFilesWithArrayNotation: true,
          dotsForObjectNotation: true,
          indices: true,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.statusType = data.statusType;
          this.statusMessage = data.statusMessage;
          this.headerMappings = data.headerMappings;
          this.factors = data.parsedEmissionFactors ?? [];
          this.validationErrors = false;
        })
        .finally(() => {
          this.parseLoading = false;

          // Allow the same file to be uploaded again
          this.$refs.uploadInput.value = "";
        });
    },
    importFactors() {
      this.importLoading = true;

      const fieldMappings = {};

      this.headerMappings
        .filter((x) => !!x.field)
        .forEach((mapping) => {
          fieldMappings[mapping.field] = mapping.index;
        });

      const body = {
        emissionFactorsJson: JSON.stringify(this.factors.map((x) => x.values)),
        fieldMappingsJson: JSON.stringify(fieldMappings),
      };

      fetch(this.route("api.emission-factors.import"), {
        method: "POST",
        body: serialize(body, { indices: true, dotsForObjectNotation: true }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.statusType = data.statusType;
          this.statusMessage = data.statusMessage;
          this.factors = data.emissionFactors ?? [];
          this.validationErrors = data.validationErrors;
        })
        .finally(() => (this.importLoading = false));
    },
    getValue(item, path) {
      return get(item, path);
    },
  },
};
</script>
<style scoped>
.white-space-pre-line {
  white-space: pre-line;
}

.cell-container {
  height: 100%;
  width: calc(100% + 32px);
  margin-left: -16px;
  padding: 0 16px;
  display: flex;
  align-items: center;
}
</style>
<style>
.factor-import-unmatched-header span {
  border: 2px solid #ff5252;
  padding: 2px;
  white-space: nowrap;
}
</style>
