<template>
  <v-tab-item class="fill-width min-h-104">
    <div class="fill-height fill-width">
      <v-row>
        <v-col md="4">
          <v-checkbox
            v-if="false"
            solo
            dense
            :input-value="form.moduleFixedAssets"
            :label="'Fixed Assets'"
            :error-messages="form.errors.moduleFixedAssets"
            @change="toggleFixedAssets($event)"
          ></v-checkbox>
          <div v-if="form.moduleFixedAssets">
            <div class="mb-4">
              <v-autocomplete
                v-model="defaultDepreciationMethodIdProxy"
                :items="depreciationMethods"
                item-text="value"
                item-value="id"
                solo
                hide-details
                label="Depreciation method"
                clearable
                :error-messages="form.errors.defaultDepreciationMethodId"
              />
            </div>
            <div class="mb-4">
              <v-autocomplete
                v-model="fiscalYearProxy"
                :items="fiscalYears"
                solo
                hide-details
                label="Fiscal year"
                clearable
                :error-messages="form.errors.fiscalYear"
              />
            </div>
            <div class="mb-4">
              <v-autocomplete
                v-model="defaultStartOfDepreciationIdProxy"
                :items="startOfDepreciations"
                item-text="value"
                item-value="id"
                solo
                hide-details
                label="Start of depreciation"
                clearable
                :error-messages="form.errors.defaultStartOfDepreciationId"
              />
            </div>
          </div>
          <v-checkbox
            solo
            :disabled="true"
            :input-value="form.moduleCo2"
            label="CO2"
            :error-messages="form.errors.moduleCo2"
            @change="updateForm('moduleCo2', $event)"
          ></v-checkbox>
          <v-row v-if="form.moduleCo2" no-gutters class="ml-7 mt-n8">
            <v-col md="12">
              <v-radio-group
                :value="form.defaultAiModel"
                @change="updateForm('defaultAiModel', $event)"
              >
                <v-radio
                  v-for="model in aiModels"
                  :key="model.value"
                  :label="model.label"
                  :value="model.value"
                ></v-radio>
                <v-checkbox
                  solo
                  class="mt-0"
                  :input-value="form.modulePrecursora"
                  label="Precursora"
                  :error-messages="form.errors.modulePrecursora"
                  @change="updateForm('modulePrecursora', $event)"
                ></v-checkbox>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-checkbox
            v-if="false"
            solo
            :input-value="form.moduleAssessments"
            label="Assessments"
            :error-messages="form.errors.moduleAssessments"
            @change="updateForm('moduleAssessments', $event)"
          ></v-checkbox>
          <v-checkbox
            solo
            :input-value="form.moduleEsgActive"
            label="ESG"
            :error-messages="form.errors.moduleEsgActive"
            @change="updateForm('moduleEsgActive', $event)"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="form.moduleEsgActive" no-gutters class="ml-7 mt-n8">
        <v-col md="2">
          <v-radio-group
            :value="form.moduleEsgOption"
            @change="updateForm('moduleEsgOption', $event)"
          >
            <v-radio
              v-bind:key="module"
              v-for="module in esgModules"
              :label="`${module.toUpperCase()}`"
              :value="module"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="displayESRSOptions" md="3">
          <v-checkbox
            :input-value="true"
            disabled
            label="Basic Module"
          ></v-checkbox>
          <v-checkbox
            :input-value="form.moduleEsgNarrativePat"
            label="Narrative-PAT Module"
            :error-messages="form.errors.moduleEsgNarrativePat"
            @change="updateForm('moduleEsgNarrativePat', $event)"
          ></v-checkbox>
          <v-checkbox
            :input-value="form.moduleEsgBusinessPartsners"
            label="Business Partners Module"
            :error-messages="form.errors.moduleEsgBusinessPartsners"
            @change="updateForm('moduleEsgBusinessPartsners', $event)"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    form: Object,
    depreciationMethods: Array,
    fiscalYears: Array,
    startOfDepreciations: Array,
    esgModules: Array,
  },
  emits: ["updateForm"],
  data() {
    return {
      aiModels: [
        { label: "Verarca AI V5 - Annabelle", value: "aiv5" },
        { label: "Verarca AI V6 - Tamerlane", value: "aiv6" },
        { label: "Verarca AI V6.1 - Tamerlane", value: "aiv6.1" },
      ],
    };
  },
  computed: {
    defaultDepreciationMethodIdProxy: {
      get() {
        return this.form.defaultDepreciationMethodId;
      },
      set(value) {
        this.updateForm("defaultDepreciationMethodId", value);
      },
    },
    fiscalYearProxy: {
      get() {
        return this.form.fiscalYear;
      },
      set(value) {
        this.updateForm("fiscalYear", value);
      },
    },
    defaultStartOfDepreciationIdProxy: {
      get() {
        return this.form.defaultStartOfDepreciationId;
      },
      set(value) {
        this.updateForm("defaultStartOfDepreciationId", value);
      },
    },
    displayESRSOptions() {
      return this.form.moduleEsgOption == "vsme"; // only for vsme atm
    },
  },
  methods: {
    updateForm(formKey, value) {
      this.$emit("updateForm", formKey, value);
    },
    toggleFixedAssets(value) {
      if (value === false) {
        this.updateForm("defaultDepreciationMethodId", null);
        this.updateForm("defaultStartOfDepreciationId", null);
      }
      this.updateForm("moduleFixedAssets", value);
    },
  },
};
</script>
