<template>
  <div>
    <section-divider v-if="logicOperator" :text="logicOperator" class="mb-4" />
    <v-row no-gutters class="align-start">
      <v-col cols="4">
        <v-subheader>Trigger rule if field</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-autocomplete
          :value="selectedField"
          :items="fieldOptions"
          :menu-props="{ top: false, offsetY: true }"
          item-text="name"
          :item-value="null"
          class="rounded-0 mb-4"
          color="#F25D3B"
          background-color="#F7F7F7"
          hide-details
          hide-selected
          solo
          flat
          filled
          light
          @change="handleFieldChanged"
        >
          <template #item="{ item }">
            {{ item.name }}
            <span v-if="item.value" class="indigo--text text--lighten-2 ml-2">
              <template v-if="item.value.startsWith('[')">
                {{ item.value }}
              </template>
              <template v-else>({{ item.value }})</template>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row no-gutters class="align-start">
      <v-col cols="4">
        <v-subheader>Sub field</v-subheader>
      </v-col>
      <v-col cols="8">
        <v-autocomplete
          :value="selectedSubField"
          :items="subFieldOptions"
          :menu-props="{ top: false, offsetY: true }"
          item-text="name"
          :item-value="null"
          class="rounded-0 mb-4"
          color="#F25D3B"
          background-color="#F7F7F7"
          hide-details
          hide-selected
          solo
          flat
          filled
          light
          @change="handleSubFieldChanged"
        >
          <template #item="{ item }">
            {{ item.name }}
            <span v-if="item.value" class="indigo--text text--lighten-2 ml-2">
              <template v-if="item.value.startsWith('[')">
                {{ item.value }}
              </template>
              <template v-else>({{ item.value }})</template>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row no-gutters class="align-start">
      <v-col cols="4">
        <v-subheader>Equals</v-subheader>
      </v-col>
      <v-col cols="5">
        <v-text-field
          :value="value"
          :disabled="notNull"
          hide-details="auto"
          clearable
          class="rounded-0 mb-4"
          background-color="#F7F7F7"
          hide-selected
          solo
          flat
          filled
          light
          @input="$emit('valueChanged', $event)"
        />
      </v-col>
      <v-col cols="2" offset="1" class="d-flex justify-end">
        <v-checkbox
          :input-value="notNull"
          label="Or is Not Null"
          class="reverse-checkbox"
          @change="handleNotNullChanged"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import SectionDivider from "../SectionDivider.vue";

export default {
  components: { SectionDivider },
  props: {
    field: String,
    subField: String,
    value: String,
    notNull: Boolean,
    logicOperator: String,
    fieldOptions: Array,
    subFieldOptions: Array,
  },
  emits: [
    "fieldChanged",
    "subFieldChanged",
    "valueChanged",
    "notNullChanged",
    "deleteTrigger",
  ],
  computed: {
    selectedField() {
      if (!this.field) return null;

      return this.fieldOptions.find((x) => x.name === this.field) ?? null;
    },
    selectedSubField() {
      if (!this.selectedField) return null;

      return (
        this.subFieldOptions.find(
          (x) =>
            x.index === this.selectedField.index && x.name === this.subField
        ) ?? null
      );
    },
  },
  methods: {
    handleNotNullChanged(notNull) {
      this.$emit("notNullChanged", notNull);

      if (!notNull) return;

      this.$emit("valueChanged", null);
    },
    setPredefinedValue(value) {
      if (!value || value === "[array]") return;

      this.$emit("valueChanged", value);
    },
    handleFieldChanged(field) {
      this.$emit("fieldChanged", field.name);
      this.$emit("subFieldChanged", null);

      this.setPredefinedValue(field.value);
    },
    handleSubFieldChanged(subField) {
      this.$emit("subFieldChanged", subField.name);

      this.setPredefinedValue(subField.value);
    },
  },
};
</script>
<style scoped>
.reverse-checkbox {
  width: 150px;
}

.reverse-checkbox:deep(.v-input__slot) {
  flex-direction: row-reverse;
}
</style>
