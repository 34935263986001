<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Site Admins</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="space-between" class="d-flex align-center">
      <admin-form
        v-model="showModal"
        :admin="admin"
        @clear="admin = null"
        @reload="reloadAdmin"
      />
      <v-col md="3">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1 row-pointer"
      :headers="headers"
      :items="admins.data"
      :server-items-length="admins.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      must-sort
      @dblclick:row="(event, { item }) => editAdmin(item)"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
      ><template v-slot:item.createdAt="{ item }">
        <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
      </template>
      <template #item.disabledAt="{ item }">
        {{ item.disabledAt ? "Inactive" : "Active" }}
      </template>
      <template #item.action="{ item }">
        <td class="fixed">
          <v-row class="actionrow" justify="center">
            <info-popup :item="item" align="left" />
            <action-menu-button
              title="Login"
              icon="mdi-login"
              :disabled="!!item.disabledAt"
              @click="attemptImpersonation(item)"
            />
            <action-menu-button title="Log" icon="mdi-text-long" />
            <action-menu-button
              title="Edit"
              icon="mdi-pencil"
              @click="editAdmin(item)"
            />
          </v-row>
        </td>
      </template>
    </v-data-table>
    <v-dialog
      :value="errorModalMessage !== null"
      :max-width="350"
      hide-overlay
      @click:outside="errorModalMessage = null"
    >
      <v-card>
        <v-card-title>
          <v-icon class="mr-1" color="red">mdi-alert-circle</v-icon>
          Error occurred!
        </v-card-title>
        <v-card-text>
          {{ errorModalMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="errorModalMessage = null">
            <v-icon class="black--text">mdi-close</v-icon>
            <p class="teal--text text--darken-4 mb-0">CLOSE</p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { footerProps } from "@/util/dataTable";
import { appLayout } from "@/util/layout";
import InfoPopup from "@/Components/InfoPopup";
import ActionMenuButton from "@/Components/ActionMenuButton";
import AdminForm from "./Form.vue";
import qs from "qs";

export default {
  layout: appLayout({ title: "Admins" }),
  components: { InfoPopup, ActionMenuButton, AdminForm },
  props: {
    admins: Object,
    reloadFilters: Object,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Created At", value: "createdAt" },
        { text: "Status", value: "disabledAt" },
        {
          text: "Actions",
          value: "action",
          width: 120,
          sortable: false,
          align: "center fixed",
          class: "fixed",
        },
      ],
      pagination: {
        page: this.admins?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? "name"],
        sortDesc: [searchParams.sortDesc === "true"],
      },
      tableFooterProps: footerProps,
      filters: this.reloadFilters ?? {},
      admin: null,
      showModal: false,
      modalAdmin: null,
      errorModalMessage: null,
    };
  },
  computed: {},
  methods: {
    resetFilter() {
      this.filters = {};
    },
    changePage(options) {
      const query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(this.route("admins.index", query), {
        preserveState: true,
      });
    },
    editAdmin(admin) {
      this.admin = admin;
      this.showModal = true;
    },
    reloadAdmin(adminId) {
      this.admin = this.admins.data.find((x) => x.id === adminId) ?? null;

      if (!this.admin) this.showModal = false;
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = { page: 1, sortBy: ["name"], sortDesc: [false] };
        this.changePage(this.pagination);
      }, 500),
      deep: true,
    },
  },
};
</script>
<style scoped></style>
