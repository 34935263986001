<template>
  <v-tab-item class="fill-width min-h-104">
    <div class="fill-height fill-width">
      <div v-if="!hasIntegration" class="pt-8">
        <p>Organization has no integration</p>
      </div>
      <div v-else>
        <div class="pt-8">
          Integration System: {{ organizationSyncPeriodData.system }}
        </div>
        <div>
          <v-row>
            <v-col md="4">
              <v-autocomplete
                v-model="defaultSyncPeriod"
                :items="integrationSyncPeriods"
                item-text="name"
                item-value="id"
                filled
                hide-details
                label="Sync Period"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-tab-item>
</template>
<script>
import AuthHelpers from "../../../mixins/AuthHelpers.vue";

export default {
  mixins: [AuthHelpers],
  props: {
    organizationSyncPeriodData: Object,
    integrationSyncPeriods: Array,
  },
  data() {
    return {};
  },
  emits: ["updateForm"],
  computed: {
    defaultSyncPeriod: {
      get() {
        if (this.organizationSyncPeriodData) {
          var selection = this.integrationSyncPeriods.find(
            (x) => x === this.organizationSyncPeriodData.syncPeriod
          );

          if (selection) {
            return selection;
          }
        }
        return this.integrationSyncPeriods[0];
      },
      set(value) {
        this.$emit("updateForm", "syncPeriod", value);
        this.$emit(
          "updateForm",
          "integrationId",
          this.organizationSyncPeriodData.integrationId
        );
      },
    },
    hasIntegration() {
      if (
        this.organizationSyncPeriodData &&
        this.organizationSyncPeriodData.integrationId !== null
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
