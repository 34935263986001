<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Precursora AI</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row no-gutters>
      <v-col cols="12" lg="8">
        <form @submit.prevent="submit">
          <v-card elevation="4">
            <v-card-title>
              {{ form.id ? "Update rule" : "Create new rule" }}
            </v-card-title>
            <v-card-text>
              <v-row no-gutters class="align-start">
                <v-col cols="4">
                  <v-subheader>Trigger</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="form.firstTriggerPropertyName"
                    :items="precursoraRuleTriggerProperties"
                    :error-messages="form.errors.firstTriggerPropertyName"
                    :hide-details="!form.errors.firstTriggerPropertyName"
                    class="rounded-0 mb-4"
                    background-color="#F7F7F7"
                    hide-selected
                    solo
                    flat
                    filled
                    light
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="align-start">
                <v-col cols="4">
                  <v-subheader>Keyword</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="form.firstTriggerValue"
                    :error-messages="form.errors.firstTriggerValue"
                    :hide-details="!form.errors.firstTriggerValue"
                    clearable
                    class="rounded-0 mb-4"
                    background-color="#F7F7F7"
                    hide-selected
                    solo
                    flat
                    filled
                    light
                  />
                </v-col>
              </v-row>
              <section-divider full class="mt-2"></section-divider>
              <v-row no-gutters class="align-start mt-4">
                <v-col cols="4">
                  <v-subheader>Custom extraction model</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="form.customExtractionModel"
                    :items="customExtractionModels"
                    :error-messages="form.errors.customExtractionModel"
                    hide-details="auto"
                    class="rounded-0 mb-4"
                    background-color="#F7F7F7"
                    clearable
                    hide-selected
                    solo
                    flat
                    filled
                    light
                  />
                </v-col>
              </v-row>
              <v-divider v-if="!form.customExtractionModel"></v-divider>
              <template v-if="!form.customExtractionModel">
                <v-tabs v-model="actionsTab" show-arrows color="primary">
                  <v-tab
                    v-for="(tab, index) in attributeClasses"
                    :key="index"
                    class="body-2 text-none"
                  >
                    {{ tab.name }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="actionsTab">
                  <template v-for="(tab, index) in attributeClasses">
                    <tab-co2
                      v-if="tab.id === fixedClasses.co2Id"
                      :key="`co2-${tab.id}`"
                      :current-rule-id="form.id"
                      :tab-value="index"
                      :initial-sectors="sectors"
                      :attributes="attributes"
                      :ghg-categories="ghgCategories"
                      :rerender-counter="rerenderCounter"
                      :get-dynamic-value="getDynamicValue"
                      :get-fixed-value="getFixedValue"
                      @setDynamicValue="setDynamicValue"
                      @setFixedValue="setFixedValue"
                    />
                    <tab-general
                      v-else-if="tab.id === fixedClasses.generalId"
                      :key="`general-${tab.id}`"
                      :tab-value="index"
                      :attributes="attributes"
                      :currencies="currencies"
                      :rerender-counter="rerenderCounter"
                      :get-dynamic-value="getDynamicValue"
                      :get-dynamic-second-value="getDynamicSecondValue"
                      :get-fixed-value="getFixedValue"
                      @setDynamicValue="setDynamicValue"
                      @setFixedValue="setFixedValue"
                    />
                    <tab-dynamic
                      v-else
                      :key="tab.id"
                      :tab-value="index"
                      :attribute-class="tab"
                      :attributes="attributes"
                      :currencies="currencies"
                      :get-dynamic-value="getDynamicValue"
                      :get-dynamic-second-value="getDynamicSecondValue"
                      @setDynamicValue="setDynamicValue"
                      @setDynamicSecondValue="setDynamicSecondValue"
                    />
                  </template>
                </v-tabs-items>
              </template>
              <v-divider></v-divider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="form.id"
                text
                color="blue darken-1"
                @click="resetForm"
              >
                <v-icon class="black--text">mdi-cancel</v-icon>
                <p class="teal--text text--darken-4 mb-0">Cancel</p>
              </v-btn>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!formIsFilledOut || formLoading"
                :loading="formLoading"
                @click="submit()"
              >
                <v-icon class="black--text">mdi-content-save-outline</v-icon>
                <p class="teal--text text--darken-4 mb-0">Save</p>
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
      <v-col cols="12" lg="8" class="mt-4">
        <v-card elevation="4">
          <v-card-title>Existing rules</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="existingRuleHeaders"
              :items="precursoraRules.data"
              :server-items-length="precursoraRules.totalCount"
              :options.sync="pagination"
              :footer-props="tableFooterProps"
              must-sort
              @dblclick:row="(_, { item }) => editRule(item)"
              @update:page="changePage(pagination)"
              @update:items-per-page="changePage(pagination)"
              @update:sort-by="changePage(pagination)"
              @update:sort-desc="changePage(pagination)"
            >
              <template #item.trigger="{ item }">
                <span class="font-weight-bold">
                  {{ item.firstTriggerPropertyName }}:
                </span>
                {{ formatTriggerValue(item) }}
              </template>
              <template #item.effect="{ item }">
                <span v-if="getRuleEffectPrefix(item)" class="font-weight-bold">
                  {{ getRuleEffectPrefix(item) }}:
                </span>
                {{ getRuleEffect(item) }}
              </template>
              <template #item.actions="{ item }">
                <v-btn small outlined color="primary" @click="editRule(item)">
                  <v-icon class="black--text">mdi-pencil</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Edit</p>
                </v-btn>
                <v-btn
                  small
                  outlined
                  class="ml-2"
                  color="primary"
                  @click="deleteRule(item)"
                >
                  <v-icon class="black--text">mdi-trash-can-outline</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Delete</p>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { appLayout } from "../../util/layout";
import { fixedAttributes } from "../../util/fixedAssetData";
import { footerProps } from "@/util/dataTable";
import qs from "qs";
import { serialize } from "object-to-formdata";
import {
  assetRuleActionProperty,
  fixedClasses,
} from "../../util/fixedAssetData";
import { removeTimezoneOffset } from "../../util/dateTime";

import TabDynamic from "./Form/TabDynamic.vue";
import TabGeneral from "./Form/TabGeneral.vue";
import TabCo2 from "./Form/TabCo2.vue";

//import TriggerSection from "../../Components/AssetRule/TriggerSection.vue";
import SectionDivider from "../../Components/SectionDivider.vue";

export default {
  layout: appLayout({ title: "Precursora" }),
  components: {
    TabDynamic,
    TabGeneral,
    TabCo2,
    //TriggerSection,
    SectionDivider,
  },
  props: {
    precursoraRules: Object,
    sectors: Array,
    attributeClasses: Array,
    attributes: Array,
    currencies: Array,
    precursoraRuleTriggerProperties: Array,
    ghgCategories: Array,
    customExtractionModels: Array,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      emissionCategories: [],
      emissionRegions: [],
      emissionFactors: [],
      actionsTab: null,
      emissionCategoriesLoading: false,
      emissionRegionsLoading: false,
      emissionFactorsLoading: false,
      pagination: {
        page: this.precursoraRules?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? "keyword"],
        sortDesc: [searchParams.sortDesc === "true"],
      },
      tableFooterProps: footerProps,
      emissionSectorId: null,
      emissionCategoryId: null,
      emissionRegionId: null,
      form: this.$inertia.form({
        id: null,
        firstTriggerPropertyName:
          this.precursoraRuleTriggerProperties[0] ?? null,
        firstTriggerValue: null,
        actions: [],
        customExtractionModel: null,
      }),
      existingRuleHeaders: [
        { text: "ID", value: "referenceKey" },
        { text: "Trigger", value: "trigger" },
        { text: "Effect", value: "effect" },
        { text: "Actions", value: "actions", sortable: false, width: 240 },
      ],
      formLoading: false,
      rerenderCounter: 0,
      fixedClasses,
    };
  },
  methods: {
    submit() {
      this.formLoading = true;

      const transformedForm = this.form.transform((data) =>
        serialize(
          {
            ...data,
            actions: data.actions.map((action) => ({
              ...action,
              attribute: undefined,
              files: undefined,
              fileIds: action.files?.map((file) => file.id),
            })),
          },
          { indices: true, dotsForObjectNotation: true }
        )
      );

      if (this.form.id) {
        transformedForm.put(
          this.route("precursora-rules.update", this.form.id),
          {
            onSuccess: () => {
              this.resetForm();
            },
            onFinish: () => {
              this.formLoading = false;
            },
          }
        );
        return;
      }

      transformedForm.post(this.route("precursora-rules.store"), {
        onSuccess: () => {
          this.resetForm();
        },
        onFinish: () => {
          this.formLoading = false;
        },
      });
    },
    resetForm() {
      this.form = this.$inertia.form({
        id: null,
        firstTriggerPropertyName:
          this.precursoraRuleTriggerProperties[0] ?? null,
        firstTriggerValue: null,
        actions: [],
        customExtractionModel: null,
      });
      this.emissionSectorId = null;
      this.emissionCategoryId = null;
      this.emissionRegionId = null;
    },
    changePage(options) {
      const query = {
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("precursora-rules.index", query),
        {},
        { preserveState: true }
      );
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    editRule(item) {
      this.scrollToTop();
      const confirmText =
        "Are you sure you want to continue? Any changes in the form will be discarded.";

      if (this.form.isDirty && !confirm(confirmText)) return;

      this.form = this.$inertia.form({
        id: item.id,

        firstTriggerPropertyName: item.firstTriggerPropertyName,
        firstTriggerValue: item.firstTriggerValue,

        actions: item.actions ?? [],
        customExtractionModel: item.customExtractionModel,
      });
    },
    deleteRule(item) {
      if (!confirm("Are you sure you want to delete the selected rule?"))
        return;

      this.$inertia.delete(this.route("precursora-rules.destroy", item.id), {
        preserveState: true,
      });

      // if editing for with item id clear form
      if (this.form.id === item.id) {
        this.resetForm();
      }
    },
    getRuleEffectPrefix(precursoraRule) {
      const cem = precursoraRule.customExtractionModel;

      if (cem) {
        return "CEM";
      }

      return null;
    },
    getRuleEffect(precursoraRule) {
      const cem = precursoraRule.customExtractionModel;

      if (cem) {
        return cem;
      }

      const assetActions = precursoraRule.actions?.length;

      return `${assetActions} asset data actions`;
    },
    getFixedValue(propertyName) {
      const action = this.form.actions.find(
        (x) => x.propertyName === propertyName
      );

      if (!action) return null;

      switch (action.propertyName) {
        case assetRuleActionProperty.category:
        case assetRuleActionProperty.emissionFactorName:
        case assetRuleActionProperty.region:
        case assetRuleActionProperty.sector:
        case assetRuleActionProperty.ghgCategory:
          return action.guidValue ?? null;
        default:
          return null;
      }
    },
    setFixedValue(propertyName, value) {
      if (!value) {
        this.form.actions = this.form.actions.filter(
          (x) => x.propertyName !== propertyName
        );
        return;
      }

      let action = this.form.actions.find(
        (x) => x.propertyName === propertyName
      );

      if (!action) {
        action = { propertyName };

        this.form.actions.push(action);
      }

      switch (action.propertyName) {
        case assetRuleActionProperty.category:
        case assetRuleActionProperty.emissionFactorName:
        case assetRuleActionProperty.region:
        case assetRuleActionProperty.sector:
        case assetRuleActionProperty.ghgCategory:
          action.guidValue = value;
          break;
      }

      this.rerenderCounter++;
    },
    getDynamicValue(attributeId) {
      const action = this.form.actions.find(
        (x) => x.attributeId === attributeId
      );

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!action || !attribute) return null;

      switch (attribute.format) {
        case "Text":
        case "TextArea":
          return action.stringValue ?? null;
        case "Number":
        case "Percentage":
        case "Currency":
          return action.decimalValue ?? null;
        case "Date":
        case "DateTime":
          return action.dateTimeValue ?? null;
        case "Boolean":
          return action.boolValue ?? null;
        case "File":
          return action.newFiles ?? null;
        case "Select":
          return action.guidValue ?? null;
        default:
          return null;
      }
    },
    getDynamicSecondValue(attributeId) {
      const action = this.form.actions.find(
        (x) => x.attributeId === attributeId
      );

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!action || !attribute) return null;

      switch (attribute.format) {
        case "Currency":
          return action.stringValue ?? null;
        case "File":
          return action.files ?? null;
        default:
          return null;
      }
    },
    setDynamicValue(attributeId, value) {
      if (!value) {
        this.form.actions = this.form.actions.filter(
          (x) => x.attributeId !== attributeId
        );
        return;
      }

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!attribute) return;

      let action = this.form.actions.find((x) => x.attributeId === attributeId);

      if (!action) {
        action = { attributeId };

        this.form.actions.push(action);
      }

      switch (attribute.format) {
        case "Text":
        case "TextArea":
          action.stringValue = value;
          break;
        case "Number":
        case "Percentage":
          action.decimalValue = value;
          break;
        case "Currency":
          action.decimalValue = value;

          if (!action.stringValue && this.defaultCurrencyCode)
            action.stringValue = this.defaultCurrencyCode;
          break;
        case "Date":
        case "DateTime":
          action.dateTimeValue = value ? removeTimezoneOffset(value) : value;
          break;
        case "Boolean":
          action.boolValue = value;
          break;
        case "File":
          action.newFiles = value;
          break;
        case "Select":
          action.guidValue = value;
          break;
      }

      this.rerenderCounter++;
    },
    setDynamicSecondValue(attributeId, value) {
      if (!value && !this.getDynamicValue(attributeId)) {
        this.form.actions = this.form.actions.filter(
          (x) => x.attributeId !== attributeId
        );
        return;
      }

      const attribute = this.attributes.find((x) => x.id === attributeId);

      if (!attribute) return;

      let action = this.form.actions.find((x) => x.attributeId === attributeId);

      if (!action) {
        action = { attributeId };

        this.form.actions.push(action);
      }

      switch (attribute.format) {
        case "Currency":
          action.stringValue = value;
          break;
      }

      this.rerenderCounter++;
    },
    addTrigger(logicOperator) {
      if (!this.form.secondTriggerLogicOperator) {
        this.form.secondTriggerLogicOperator = logicOperator;
        return;
      }

      if (!this.form.thirdTriggerLogicOperator) {
        this.form.thirdTriggerLogicOperator = logicOperator;
        return;
      }
    },
    formatTriggerValue(precursoraRule) {
      return precursoraRule.firstTriggerValue;
    },
  },
  computed: {
    formIsFilledOut() {
      const hasTriggerProperty = !!this.form.firstTriggerPropertyName;
      const hasFixedAssetName = this.form.actions.some(
        (x) => x.attributeId === fixedAttributes.generalNameId
      );
      const hasTriggerValue = (this.form.firstTriggerValue ?? "").trim() !== "";
      const hasModelValue =
        (this.form.customExtractionModel ?? "").trim() !== "" ||
        hasFixedAssetName;

      const hasActions = this.form.actions.length > 0;

      return (
        hasTriggerProperty && hasTriggerValue && (hasModelValue || hasActions)
      );
    },
    triggerProperties() {
      return [
        this.form.firstTriggerPropertyName,
        this.form.secondTriggerPropertyName,
        this.form.thirdTriggerPropertyName,
      ];
    },
    logicOperators() {
      return [
        this.form.secondTriggerLogicOperator,
        this.form.thirdTriggerLogicOperator,
      ];
    },
  },
  watch: {
    "form.isIgnoreRule": function (value) {
      if (!value) return;

      this.form.actions = [];
    },
  },
};
</script>
