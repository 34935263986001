<template>
  <v-list>
    <v-list-item-group v-model="selectedMenu" class="px-2">
      <v-list-group
        v-for="item in availableItems"
        :append-icon="null"
        :key="item.route"
        :href="generateHref(item)"
        class="v-list-item--dense"
        @click="visit(item)"
        :class="isOnRoute(item) ? 'highlighted' : ''"
      >
        <template v-slot:activator>
          <v-list-item-title :to="item.route">
            {{ item.title }}
          </v-list-item-title>
        </template>
        <v-list-item
          v-for="sub in item.subRoutes"
          :key="sub.route"
          v-inertia
          link
          dense
          active-class=""
          :href="generateHref(sub)"
          :class="isOnRoute(sub, true) ? 'highlighted indented' : 'indented'"
        >
          <v-list-item-content class="black--text text--lighten-2">
            <v-list-item-title>
              {{ sub.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list-item-group>
  </v-list>
</template>

<script>
import AuthHelpers from "../mixins/AuthHelpers.vue";

export default {
  mixins: [AuthHelpers],
  props: {
    url: String,
  },
  computed: {
    selectedMenu: {
      get() {
        return this.url;
      },
      set() {
        //redirects
      },
    },
    availableItems() {
      const roleName = this.getCurrentUserRoleName();

      const itemFilter = (i) =>
        (!i.permission || this.currentUserHasClaim(i.permission)) &&
        (!i.roles || i.roles.includes(roleName));

      let items = this.items.filter(itemFilter);

      items.forEach((item) => {
        if (item.subRoutes) {
          item.subRoutes = item.subRoutes.filter(itemFilter);
        }
      });

      return items;
    },
    user() {
      return this.$inertia.page.props.auth.user;
    },
  },
  methods: {
    calculateFilters(filterType) {
      var searchParams = new URLSearchParams(window.location.search);
      if (filterType == "filters") {
        return {
          filters: {
            organizationId:
              searchParams.get("organizationId") ??
              searchParams.get("filters[organizationId]"),
          },
        };
      }
      if (filterType == "flat") {
        return {
          organizationId:
            searchParams.get("organizationId") ??
            searchParams.get("filters[organizationId]"),
        };
      }
    },
    generateHref(item) {
      if (item.href) return item.href;

      if (!item.route) return undefined;

      if (item.filter != null) {
        return this.route(item.route, this.calculateFilters(item.filter));
      }

      return this.route(item.route);
    },
    isOnRoute(item, isSubRoute = false) {
      if (item.subRoutes != null || !item.route) {
        return false;
      }
      if (this.$page.url.substr(1).toLowerCase() == "") {
        return item.route.split(".")[0] == "dashboard";
      }

      if (isSubRoute) return this.routeRaw().current() === item.route;

      return this.$page.url
        .substr(1)
        .toLowerCase()
        .startsWith(item.route.split(".")[0]);
    },
    visit(item) {
      if (!item.route && !item.href) return;

      setTimeout(() => {
        if (item.href) window.location.href = item.href;

        if (item.route === "logout") {
          this.$inertia.post(this.route(item.route));
          return;
        }

        if (item.filter != null) {
          this.$inertia.get(
            this.route(item.route, this.calculateFilters(item.filter))
          );
          return;
        }
        this.$inertia.get(this.route(item.route));
      }, 150);
    },
  },
  data() {
    return {
      items: [
        {
          title: "Dashboard",
          route: "dashboard.index",
          roles: ["ADMIN"],
        },
        {
          title: "Organization Management",
          roles: ["ADMIN", "PARTNER ADMIN"],
          subRoutes: [
            {
              title: "Organizations",
              route: "organizations.index",
            },
            {
              title: "Organization Groups",
              route: "organization-groups.index",
            },
            {
              title: "Integrations",
              route: "integrations.index",
              roles: ["ADMIN"],
            },
            {
              title: "Admins",
              route: "users.index",
            },
          ],
        },
        {
          title: "Data",
          roles: ["ADMIN"],
          subRoutes: [
            {
              title: "Data explorer",
              route: "assets.index",
            },
          ],
        },
        {
          title: "Partner Management",
          roles: ["ADMIN"],
          subRoutes: [
            {
              title: "Partners",
              route: "partners.index",
            },
            {
              title: "Partner admins",
              route: "partner-admins.index",
            },
          ],
        },
        {
          title: "Site Admins",
          route: "admins.index",
          roles: ["ADMIN"],
        },
        {
          title: "Factors",
          roles: ["ADMIN", "PARTNER ADMIN"],
          subRoutes: [
            {
              title: "Factor explorer",
              route: "emission-factors.index",
              roles: ["ADMIN", "PARTNER ADMIN"],
            },
            {
              title: "GHG factor filtration",
              route: "emission-factors.ghg-filtration",
              roles: ["ADMIN"],
            },
            {
              title: "Fallback emission factors",
              route: "emission-factor-fallbacks.index",
              roles: ["ADMIN"],
            },
            {
              title: "Custom emission factors",
              route: "emission-factors.custom.emissions",
              roles: ["ADMIN"],
            },
            {
              title: "Custom energy factors",
              route: "energy-factors.custom.energy",
              roles: ["ADMIN"],
            },
            {
              title: "Import custom emission factor",
              route: "emission-factors.custom.import",
              roles: ["ADMIN"],
            },
            {
              title: "Account name collection",
              route: "account-name-collections.index",
              roles: ["ADMIN"],
            },
          ],
        },
        {
          title: "AI",
          route: "asset-rules.index",
          roles: ["ADMIN"],
          subRoutes: [
            {
              title: "Precursora",
              route: "precursora-rules.index",
            },
            {
              title: "AT AI",
              route: "asset-rules.index",
            },
            {
              title: "CT AI",
              route: "document-rules.index",
            },
            {
              title: "Standard accounts mapping",
              route: "standard-financial-accounts.index",
            },
            {
              title: "GPT",
              route: "open-ai-settings.edit",
            },
          ],
        },
        {
          title: "Translations",
          route: "translations.index",
          roles: ["ADMIN"],
        },
        {
          title: "Log",
          route: "logs.index",
          roles: ["ADMIN"],
        },
        {
          title: "Settings",
          route: "settings.index",
          roles: ["ADMIN"],
        },
        {
          title: "Referral payments",
          route: "referral-payments.index",
          roles: ["ADMIN"],
        },
        {
          title: "Integration Data",
          route: "integrations.data-counter",
          roles: ["ADMIN"],
          subRoutes: [
            {
              title: "Counter",
              route: "integrations.data-counter",
            },
            {
              title: "Economic entries",
              route: "economic-accounting-entries.index",
            },
          ],
        },
        {
          title: "Hangfire",
          href: "/hangfire",
          roles: ["ADMIN"],
        },
        {
          title: "Logout",
          route: "logout",
        },
      ],
    };
  },
};
</script>
<style scoped>
.highlighted {
  background: white;
  border-radius: 5px;
}

.indented {
  margin-left: 1rem;
}

.red-dot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  background-color: red;
  top: 7px;
  right: 19px;
  width: 10px;
  height: 10px;
}

.v-list-item--dense :deep(.v-list-group__header) {
  min-height: 40px;
}
</style>
