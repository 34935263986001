<template>
  <v-tab-item :value="tabValue" :key="tabValue">
    <div class="fill-height fill-width">
      <v-row>
        <v-col cols="5">
          <v-row dense>
            <v-col>
              <span>Name</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="name"
                solo
                dense
                type="text"
                maxlength="38"
                :error-messages="formErrors.name"
                @input="updateForm('name', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Type</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <form autocomplete="off" @submit.prevent="() => {}">
                <v-autocomplete
                  :items="partnerTypes"
                  :value="type"
                  solo
                  dense
                  :error-messages="formErrors.type"
                  @input="updateForm('type', $event)"
                />
              </form>
            </v-col>
          </v-row>
        </v-col>
        <v-col offset="2" cols="5">
          <v-row dense>
            <v-col>
              <span>Tax number</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="taxNumber"
                solo
                dense
                type="text"
                :error-messages="formErrors.taxNumber"
                @input="updateForm('taxNumber', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Email</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="email"
                solo
                dense
                type="email"
                :error-messages="formErrors.email"
                @input="updateForm('email', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-checkbox
                :input-value="isSupport"
                label="Show partner as support"
                :error-messages="formErrors.isSupport"
                @change="updateForm('isSupport', $event)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    tabValue: Number,
    name: String,
    type: String,
    taxNumber: String,
    email: String,
    partnerTypes: Array,
    formErrors: Object,
    isSupport: Boolean,
  },
  emits: ["updateForm"],
  methods: {
    updateForm(formKey, value) {
      this.$emit(`update:${formKey}`, value);
    },
  },
};
</script>
