<template>
  <v-tab-item :value="tabValue" :key="tabValue">
    <div class="fill-height fill-width">
      <v-row>
        <v-col cols="5">
          <v-row dense>
            <v-col>
              <span>Consent questions</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-for="(question, index) in consentQuestions"
                :key="index"
                :value="question"
                solo
                dense
                type="text"
                maxlength="38"
                @input="updateConsentQuestions($event, index)"
              >
                <template #append-outer>
                  <v-btn @click="removeQuestion(index)" icon
                    ><v-icon>mdi-minus</v-icon></v-btn
                  >
                </template>
              </v-text-field>
              <v-btn @click="addQuestion" icon><v-icon>mdi-plus</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col offset="2" cols="5">
          <v-row dense>
            <v-col>
              <span>Consent text</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                :value="consentText"
                solo
                dense
                @input="updateConsentText($event)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    tabValue: Number,
    consentQuestions: Array,
    consentText: String,
  },
  methods: {
    updateConsentText(value) {
      this.$emit("update:consentText", value);
    },
    updateConsentQuestions(value, index) {
      const questions = this.consentQuestions;
      questions[index] = value;
      this.$emit(`update:consentQuestions`, questions);
    },
    addQuestion() {
      const questions = this.consentQuestions;
      questions.push("");
      this.$emit(`update:consentQuestions`, questions);
    },
    removeQuestion(index) {
      const questions = this.consentQuestions.filter((_, i) => i !== index);
      this.$emit(`update:consentQuestions`, questions);
    },
  },
};
</script>
