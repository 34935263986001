<template>
  <div class="flex d-flex justify-center align-center">
    <inertia-link id="back-arrow" :href="route('login')">
      <v-icon x-large>mdi-arrow-left</v-icon>
    </inertia-link>
    <v-form class="col-10 col-md-7">
      <h3 class="text-h4 cyan--text text--darken-4 font-weight-light">
        Check your mail
      </h3>
      <p class="caption mb-6">
        We have sent a password recover instructions to your email.
      </p>

      <p>
        Did not receive the email? Check your spam filter or
        <inertia-link :href="route('password.request')">
          try another email address
        </inertia-link>
      </p>
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";

export default {
  metaInfo: { title: "Email sent" },
  layout: Layout,
};
</script>
<style scoped>
.relative {
  position: relative;
}

#back-arrow {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: none;
}
</style>
