<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Economic accounting entries</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="space-between" class="d-flex align-center">
      <v-spacer></v-spacer>
      <v-col md="3">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1 row-pointer"
      :headers="headers"
      :items="economicAccountingEntries.data"
      :server-items-length="economicAccountingEntries.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      must-sort
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
    >
      <template v-slot:item.date="{ item }">
        <span>{{
          item.date ? new Date(item.date).toLocaleDateString() : ""
        }}</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { footerProps } from "@/util/dataTable";
import { appLayout } from "@/util/layout";
import qs from "qs";

export default {
  layout: appLayout({ title: "Economic Entries" }),
  props: {
    economicAccountingEntries: Object,
    reloadFilters: Object,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      headers: [
        { text: "Entry number", value: "entryNumber" },
        { text: "Account number", value: "accountNumber" },
        { text: "Text", value: "text" },
        { text: "Amount", value: "amount" },
        { text: "Currency", value: "currency" },
        { text: "Amount in base currency", value: "amountInBaseCurrency" },
        { text: "VAT code", value: "vatCode" },
        { text: "Entry type", value: "entryType" },
        { text: "Date", value: "date" },
      ],
      pagination: {
        page: this.economicAccountingEntries?.currentPage ?? 1,
        itemsPerPage: searchParams.pageSize
          ? parseInt(searchParams.pageSize)
          : 50,
        sortBy: [searchParams.sortBy ?? "entryNumber"],
        sortDesc: [searchParams.sortDesc === "true"],
      },
      tableFooterProps: footerProps,
      filters: this.reloadFilters ?? {},
    };
  },
  computed: {},
  methods: {
    resetFilter() {
      this.filters = {};
    },
    changePage(options) {
      const query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("economic-accounting-entries.index", query),
        {
          preserveState: true,
        }
      );
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          page: 1,
          sortBy: ["entryNumber"],
          sortDesc: [false],
        };
        this.changePage(this.pagination);
      }, 500),
      deep: true,
    },
  },
};
</script>
