<template>
  <v-tab-item :value="tabValue" :key="tabValue">
    <div class="fill-height fill-width">
      <v-row dense>
        <v-col>
          <span>Upload login image</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            class="image-preview-container"
            :class="{ 'dropzone-highlighted': fileDragover }"
            @dragenter="fileDragover = true"
            @dragleave="fileDragover = false"
            @dragend="fileDragover = false"
            @dragexit="fileDragover = false"
            @dragover="$event.preventDefault()"
            @drop="handleDroppedFile"
            @click="triggerFileUpload"
          >
            <img
              v-if="newPictureUrl"
              :src="newPictureUrl"
              alt="New partner picture"
              class="image-preview"
            />
            <img
              v-else-if="picture"
              :src="
                route('api.files.content', {
                  id: picture.id,
                  filename: picture.originalName,
                })
              "
              alt="Existing partner picture"
              class="image-preview"
            />
            <v-icon v-else x-large>mdi-tray-arrow-up</v-icon>
          </div>
          <v-file-input
            ref="fileUpload"
            class="d-none"
            accept=".svg"
            :value="newPicture"
            :rules="fileSizeRules"
            @change="updateForm('newPicture', $event)"
          />
        </v-col>

        <v-col offset="2" cols="5"> </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    tabValue: Number,
    picture: Object,
    newPicture: File,
    formErrors: Object,
  },
  data() {
    return {
      fileSizeRules: [
        (value) =>
          !value ||
          value.size < 30000000 ||
          "File size should be less than 26 MB",
      ],
      fileDragover: false,
    };
  },
  methods: {
    updateForm(formKey, value) {
      this.$emit(`update:${formKey}`, value);
    },
    triggerFileUpload() {
      this.$refs.fileUpload.$refs.input.click();
    },
    handleDroppedFile(event) {
      event.preventDefault();

      const droppedFiles = event.dataTransfer?.files;

      if (!droppedFiles?.length) return;

      const file = droppedFiles[0];

      if (!this.pictureAllowedMimeTypes.includes(file.type)) {
        alert("Dropped file is not in a supported image format.");
        return;
      }

      this.updateForm("newPicture", file);

      this.fileDragover = false;
    },
  },
  computed: {
    newPictureUrl() {
      if (!this.newPicture) return null;

      return URL.createObjectURL(this.newPicture);
    },
    pictureAllowedMimeTypes() {
      return ["image/svg+xml"];
    },
  },
};
</script>
<style scoped>
.image-preview-container {
  display: inline-flex;
  height: 160px;
  width: 160px;
  border: 1px solid gray;
  border-radius: 9px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image-preview {
  border-radius: 7px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.dropzone-highlighted {
  border: 2px solid #00695c;
}
</style>
