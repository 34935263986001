<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Account name collections</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row no-gutters>
      <v-col cols="12">
        <form @submit.prevent="submit">
          <v-card elevation="4">
            <v-card-title>
              {{ form.id ? "Update collection" : "Create new collection" }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <dialog-form-text-input
                    title="Name"
                    v-model="form.name"
                    hide-details="auto"
                    :error-messages="form.errors.name"
                  ></dialog-form-text-input>
                  <dialog-form-text-input
                    title="Synonyms (divide by semicolon)"
                    v-model="form.synonyms"
                    hide-details="auto"
                    :error-messages="form.errors.synonyms"
                    hint='e.g. "small purchases; smaller purchases"'
                    persistent-hint
                  ></dialog-form-text-input>
                  <v-checkbox
                    v-model="form.synchronizeByDefault"
                    label="If checked, account will be synchronized by default"
                    hide-details="auto"
                    :error-messages="form.errors.synchronizeByDefault"
                    class="mb-4"
                  ></v-checkbox>
                  <dialog-form-select-input
                    title="Default GHG category"
                    :items="ghgCategories"
                    item-text="name"
                    item-value="id"
                    clearable
                    v-model="form.defaultGhgCategoryId"
                    :error-messages="form.errors.defaultGhgCategoryId"
                    :disabled="!form.synchronizeByDefault"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                  <dialog-form-select-input
                    title="Standard account mapping"
                    :items="standardAccountEntries"
                    item-text="name"
                    item-value="id"
                    clearable
                    v-model="form.standardFinancialAccountId"
                    :error-messages="form.errors.standardFinancialAccountId"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="form.id"
                text
                color="blue darken-1"
                @click="resetForm"
              >
                <v-icon class="black--text">mdi-cancel</v-icon>
                <p class="teal--text text--darken-4 mb-0">Cancel</p>
              </v-btn>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!formIsFilledOut || formLoading"
                :loading="formLoading"
                @click="submit"
              >
                <v-icon class="black--text">mdi-content-save-outline</v-icon>
                <p class="teal--text text--darken-4 mb-0">Save</p>
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
      <v-col cols="12" class="mt-4">
        <v-card elevation="4">
          <v-card-title>Existing account name collections</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="existingCollectionsHeaders"
              :items="accountNameCollections.data"
              :server-items-length="accountNameCollections.totalCount"
              :options.sync="pagination"
              :footer-props="tableFooterProps"
              @dblclick:row="(_, { item }) => editCollection(item)"
              @update:page="changePage(pagination)"
              @update:items-per-page="changePage(pagination)"
              @update:sort-by="changePage(pagination)"
              @update:sort-desc="changePage(pagination)"
            >
              <template #item.synchronizeByDefault="{ item }">
                {{ item.synchronizeByDefault ? "Yes" : "No" }}
              </template>
              <template #item.actions="{ item }">
                <v-btn
                  small
                  outlined
                  color="primary"
                  @click="editCollection(item)"
                >
                  <v-icon class="black--text">mdi-pencil</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Edit</p>
                </v-btn>
                <v-btn
                  small
                  outlined
                  class="ml-2"
                  color="primary"
                  @click="deleteCollection(item)"
                >
                  <v-icon class="black--text">mdi-trash-can-outline</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Delete</p>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import qs from "qs";
import { serialize } from "object-to-formdata";

import { appLayout } from "../../util/layout";
import { footerProps } from "@/util/dataTable";

import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";

export default {
  layout: appLayout({ title: "Account name collections" }),
  components: {
    DialogFormTextInput,
    DialogFormSelectInput,
  },
  props: {
    accountNameCollections: Object,
    ghgCategories: Array,
    standardFinancialAccounts: Array,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      form: this.$inertia.form({
        id: null,
        name: null,
        synonyms: null,
        synchronizeByDefault: false,
        defaultGhgCategoryId: null,
        standardFinancialAccountId: null,
      }),

      formLoading: false,

      pagination: {
        page: this.accountNameCollections?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? null],
        sortDesc: [searchParams.sortDesc === "true"],
      },

      tableFooterProps: footerProps,

      existingCollectionsHeaders: [
        { text: "Collection name", value: "name" },
        { text: "Synonyms", value: "synonyms" },
        { text: "Synchronized by default", value: "synchronizeByDefault" },
        { text: "GHG Category", value: "defaultGhgCategory.name" },
        { text: "", value: "actions", width: 230, sortable: false },
      ],
    };
  },
  computed: {
    formIsFilledOut() {
      const { name, synonyms } = this.form;

      return !!name && !!synonyms;
    },
    standardAccountEntries() {
      if (!this.standardFinancialAccounts) return [];

      return this.standardFinancialAccounts.map((x) => ({
        id: x.id,
        name: `${x.accountNumber} - ${x.accountName}`,
      }));
    },
  },
  methods: {
    changePage(options) {
      const query = {
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("account-name-collections.index", query),
        {},
        { preserveState: true, preserveScroll: true }
      );
    },
    submit() {
      this.formLoading = true;

      const transformedForm = this.form.transform((data) =>
        serialize(
          { ...data, synchronizeByDefault: data.synchronizeByDefault + "" },
          { indices: true, dotsForObjectNotation: true }
        )
      );

      if (this.form.id) {
        transformedForm.put(
          this.route("account-name-collections.update", this.form.id),
          {
            onSuccess: () => {
              this.resetForm();
            },
            onFinish: () => {
              this.formLoading = false;
            },
          }
        );

        return;
      }

      transformedForm.post(this.route("account-name-collections.store"), {
        onSuccess: () => {
          this.resetForm();
        },
        onFinish: () => {
          this.formLoading = false;
        },
      });
    },
    resetForm() {
      this.form = this.$inertia.form({
        id: null,
        name: null,
        synonyms: null,
        synchronizeByDefault: false,
        defaultGhgCategoryId: null,
        standardFinancialAccountId: null,
      });
    },
    editCollection(item) {
      const confirmText =
        "Are you sure you want to continue? Any changes in the form will be discarded.";

      if (this.form.isDirty && !confirm(confirmText)) return;

      this.form = this.$inertia.form({
        id: item.id,
        name: item.name,
        synonyms: item.synonyms,
        synchronizeByDefault: item.synchronizeByDefault,
        defaultGhgCategoryId: item.defaultGhgCategoryId,
        standardFinancialAccountId: item.standardFinancialAccountId,
      });
    },
    deleteCollection(item) {
      if (!confirm("Are you sure you want to delete the selected collection?"))
        return;

      this.$inertia.delete(
        this.route("account-name-collections.destroy", item.id),
        {
          preserveState: true,
        }
      );
    },
  },
  watch: {
    "form.synchronizeByDefault"(value) {
      if (value) return;

      this.form.defaultGhgCategoryId = null;
    },
  },
};
</script>
