<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Fallback emission factors</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row no-gutters>
      <v-col cols="12">
        <form @submit.prevent="submit">
          <v-card elevation="4">
            <v-card-title>
              {{ form.id ? "Update fallback" : "Create new fallback" }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <dialog-form-select-input
                    title="Trigger"
                    :items="currentTriggerPropertyNames"
                    v-model="form.triggerPropertyName"
                    :disabled="form.fixed"
                    hide-details="auto"
                    class="mb-4"
                    @change="clearUnrelatedTriggerValue"
                  ></dialog-form-select-input>
                  <dialog-form-text-input
                    v-if="
                      form.triggerPropertyName !== 'Account name collection'
                    "
                    title="Keyword"
                    v-model="form.triggerValue"
                    hide-details="auto"
                    :disabled="form.fixed"
                    :error-messages="form.errors.triggerValue"
                  ></dialog-form-text-input>
                  <div v-else>
                    <p class="text-caption grey--text my-2 flex-grow-1">
                      Keyword
                    </p>
                    <v-autocomplete
                      v-model="form.triggerAccountNameCollectionId"
                      :items="
                        [...accountNameCollections].sort((a, b) =>
                          a.name
                            .toLowerCase()
                            .localeCompare(b.name.toLowerCase())
                        )
                      "
                      item-text="name"
                      item-value="id"
                      class="rounded-0 mb-4"
                      background-color="#F7F7F7"
                      hide-selected
                      solo
                      flat
                      filled
                      light
                      :error-messages="
                        form.errors.triggerAccountNameCollectionId
                      "
                    ></v-autocomplete>
                  </div>

                  <dialog-form-select-input
                    title="Scopes"
                    :items="scopes"
                    item-text="value"
                    item-value="id"
                    v-model="form.scopeId"
                    :error-messages="form.errors.scopeId"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                </v-col>
                <v-col cols="5" offset="2">
                  <dialog-form-select-input
                    title="Sector"
                    :items="emissionSectors"
                    item-text="name"
                    item-value="id"
                    v-model="selectedSectorId"
                    hide-details="auto"
                    :loading="sectorsLoading"
                    class="mb-4"
                    @change="handleSectorChanged"
                  ></dialog-form-select-input>
                  <dialog-form-select-input
                    title="Category"
                    :items="emissionCategories"
                    item-text="name"
                    item-value="id"
                    v-model="selectedCategoryId"
                    hide-details="auto"
                    :loading="categoriesLoading"
                    class="mb-4"
                    @change="handleCategoryChanged"
                  ></dialog-form-select-input>
                  <dialog-form-select-input
                    title="Region"
                    :items="emissionRegions"
                    item-text="countryName"
                    item-value="id"
                    v-model="selectedRegionId"
                    hide-details="auto"
                    :loading="regionsLoading"
                    class="mb-4"
                    @change="handleRegionChanged"
                  ></dialog-form-select-input>
                  <dialog-form-select-input
                    title="Activity name"
                    :items="emissionFactors"
                    item-text="name"
                    item-value="id"
                    v-model="form.factorId"
                    hide-details="auto"
                    :error-messages="form.errors.factorId"
                    :loading="factorsLoading"
                    class="mb-4"
                  >
                    <template #selectItem="{ item }">
                      <div class="d-flex flex-column">
                        <div>
                          {{ item.name }} {{ getFactorVersionLabel(item) }}
                        </div>
                        <div class="grey--text text--lighten-1 caption">
                          {{ formatEmissions(item) }}
                        </div>
                      </div>
                    </template>
                  </dialog-form-select-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="form.id"
                text
                color="blue darken-1"
                @click="resetForm"
              >
                <v-icon class="black--text">mdi-cancel</v-icon>
                <p class="teal--text text--darken-4 mb-0">Cancel</p>
              </v-btn>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!formIsFilledOut || formLoading"
                :loading="formLoading"
                @click="submit"
              >
                <v-icon class="black--text">mdi-content-save-outline</v-icon>
                <p class="teal--text text--darken-4 mb-0">Save</p>
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
      <v-col cols="12" class="mt-4">
        <v-card elevation="4">
          <v-card-title>Existing custom factors</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="existingFactorsHeaders"
              :items="emissionFactorFallbacks.data"
              :server-items-length="emissionFactorFallbacks.totalCount"
              :options.sync="pagination"
              :footer-props="tableFooterProps"
              @dblclick:row="(_, { item }) => editFallback(item)"
              @update:page="changePage(pagination)"
              @update:items-per-page="changePage(pagination)"
              @update:sort-by="changePage(pagination)"
              @update:sort-desc="changePage(pagination)"
            >
              <template #item.triggerValue="{ item }">
                <span class="font-weight-bold">
                  {{ item.triggerPropertyName
                  }}{{ formatTriggerValue(item) ? ":" : "" }}
                </span>
                {{ formatTriggerValue(item) }}
              </template>
              <template #item.calculationMethod="{ item }">
                {{ getCalculationMethodFromFactor(item.factor) }}
              </template>
              <template #item.emissions="{ item }">
                {{ formatEmissions(item.factor) }}
              </template>
              <template #item.actions="{ item }">
                <v-btn
                  small
                  outlined
                  color="primary"
                  @click="editFallback(item)"
                >
                  <v-icon class="black--text">mdi-pencil</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Edit</p>
                </v-btn>
                <v-btn
                  v-if="!item.fixed"
                  small
                  outlined
                  class="ml-2"
                  color="primary"
                  @click="deleteFallback(item)"
                >
                  <v-icon class="black--text">mdi-trash-can-outline</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Delete</p>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import qs from "qs";
import { serialize } from "object-to-formdata";

import { appLayout } from "../../util/layout";
import { footerProps } from "@/util/dataTable";

import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";
import { formatEmissionFactor, formatEmissionFactorName } from "../../util/formatters";

export default {
  layout: appLayout({ title: "Custom emission factors" }),
  components: {
    DialogFormTextInput,
    DialogFormSelectInput,
  },
  props: {
    emissionFactorFallbacks: Object,
    triggerPropertyNames: Array,
    visibleTriggerPropertyNames: Array,
    averageDataUnits: Array,
    scopes: Array,
    accountNameCollections: Array,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      form: this.$inertia.form({
        id: null,
        triggerPropertyName: null,
        triggerValue: null,
        triggerAccountNameCollectionId: null,
        scopeId: null,
        factorId: null,
        fixed: false,
      }),

      selectedSectorId: null,
      selectedCategoryId: null,
      selectedRegionId: null,

      emissionSectors: [],
      emissionCategories: [],
      emissionRegions: [],
      emissionFactors: [],

      sectorsLoading: false,
      categoriesLoading: false,
      regionsLoading: false,
      factorsLoading: false,

      formLoading: false,

      pagination: {
        page: this.emissionFactorFallbacks?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? null],
        sortDesc: [searchParams.sortDesc === "true"],
      },
      tableFooterProps: footerProps,

      existingFactorsHeaders: [
        { text: "ID", value: "referenceKey", width: 67 },
        { text: "Trigger", value: "triggerValue" },
        { text: "Activity name", value: "factor.name" },
        {
          text: "Calculation method",
          value: "calculationMethod",
          sortable: false,
        },
        { text: "Sector", value: "sector.name" },
        { text: "Category", value: "category.name" },
        { text: "Region", value: "region.countryName" },
        { text: "Emission factor", value: "emissions" },
        { text: "", value: "actions", width: 230 },
      ],
    };
  },
  computed: {
    formIsFilledOut() {
      const {
        triggerPropertyName,
        triggerValue,
        triggerAccountNameCollectionId,
        scopeId,
        factorId,
        fixed,
      } = this.form;

      return (
        !!triggerPropertyName &&
        !!scopeId &&
        !!factorId &&
        (fixed || !!triggerValue || !!triggerAccountNameCollectionId)
      );
    },
    currentTriggerPropertyNames() {
      if (this.form.fixed) return this.triggerPropertyNames;

      return this.visibleTriggerPropertyNames;
    },
    emissionFactor() {
      if (!this.form.factorId) return null;

      return this.emissionFactors.find((x) => x.id === this.form.factorId);
    },
  },
  methods: {
    getEmissionSectors() {
      this.sectorsLoading = true;

      fetch(
        this.route("api.emission-sectors.index", {
          anyFactors: "true",
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionSectors = data;
        })
        .finally(() => (this.sectorsLoading = false));
    },
    getEmissionCategories(sectorId) {
      this.categoriesLoading = true;

      fetch(
        this.route("api.emission-categories.index", {
          emissionSectorId: sectorId,
          anyFactors: "true",
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionCategories = data;
        })
        .finally(() => (this.categoriesLoading = false));
    },
    getEmissionRegions(categoryId) {
      this.regionsLoading = true;

      fetch(
        this.route("api.emission-regions.index", {
          emissionCategoryId: categoryId,
          anyFactors: "true",
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionRegions = data;
        })
        .finally(() => (this.regionsLoading = false));
    },
    getEmissionFactors(sectorId, categoryId, regionId, factorId = null) {
      this.factorsLoading = true;

      fetch(
        this.route("api.emission-factors.index", {
          emissionSectorId: sectorId,
          emissionCategoryId: categoryId,
          emissionRegionId: regionId,
          emissionFactorId: factorId,
          anyFactors: "true",
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionFactors = data.map((x) => ({
            ...x,
            name: formatEmissionFactorName(x),
          }));
        })
        .finally(() => (this.factorsLoading = false));
    },
    submit() {
      this.formLoading = true;

      const transformedForm = this.form.transform((data) =>
        serialize(data, { indices: true, dotsForObjectNotation: true })
      );

      if (this.form.id) {
        transformedForm.put(
          this.route("emission-factor-fallbacks.update", this.form.id),
          {
            onSuccess: () => {
              this.resetForm();
            },
            onFinish: () => {
              this.formLoading = false;
            },
          }
        );

        return;
      }

      transformedForm.post(this.route("emission-factor-fallbacks.store"), {
        onSuccess: () => {
          this.resetForm();
        },
        onFinish: () => {
          this.formLoading = false;
        },
      });
    },
    resetForm() {
      this.form = this.$inertia.form({
        id: null,
        triggerPropertyName: null,
        triggerValue: null,
        triggerAccountNameCollectionId: null,
        scopeId: null,
        factorId: null,
        fixed: false,
      });

      this.selectedSectorId = null;
      this.selectedCategoryId = null;
      this.selectedRegionId = null;

      this.emissionCategories = [];
      this.emissionRegions = [];
      this.emissionFactors = [];
    },
    editFallback(item) {
      const confirmText =
        "Are you sure you want to continue? Any changes in the form will be discarded.";

      if (this.form.isDirty && !confirm(confirmText)) return;

      this.getEmissionCategories(item.sectorId);
      this.getEmissionRegions(item.categoryId);
      this.getEmissionFactors(
        item.sectorId,
        item.categoryId,
        item.regionId,
        item.factorId
      );

      this.form = this.$inertia.form({
        id: item.id,
        triggerPropertyName: item.triggerPropertyName,
        triggerValue: item.triggerValue,
        triggerAccountNameCollectionId: item.triggerAccountNameCollectionId,
        scopeId: item.scopeId,
        factorId: item.factorId,
        fixed: item.fixed,
      });

      this.selectedSectorId = item.sectorId;
      this.selectedCategoryId = item.categoryId;
      this.selectedRegionId = item.regionId;
    },
    deleteFallback(item) {
      if (!confirm("Are you sure you want to delete the selected fallback?"))
        return;

      this.$inertia.delete(
        this.route("emission-factor-fallbacks.destroy", item.id),
        {
          preserveState: true,
        }
      );
    },
    getCalculationMethodFromFactor(factor) {
      if (!factor) return "";

      if (this.averageDataUnits.some((x) => x.value === factor.activityUnit)) {
        return "Average-data";
      }

      return "Spend-based";
    },
    formatEmissions(factor) {
      return formatEmissionFactor(factor, true);
    },
    handleSectorChanged(sectorId) {
      this.selectedCategoryId = null;
      this.selectedRegionId = null;
      this.form.factorId = null;

      this.emissionCategories = [];
      this.emissionRegions = [];
      this.emissionFactors = [];

      if (!sectorId) return;

      this.getEmissionCategories(sectorId);
    },
    handleCategoryChanged(categoryId) {
      this.selectedRegionId = null;
      this.form.factorId = null;

      this.emissionRegions = [];
      this.emissionFactors = [];

      if (!categoryId) return;

      this.getEmissionRegions(categoryId);
    },
    handleRegionChanged(regionId) {
      this.form.factorId = null;

      this.emissionFactors = [];

      if (!regionId) return;

      this.getEmissionFactors(
        this.selectedSectorId,
        this.selectedCategoryId,
        regionId
      );
    },
    changePage(options) {
      const query = {
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("emission-factor-fallbacks.index", query),
        {},
        { preserveState: true, preserveScroll: true }
      );
    },
    formatTriggerValue(fallback) {
      return (
        fallback.triggerAccountNameCollection?.name ?? fallback.triggerValue
      );
    },
    clearUnrelatedTriggerValue(propertyName) {
      if (propertyName === "Account name collection") {
        this.form.triggerValue = null;
        return;
      }

      this.form.triggerAccountNameCollectionId = null;
    },
    getFactorVersionLabel(factor) {
      const {
        id,
        activityId,
        activityUnit,
        lcaActivity,
        yearReleased,
        yearsCalculatedFrom,
      } = this.emissionFactor ?? {};

      if (
        factor.activityId !== activityId ||
        factor.activityUnit !== activityUnit ||
        factor.lcaActivity !== lcaActivity ||
        factor.id === id
      )
        return "";

      const year = yearsCalculatedFrom ?? yearReleased;
      const factorYear = factor.yearsCalculatedFrom ?? factor.yearReleased;

      const labelText = factorYear > year ? "Updated" : "Outdated";

      return `(${labelText})`;
    },
  },
  mounted() {
    this.getEmissionSectors();
  },
};
</script>
