<template>
  <page-modal v-model="show" :title="modalTitle">
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
        @click="$emit('clear')"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-plus</v-icon>
          <p class="teal--text text--darken-4 mb-0">CREATE</p>
        </div>
      </v-btn>
    </template>
    <template #body>
      <v-row>
        <v-col cols="5">
          <v-row dense>
            <v-col>
              <span>Name</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.name"
                solo
                dense
                type="text"
                :error-messages="form.errors.name"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Email</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.email"
                solo
                dense
                type="email"
                :error-messages="form.errors.email"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>{{ admin && admin.id ? "New password" : "Password" }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.password"
                solo
                dense
                type="password"
                :error-messages="form.errors.password"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template #actions>
      <v-btn
        v-if="admin && admin.id"
        color="blue darken-1"
        text
        :loading="toggleActiveLoading"
        :disabled="toggleActiveLoading"
        @click="toggleActive"
      >
        <v-icon class="black--text">{{
          admin && admin.disabledAt
            ? "mdi-toggle-switch-off-outline"
            : "mdi-toggle-switch-outline"
        }}</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ admin && admin.disabledAt ? "ACTIVATE" : "DEACTIVATE" }}
        </p>
      </v-btn>
      <v-btn v-if="admin && admin.id" color="blue darken-1" text>
        <v-icon class="black--text">mdi-email-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">(RE)SEND INVITE</p>
      </v-btn>
      <v-btn color="blue darken-1" text @click="show = false">
        <v-icon class="black--text">mdi-close</v-icon>
        <p class="teal--text text--darken-4 mb-0">CLOSE</p>
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="loading"
        :loading="loading"
        @click="saveAdmin"
      >
        <v-icon class="black--text">mdi-content-save-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ admin && admin.id ? "SAVE" : "CREATE" }}
        </p>
      </v-btn>
    </template>
  </page-modal>
</template>
<script>
import { serialize } from "object-to-formdata";

import PageModal from "@/Components/PageModal.vue";

export default {
  components: { PageModal },
  props: {
    admin: Object,
    value: Boolean,
  },
  data() {
    return {
      form: this.$inertia.form({
        name: null,
        email: null,
        password: null,
      }),
      active: false,
      loading: false,
      toggleActiveLoading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    modalTitle() {
      if (this.admin && this.admin.id)
        return `EDIT SITE ADMIN: ${this.admin.name}`;

      return "CREATE SITE ADMIN";
    },
  },
  methods: {
    setForm(admin) {
      if (!admin) {
        this.form.reset();
        return;
      }

      this.form.name = admin.name;
      this.form.email = admin.email;
      this.active = !this.admin.disabledAt;
    },
    saveAdmin() {
      if (this.loading) return;

      this.loading = true;

      if (this.admin?.id) {
        this.form
          .transform((data) => serialize(data))
          .put(this.route("admins.update", this.admin.id), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
              this.form.reset();
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      } else {
        this.form
          .transform((data) => serialize(data))
          .post(this.route("admins.store"), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      }
    },
    toggleActive() {
      if (this.toggleActiveLoading || !this.admin) return;

      this.toggleActiveLoading = true;

      this.$inertia
        .form({ active: !this.active })
        .transform((data) => serialize(data))
        .patch(this.route("admins.active.toggle", this.admin.id), {
          preserveState: true,
          preserveScroll: true,
          onFinish: () => {
            this.toggleActiveLoading = false;

            this.$emit("reload", this.admin.id);
          },
        });
    },
  },
  watch: {
    admin: function (admin) {
      this.setForm(admin);
    },
  },
};
</script>
