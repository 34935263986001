import { Inertia, shouldIntercept } from "@inertiajs/inertia";

const focusDirective = (el) => {
  el.focus();
};

const inertiaDirective = (el, binding, vnode) => {
  el.addEventListener("click", (event) => {
    if (!shouldIntercept(event)) {
      return;
    }

    event.preventDefault();

    const method = binding.arg || "get";
    const options = (binding.value || {}).options || {};
    const data =
      (binding.value || {}).data ||
      (Object.keys(options).length === 0 ? binding.value : {});
    const url =
      ((vnode.data || {}).attrs || {}).href ||
      (binding.value || {}).href ||
      (binding.value || {}).url ||
      el.href;

    if (method === "replace") {
      Inertia.replace(url, options);
    } else if (method === "reload") {
      Inertia.reload(options);
    } else if (["post", "put", "patch"].indexOf(method) > -1) {
      Inertia[method](url, data, options);
    } else if (method === "delete") {
      Inertia.delete(url, options);
    } else {
      Inertia.visit(url, {
        ...options,
        method,
        data,
      });
    }

    return false;
  });
};

export default {
  inertia: inertiaDirective,
  focus: focusDirective,
};
