import { integrationSystemLookup, integrationTypeLookup } from "./lookup";
import { fixedAttributes, fixedFieldAttributes } from "./fixedAssetData";
import { normalizeISOString } from "./dateTime";

export const roundedNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 2,
});

export const fullNumberFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 20,
});

export const currencyFormatter = new Intl.NumberFormat("da-DK", {
  useGrouping: "always",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const megabytesFormatter = new Intl.NumberFormat("da-DK", {
  maximumFractionDigits: 2,
  style: "unit",
  unit: "megabyte",
});

export function formatEmissionFactor(emissionFactor, withUnit = false) {
  if (!emissionFactor) return "";

  const { kgCo2eAr4, kgCo2eAr5, kgCo2eAr6 } = emissionFactor;

  let factor = kgCo2eAr6;

  if (!factor) factor = kgCo2eAr5;
  if (!factor) factor = kgCo2eAr4;

  if (!factor) factor = 0;

  let result = `${fullNumberFormatter.format(factor)} kg CO2e`;

  if (!withUnit) return result;

  let activityUnit = emissionFactor.activityUnit;

  if (!activityUnit) activityUnit = "Unknown";

  return `${result}/${activityUnit}`;
}

export function formatEmissions(emissions) {
  if (!emissions && emissions !== 0) return "";

  let unit = "kg CO2e";

  if (emissions >= 1000) {
    unit = "t CO2e";
    emissions = emissions / 1000;
  }

  return `${roundedNumberFormatter.format(emissions)} ${unit}`;
}

export function formatAttributeLink(attributeLink) {
  const guidValueAttributeIds = [
    fixedAttributes.co2SectorId,
    fixedAttributes.co2CategoryId,
    fixedAttributes.co2RegionId,
    fixedAttributes.co2NameId,
    fixedAttributes.co2GhgCategoryId,
  ];

  if (guidValueAttributeIds.includes(attributeLink?.attributeId)) {
    return attributeLink.searchString;
  }

  if (
    attributeLink?.attributeId === fixedFieldAttributes.generalVerarcaIdId ||
    attributeLink?.attributeId === fixedAttributes.infoAccountNumberId ||
    attributeLink?.attributeId === fixedAttributes.infoEntryNumberId
  ) {
    return attributeLink.searchString;
  }

  switch (attributeLink?.attribute?.format) {
    case "Text":
      return attributeLink.stringValue;
    case "TextArea":
      return attributeLink.stringValue;
    case "Select":
      return attributeLink.attributeSelectOption?.value;
    case "Number":
      if (!attributeLink.decimalValue) return "";

      return fullNumberFormatter.format(attributeLink.decimalValue);
    case "Percentage":
      if (!attributeLink.decimalValue) return "";

      return fullNumberFormatter.format(attributeLink.decimalValue) + " %";
    case "DateTime":
      return attributeLink.searchString;
    case "Date":
      return attributeLink.searchString;
    case "Currency":
      return `${
        attributeLink.stringValue ? attributeLink.stringValue + " " : ""
      }${currencyFormatter.format(attributeLink.decimalValue)}`;
    case "Boolean":
      if (
        attributeLink.boolValue === undefined ||
        attributeLink.boolValue === null
      )
        return "";

      return attributeLink.boolValue ? "true" : "false";
    default:
      return;
  }
}

export function formatEmissionFactorName(emissionFactor) {
  const lcaActivity = emissionFactor.lcaActivity ?? "unknown";

  const lcaSuffix =
    lcaActivity.toLowerCase() !== "unknown" ? ` (${lcaActivity})` : "";

  return `${emissionFactor.name}${lcaSuffix}`;
}

export function formatLogEntryInfo(action, parameters) {
  switch (action) {
    case "ResetIntegration":
      return formatResetIntegrationEvent(parameters);
    default:
      return "";
  }
}

function formatResetIntegrationEvent(parameters) {
  const { type, system, systemId } = parameters;

  const formattedType = integrationTypeLookup[type] ?? type ?? "Unknown";
  const formattedSystem =
    integrationSystemLookup[system] ?? system ?? "Unknown";

  const formattedSystemId = systemId ?? "Unknown";

  return `Type: ${formattedType}, System: ${formattedSystem}, System ID: ${formattedSystemId}`;
}

export function formatMegabytes(bytes) {
  const convertedBytes = (bytes ?? 0) / 1024 / 1024;

  return megabytesFormatter.format(convertedBytes);
}

export function formatDate(timestamp) {
  const normalizedDate = normalizeISOString(timestamp);

  return new Date(normalizedDate).toLocaleDateString();
}

export function formatIntegrationSystem(system) {
  switch (system) {
    case "economic":
      return "E-conomic";
    case "dynamics365":
      return "Dynamics 365 BC";
    case "uniconta":
      return "Uniconta";
    case "datacollect":
      return "DataCollect";
    default:
      return system;
  }
}
