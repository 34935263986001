<template>
  <v-tooltip left>
    <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
      <v-btn
        class="mx-1"
        icon
        :disabled="disabled"
        v-bind="{ attrsTooltip, ...attrs }"
        v-on="!disabled ? { ...onTooltip, ...on } : undefined"
        @click="$emit('click')"
        x-small
      >
        <v-icon small>
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ title }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    attrs: {
      type: Object,
      default: () => ({}),
    },
    on: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["click"],
};
</script>
