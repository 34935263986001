import Vue from "vue";
import Vuetify from "vuetify/lib";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.teal.darken3,
        lightGrey: "#F7F7F7",
        orange: "#F25D3B",
      },
    },
  },
});
