<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    :close-on-click="false"
    offset-y
    nudge-left="50px"
  >
    <template #activator="{ on, attrs }">
      <td v-bind="attrs" v-on="on">
        <div class="d-flex flex-row">
          <v-icon>mdi-magnify</v-icon>
          <p class="mb-0 ml-1 fill-width">{{ formattedTimestampFilter }}</p>
        </div>
      </td>
    </template>
    <v-card>
      <v-card-text>
        <v-row class="mx-0">
          <v-col class="pl-0">
            <label class="d-block">From</label>
            <date-picker
              :lang="lang"
              first-day-of-week="1"
              :value="from"
              type="date"
              @input="handleFromValueChanged"
            />
          </v-col>
          <v-col class="pr-0">
            <label class="d-block">To</label>
            <date-picker
              :lang="lang"
              first-day-of-week="1"
              v-model="to"
              type="date"
              :disabled-date="(date) => date < from"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mx-0">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="submit" :disabled="loading">
            <v-icon>mdi-chevron-right</v-icon>
            <p class="teal--text text--darken-4 mb-0">SUBMIT</p>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import { toLocaleUTCDateString } from "@/util/dateTime";

export default {
  components: { DatePicker },
  props: {
    value: Boolean,
    filters: Object,
    loading: Boolean,
  },
  emits: ["input", "submit"],
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      from: null,
      to: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    formattedTimestampFilter() {
      let result = "";

      let { from, to } = this.filters;

      if (!from && !to) return result;

      if (from) {
        result += toLocaleUTCDateString(from, false) + " ";
      }

      result += "→";

      if (to) result += " " + toLocaleUTCDateString(to, false) + " ";

      return result;
    },
  },
  methods: {
    handleFromValueChanged(value) {
      if (value > this.to) this.to = null;

      this.from = value;
    },
    submit() {
      this.$emit("submit", {
        from: this.from,
        to: this.to,
      });

      this.from = null;
      this.to = null;
    },
  },
  watch: {
    show(value) {
      if (!value) return;

      this.from = this.from ?? this.filters.from ?? null;
      this.to = this.to ?? this.filters.to ?? null;
    },
  },
};
</script>
