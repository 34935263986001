<template>
  <td>
    <div class="d-flex input-float-left fill-width">
      <v-select
        :key="filterRerenderCounter"
        class="hide-caret filter-button mr-1"
        :value="filterValue"
        dense
        hide-details
        :items="filterOptions"
        item-value="value"
        solo
        @input="debounce(() => handleFilterChanged($event), 100, `filter`)()"
      >
        <template v-slot:item="{ item }">
          <v-icon class="mr-2">{{ item.icon }}</v-icon>
          <p class="mb-0">{{ item.text }}</p>
        </template>
        <template v-slot:selection="{ item }">
          <v-icon>{{ item.icon }}</v-icon>
        </template>
      </v-select>
      <v-text-field
        class="filter-text-field fill-width ml-2"
        dense
        hide-details
        :value="searchValue"
        clearable
        @input="debounce(() => (searchValue = $event), 1000, `search`)()"
        @click:clear="
          debounce(() => (searchValue = null), 250, `search-clear`)()
        "
        type="text"
      >
      </v-text-field>
    </div>
  </td>
</template>
<script>
import { filterTypes } from "@/util/dataTypes";

export default {
  props: {
    filter: String,
    search: String,
  },
  computed: {
    filterValue: {
      get() {
        return this.filter;
      },
      set(value) {
        this.$emit("input:filter", value);
      },
    },
    searchValue: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit("input:search", value);
      },
    },
  },
  data() {
    return {
      filterOptions: [
        {
          text: "Include",
          value: filterTypes.include,
          icon: "mdi-alphabetical-variant",
        },
        {
          text: "Do not include",
          value: filterTypes.notInclude,
          icon: "mdi-alphabetical-variant-off",
        },
        {
          text: "Starts with",
          value: filterTypes.startsWith,
          icon: "mdi-format-letter-starts-with",
        },
        {
          text: "Ends with",
          value: filterTypes.endsWith,
          icon: "mdi-format-letter-ends-with",
        },
        {
          text: "Equal to",
          value: filterTypes.equalTo,
          icon: "mdi-equal",
        },
        {
          text: "Is not equal to",
          value: filterTypes.notEqualTo,
          icon: "mdi-not-equal-variant",
        },
        {
          text: "Reset",
          value: null,
          icon: "mdi-magnify",
        },
      ],
      timeouts: {},
      filterRerenderCounter: 0,
    };
  },
  methods: {
    debounce(fn, delay, timeoutId) {
      timeoutId = timeoutId ?? "default";

      const vueInstance = this;

      return function () {
        clearTimeout(vueInstance.timeouts[timeoutId]);
        let args = arguments;
        let that = this;
        vueInstance.timeouts[timeoutId] = setTimeout(function () {
          fn.apply(that, args);
        }, delay);
      };
    },
    handleFilterChanged(value) {
      if (value && !this.searchValue) {
        this.filterRerenderCounter++;
        return;
      }

      // Setting the search value to null resets both properties
      if (value) this.filterValue = value;
      else this.searchValue = value;
    },
  },
};
</script>
<style scoped>
.filter-button {
  min-width: 20px;
}

.filter-button:deep(.v-input__slot) {
  box-shadow: none !important;
  background-color: transparent !important;
  position: absolute !important;
  padding: 0% !important;
}

.hide-caret:deep(.v-input__append-inner) {
  visibility: hidden;
}

.input-float-left {
  float: left;
}

.filter-text-field:deep(.v-input__slot::before) {
  border-style: hidden !important;
  position: absolute !important;
}
</style>
