import actions from "./actions.json";
import aiStatus from "./aiStatus.json";
import attributeFormats from "./attributeFormats.json";
import attributeNames from "./attributeNames.json";
import boolean from "./boolean.json";
import dataTable from "./dataTable.json";
import dropdowns from "./dropdowns.json";
import economic from "./economic.json";
import fieldNames from "./fieldNames.json";
import filters from "./filters.json";
import flashMessages from "./flashMessages.json";
import integrationEntries from "./integrations/entries.json";
import integrationErrors from "./integrations/errors.json";
import integrationTypes from "./integrations/types.json";
import integrationSystems from "./integrations/systems.json";
import integrationUnicontaErrorMessages from "./integrations/uniconta/errorMessages.json";
import placeholders from "./placeholders.json";
import roles from "./roles.json";
import rootCauses from "./rootCauses.json";
import sorting from "./sorting.json";
import emissionFactorStatuses from "./statuses/emissionFactor.json";
import emissionSourceStatuses from "./statuses/emissionSource.json";
import units from "./units.json";
import visibilities from "./visibilities.json";
import ghgCategories from "./ghgCategories.json";
import months from "./months.json";

export default {
  actions,
  aiStatus,
  attributeFormats,
  attributeNames,
  boolean,
  dataTable,
  dropdowns,
  economic,
  fieldNames,
  filters,
  flashMessages,
  integrations: {
    entries: integrationEntries,
    errors: integrationErrors,
    systems: integrationSystems,
    types: integrationTypes,
    uniconta: {
      errorMessages: integrationUnicontaErrorMessages,
    },
  },
  placeholders,
  roles,
  rootCauses,
  sorting,
  statuses: {
    emissionFactor: emissionFactorStatuses,
    emissionSource: emissionSourceStatuses,
  },
  units,
  visibilities,
  ghgCategories,
  months,
};
