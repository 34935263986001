<template>
  <v-tooltip
    content-class="custom-tooltip"
    :left="align === 'left'"
    :right="align === 'right'"
    :top="align === 'top'"
    :bottom="align === 'bottom'"
  >
    <template #activator="{ on, attrs }">
      <v-icon small class="mx-1" v-bind="attrs" v-on="on">
        mdi-eye-outline
      </v-icon>
    </template>
    <v-col class="px-6 black--text">
      <slot name="data-before" v-bind:item="item" />
      <v-row justify="space-between">
        <span>Created</span>
        <span class="font-weight-black">
          {{ formatDate(item.createdAt) }}
        </span>
      </v-row>
      <v-row justify="space-between">
        <span>Created by</span>
        <span class="font-weight-black">
          {{ item.createdBy ? item.createdBy.name : "" }}
        </span>
      </v-row>
      <v-row justify="space-between">
        <span>Updated</span>
        <span class="font-weight-black">
          {{ formatDate(item.updatedAt) }}
        </span>
      </v-row>
      <v-row justify="space-between">
        <span>Updated by</span>
        <span class="font-weight-black">
          {{ item.updatedBy ? item.updatedBy.name : "" }}
        </span>
      </v-row>
      <slot name="data-after" v-bind:item="item" />
    </v-col>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    align: {
      type: String,
      default: "left",
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "N/A";

      return new Date(date).toLocaleDateString();
    },
  },
};
</script>
