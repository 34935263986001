<template>
  <v-tooltip :top="top" :open-delay="openDelay">
    <template #activator="{ on, attrs }">
      <span
        v-on="on"
        v-bind="attrs"
        class="d-block text-truncate text-nowrap"
        :class="`${secondary ? 'text--secondary' : ''}`"
      >
        <template v-if="text">
          {{ text }}
        </template>
        <template v-else>&nbsp;</template>
      </span>
    </template>
    <div>{{ text }}</div>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    text: [String, Number],
    top: Boolean,
    openDelay: Number,
    secondary: Boolean,
  },
};
</script>
