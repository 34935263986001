<script>
import { fixedAttributes } from "../util/fixedAssetData";
import { formatEmissionFactor, formatEmissions } from "../util/formatters";

export default {
  methods: {
    formatEmissionFactor(emissionFactor, withUnit = false) {
      return formatEmissionFactor(emissionFactor, withUnit);
    },
    formatEmissions(emissions) {
      return formatEmissions(emissions);
    },
    formatAssetEmissions(asset) {
      const emissionsLink = asset?.assetAttributeLinks?.find(
        (x) => x.attributeId === fixedAttributes.co2EmissionsId
      );

      if (!emissionsLink) return "";

      return this.formatEmissions(emissionsLink?.decimalValue);
    },
  },
};
</script>
