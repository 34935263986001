<template>
  <v-tab-item class="fill-width min-h-104">
    <div class="fill-height fill-width">
      <div v-if="hasPurchasedSubscription" class="pt-8">
        <p>Organization has purchased a subscription</p>
      </div>
      <div v-else>
        <v-row>
          <v-col md="4">
            <v-autocomplete
              v-model="defaultPaymentIdProxy"
              :items="formattedPaymentTiers"
              item-text="name"
              item-value="id"
              filled
              hide-details
              label="Subscription Tier"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col md="4">
            <dialog-form-date-selector
              :key="1"
              title="Trial end date"
              @change="$emit('updateForm', 'trialEndDate', $event)"
              placeholder="Format: YYYY-mm-dd"
              :disabled="disabledDate"
              :value="currentValue"
            ></dialog-form-date-selector>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col md="4">
            <v-checkbox
              label="Force trial for payment"
              @change="$emit('updateForm', 'forceTrial', $event)"
              :value="organizationTrialData.forceTrial"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </v-tab-item>
</template>
<script>
import AuthHelpers from "../../../mixins/AuthHelpers.vue";
import DialogFormDateSelector from "../../../Components/Dialog/inputs/DialogFormDateSelector.vue";
import { stripeFreeProductId } from "../../../util/constants";

export default {
  components: { DialogFormDateSelector },
  mixins: [AuthHelpers],
  props: {
    paymentTierIds: Object,
    organizationTrialData: Object,
  },
  data() {
    return {
      freeSubscrption: "free",
      disabledDate: false,
    };
  },
  emits: ["updateForm"],
  computed: {
    formattedPaymentTiers() {
      return Object.entries(this.paymentTierIds).map(([value, id]) => ({
        id,
        name: `Tier ${String(value)}`,
      }));
    },
    defaultPaymentIdProxy: {
      get() {
        if (this.organizationTrialData) {
          var selection = this.formattedPaymentTiers.find(
            (x) => x.id === this.organizationTrialData.stripeProductPriceId
          );

          if (selection) {
            return selection;
          }
        }
        return this.freeSubscrption;
      },
      set(value) {
        this.$emit("updateForm", "trialSubscriptionTierId", value);
      },
    },
    hasPurchasedSubscription() {
      return (
        this.organizationTrialData &&
        this.organizationTrialData.stripeProductPriceId !==
          stripeFreeProductId &&
        this.organizationTrialData.hasActiveSubscription
      );
    },
    currentValue() {
      if (
        this.organizationTrialData &&
        this.organizationTrialData.trialEndDate
      ) {
        return new Date(this.organizationTrialData.trialEndDate);
      }

      return null;
    },
  },
};
</script>
