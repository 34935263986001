import dashboard from "./dashboard.json";
import assetIndex from "./assets/index.json";
import assetSettingsIndex from "./assets/asset-settings.json";
import assetForm from "./assets/form.json";
import assetExport from "./assets/export.json";
import assetImport from "./assets/import.json";
import assetBulkEdit from "./assets/bulkEdit.json";
import entryView from "./assets/entryView.json";
import attributeIndex from "./attributes/index.json";
import attributeForm from "./attributes/form.json";
import attributeClassIndex from "./attributeClasses/index.json";
import attributeClassForm from "./attributeClasses/form.json";
import login from "./login.json";
import register from "./register.json";
import verifyMail from "./verifyMail.json";
import verificationMailSent from "./verificationMailSent.json";
import forgotPassword from "./forgotPassword.json";
import resetPassword from "./resetPassword.json";
import mailVerified from "./mailVerified.json";
import documentRequest from "./documentRequest.json";
import featureRequest from "./featureRequest.json";
import fileCreate from "./files/create.json";
import fileEdit from "./files/edit.json";
import fileIndex from "./files/index.json";
import integrationEntryEventIndex from "./integrationEntryEvents/index.json";
import logIndex from "./logs/index.json";
import organizationForm from "./organizations/form.json";
import organizationIndex from "./organizations/index.json";
import permissions from "./permissions.json";
import profile from "./profile.json";
import roleForm from "./roles/form.json";
import roleIndex from "./roles/index.json";
import settingsIndex from "./settings/index.json";
import settingsAssetRuleForm from "./settings/assetRuleForm.json";
import settingsCustomEmissionFactorsForm from "./settings/customEmissionFactorsForm.json";
import settingsIntegrationSettingsForm from "./settings/integrationSettingsForm.json";
import settingsIntegrationDynamics365 from "./settings/integrationDynamics365.json";
import settingsIntegrationUniconta from "./settings/integrationUniconta.json";
import settingsGeneral from "./settings/general.json";
import factorSources from "./settings/factorSources.json";
import intensityFigures from "./settings/intensityFigures.json";
import settingsDocuments from "./settings/documents.json";
import payment from "./payment.json";
import userForm from "./users/form.json";
import userIndex from "./users/index.json";
import reportsIndex from "./reports/index.json";
import reportsCarbonFootprint from "./reports/carbonFootprint.json";
import reportsPrint from "./reports/print.json";
import climateReport from "./reports/climateReport.json";
import supportIndex from "./support/index.json";
import audits from "./audits/index.json";
import billing from "./billing/index.json";
import integrations from "./integrations/index.json";
import userManagement from "./userManagement/index.json";
import documents from "./documents/index.json";
import agreements from "./agreements/index.json";
import esg from "./esg/index.json";

export default {
  dashboard,
  assets: {
    index: assetIndex,
    form: assetForm,
    export: assetExport,
    import: assetImport,
    bulkEdit: assetBulkEdit,
    assetSettingsIndex: assetSettingsIndex,
    entryView: entryView,
  },
  attributes: {
    index: attributeIndex,
    form: attributeForm,
  },
  attributeClasses: {
    index: attributeClassIndex,
    form: attributeClassForm,
  },
  login,
  register,
  verifyMail,
  verificationMailSent,
  forgotPassword,
  resetPassword,
  mailVerified,
  documentRequest,
  featureRequest,
  files: {
    create: fileCreate,
    edit: fileEdit,
    index: fileIndex,
  },
  integrationEntryEvents: {
    index: integrationEntryEventIndex,
  },
  logs: {
    index: logIndex,
  },
  organizations: {
    form: organizationForm,
    index: organizationIndex,
  },
  permissions,
  profile,
  roles: {
    form: roleForm,
    index: roleIndex,
  },
  settings: {
    index: settingsIndex,
    assetRuleForm: settingsAssetRuleForm,
    customEmissionFactorsForm: settingsCustomEmissionFactorsForm,
    integrationSettingsForm: settingsIntegrationSettingsForm,
    integrationDynamics365: settingsIntegrationDynamics365,
    integrationUniconta: settingsIntegrationUniconta,
    general: settingsGeneral,
    documents: settingsDocuments,
    factorSources: factorSources,
    intensityFigures: intensityFigures,
  },
  users: {
    form: userForm,
    index: userIndex,
  },
  reports: {
    index: reportsIndex,
    carbonFootprint: reportsCarbonFootprint,
    print: reportsPrint,
    climateReport: climateReport,
  },
  payment,
  support: supportIndex,
  audits,
  billing,
  integrations,
  userManagement,
  documents,
  agreements,
  esg,
};
