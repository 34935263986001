<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    :close-on-click="false"
    offset-y
    nudge-left="50px"
  >
    <template #activator="{ on, attrs }">
      <td v-bind="attrs" v-on="on">
        <div class="d-flex flex-row">
          <v-icon>mdi-magnify</v-icon>
          <p class="mb-0 fill-width"></p>
        </div>
      </td>
    </template>
    <v-card>
      <v-card-text>
        <v-row class="mx-0">
          <v-col class="pl-0">
            <label class="d-block">Source</label>
            <v-autocomplete
              dense
              hide-details
              v-model="source"
              :items="formattedSources"
              item-text="name"
              item-value="value"
              clearable
              multiple
              @change="handleSourceValueChanged($event)"
              style="width: 321px !important"
            >
              <template v-slot:selection="data">
                <v-chip v-if="source.length < 2" v-bind="data.attrs" small>
                  {{ data.item.name }}
                </v-chip>
                <div v-else-if="data.index === 0">
                  {{ `${source.length} items selected` }}
                </div>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mx-0">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="submit" :disabled="loading">
            <v-icon>mdi-chevron-right</v-icon>
            <p class="teal--text text--darken-4 mb-0">SUBMIT</p>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { logSourceLookup } from "@/util/lookup";

export default {
  props: {
    value: Boolean,
    filters: Object,
    loading: Boolean,
    sources: Array,
  },
  emits: ["input", "submit"],
  data() {
    return {
      source: null,
    };
  },
  computed: {
    formattedSources() {
      return this.sources.map((x) => ({
        name: logSourceLookup[x],
        value: x,
      }));
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    handleSourceValueChanged(value) {
      this.source = value;
    },
    submit() {
      this.$emit("submit", {
        source: this.source,
      });
    },
  },
  watch: {
    show(value) {
      if (!value) return;
      this.source = this.source ?? this.filters.source ?? null;
    },
  },
};
</script>
