<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Standard accounts mapping</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row no-gutters>
      <v-col cols="12">
        <form @submit.prevent="submit">
          <v-card elevation="4">
            <v-card-title>
              {{ form.id ? "Update mapping" : "Create new mapping" }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <dialog-form-text-input
                    title="Account number"
                    v-model="form.accountNumber"
                    hide-details="auto"
                    :error-messages="form.errors.accountNumber"
                  ></dialog-form-text-input>
                  <dialog-form-text-input
                    title="Account name"
                    v-model="form.accountName"
                    hide-details="auto"
                    :error-messages="form.errors.accountName"
                  ></dialog-form-text-input>
                  <dialog-form-select-input
                    title="GHG category"
                    :items="ghgCategories"
                    item-text="name"
                    item-value="id"
                    clearable
                    v-model="form.ghgCategoryId"
                    :error-messages="form.errors.ghgCategoryId"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                </v-col>
                <v-col cols="5">
                  <dialog-form-select-input
                    title="Headline"
                    :items="formattedHeadlines"
                    item-text="name"
                    item-value="id"
                    clearable
                    v-model="form.headlineId"
                    :error-messages="form.errors.headlineId"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                  <dialog-form-text-input
                    title="New headline"
                    v-model="form.newHeadline"
                    hide-details="auto"
                    :error-messages="form.errors.newHeadline"
                    :disabled="isNewHeadlineDisabled"
                  ></dialog-form-text-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="form.id"
                text
                color="blue darken-1"
                @click="resetForm"
              >
                <v-icon class="black--text">mdi-cancel</v-icon>
                <p class="teal--text text--darken-4 mb-0">Cancel</p>
              </v-btn>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!formIsFilledOut || formLoading"
                :loading="formLoading"
                @click="submit"
              >
                <v-icon class="black--text">mdi-content-save-outline</v-icon>
                <p class="teal--text text--darken-4 mb-0">Save</p>
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
      <v-col cols="12" class="mt-4">
        <v-card elevation="4">
          <v-card-title>Existing account mappings</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="existingMappingsHeaders"
              :items="standardFinancialAccounts.data"
              :server-items-length="standardFinancialAccounts.totalCount"
              :options.sync="pagination"
              :footer-props="tableFooterProps"
              @dblclick:row="(_, { item }) => editMapping(item)"
              @update:page="changePage(pagination)"
              @update:items-per-page="changePage(pagination)"
              @update:sort-by="changePage(pagination)"
              @update:sort-desc="changePage(pagination)"
            >
              <template #item.synchronizeByDefault="{ item }">
                {{ item.synchronizeByDefault ? "Yes" : "No" }}
              </template>
              <template #item.actions="{ item }">
                <v-btn
                  small
                  outlined
                  color="primary"
                  @click="editMapping(item)"
                >
                  <v-icon class="black--text">mdi-pencil</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Edit</p>
                </v-btn>
                <v-btn
                  small
                  outlined
                  class="ml-2"
                  color="primary"
                  @click="deleteMapping(item)"
                >
                  <v-icon class="black--text">mdi-trash-can-outline</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Delete</p>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import qs from "qs";
import { serialize } from "object-to-formdata";

import { appLayout } from "../../util/layout";
import { footerProps } from "@/util/dataTable";

import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";

export default {
  layout: appLayout({ title: "Account name collections" }),
  components: {
    DialogFormTextInput,
    DialogFormSelectInput,
  },
  props: {
    standardFinancialAccounts: Object,
    ghgCategories: Array,
    headlines: Array,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    const defaultHeadlineId = this.defaultHeadlineId();

    return {
      form: this.$inertia.form({
        id: null,
        accountNumber: null,
        accountName: null,
        ghgCategoryId: null,
        headlineId: defaultHeadlineId,
        newHeadline: null,
      }),

      formLoading: false,

      pagination: {
        page: this.standardFinancialAccounts?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? null],
        sortDesc: [searchParams.sortDesc === "true"],
      },

      tableFooterProps: footerProps,

      existingMappingsHeaders: [
        { text: "Account number", value: "accountNumber" },
        { text: "Account name", value: "accountName" },
        { text: "GHG category", value: "ghgCategory.name" },
        { text: "Headline", value: "headline.name" },
        { text: "", value: "actions", width: 230, sortable: false },
      ],
    };
  },
  computed: {
    formIsFilledOut() {
      const { accountNumber, accountName, ghgCategoryId } = this.form;

      return !!accountNumber && !!accountName && !!ghgCategoryId;
    },
    formattedHeadlines() {
      const defaultHeadline = {
        id: "00000000-0000-0000-0000-000000000000",
        name: "New",
      };
      return [defaultHeadline, ...this.headlines];
    },
    isNewHeadlineDisabled() {
      return (
        this.form.headlineId &&
        this.form.headlineId !== "00000000-0000-0000-0000-000000000000"
      );
    },
  },
  methods: {
    changePage(options) {
      const query = {
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("standard-financial-accounts.index", query),
        {},
        { preserveState: true, preserveScroll: true }
      );
    },
    submit() {
      this.formLoading = true;

      const { newHeadline, ...formData } = this.form;

      const transformedForm = formData.transform((data) =>
        serialize(data, { indices: true, dotsForObjectNotation: true })
      );

      if (newHeadline) {
        transformedForm.newHeadline = newHeadline;
      }

      if (this.form.id) {
        transformedForm.put(
          this.route("standard-financial-accounts.update", this.form.id),
          {
            onSuccess: () => {
              this.resetForm();
            },
            onFinish: () => {
              this.formLoading = false;
            },
          }
        );

        return;
      }

      transformedForm.post(this.route("standard-financial-accounts.store"), {
        onSuccess: () => {
          this.resetForm();
        },
        onFinish: () => {
          this.formLoading = false;
        },
      });
    },
    resetForm() {
      const defaultHeadlineId = this.defaultHeadlineId();

      this.form = this.$inertia.form({
        id: null,
        accountNumber: null,
        accountName: null,
        ghgCategoryId: null,
        headlineId: defaultHeadlineId,
        newHeadline: null,
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    editMapping(item) {
      const confirmText =
        "Are you sure you want to continue? Any changes in the form will be discarded.";

      if (this.form.isDirty && !confirm(confirmText)) return;

      this.scrollToTop();

      const selectedHeadline = this.formattedHeadlines.find(
        (headline) => headline.id === item.headlineId
      );

      this.form = this.$inertia.form({
        id: item.id,
        accountNumber: item.accountNumber,
        accountName: item.accountName,
        ghgCategoryId: item.ghgCategoryId,
        headlineId: selectedHeadline
          ? selectedHeadline.id
          : this.defaultHeadlineId(),
        newHeadline: item.newHeadline ? item.newHeadline : null,
      });
    },
    deleteMapping(item) {
      if (!confirm("Are you sure you want to delete the selected mapping?"))
        return;

      this.$inertia.delete(
        this.route("standard-financial-accounts.destroy", item.id),
        {
          preserveState: true,
          preserveScroll: true,
          onSuccess: () => {
            if (item.id === this.form.id) this.resetForm();
          },
        }
      );
    },
    defaultHeadlineId() {
      if (!this.formattedHeadlines || this.formattedHeadlines.length === 0) {
        return "00000000-0000-0000-0000-000000000000";
      }
      const newHeadline = this.formattedHeadlines.find(
        (headline) => headline.name === "New"
      );
      return newHeadline
        ? newHeadline.id
        : "00000000-0000-0000-0000-000000000000";
    },
  },
};
</script>
