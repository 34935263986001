<template>
  <page-modal v-model="show" :title="modalTitle">
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
        @click="$emit('clear')"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-plus</v-icon>
          <p class="teal--text text--darken-4 mb-0">CREATE</p>
        </div>
      </v-btn>
    </template>
    <template #body>
      <v-row>
        <v-col cols="5">
          <v-row dense>
            <v-col>
              <span>Name</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.name"
                solo
                dense
                type="text"
                :error-messages="form.errors.name"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Email</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.email"
                solo
                dense
                type="email"
                :error-messages="form.errors.email"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>{{ partnerAdmin?.id ? "New password" : "Password" }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="form.password"
                solo
                dense
                type="password"
                :error-messages="form.errors.password"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Partner</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-autocomplete
                v-model="form.partnerIds"
                :items="partners"
                item-text="name"
                item-value="id"
                solo
                dense
                multiple
                placeholder="Partner"
                :error-messages="form.errors.partnerId"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template #actions>
      <v-btn
        v-if="partnerAdmin?.id"
        color="blue darken-1"
        text
        :loading="toggleActiveLoading"
        :disabled="toggleActiveLoading"
        @click="toggleActive"
      >
        <v-icon class="black--text">{{
          partnerAdmin?.disabledAt
            ? "mdi-toggle-switch-off-outline"
            : "mdi-toggle-switch-outline"
        }}</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ partnerAdmin?.disabledAt ? "ACTIVATE" : "DEACTIVATE" }}
        </p>
      </v-btn>
      <v-btn color="blue darken-1" text @click="show = false">
        <v-icon class="black--text">mdi-close</v-icon>
        <p class="teal--text text--darken-4 mb-0">CLOSE</p>
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="loading"
        :loading="loading"
        @click="savePartnerAdmin"
      >
        <v-icon class="black--text">mdi-content-save-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ partnerAdmin?.id ? "SAVE" : "CREATE" }}
        </p>
      </v-btn>
    </template>
  </page-modal>
</template>
<script>
import { serialize } from "object-to-formdata";

import PageModal from "@/Components/PageModal.vue";

export default {
  components: { PageModal },
  props: {
    partnerAdmin: Object,
    partners: Array,
    value: Boolean,
  },
  data() {
    return {
      form: this.$inertia.form({
        name: null,
        email: null,
        password: null,
        partnerIds: [],
      }),
      active: false,
      loading: false,
      toggleActiveLoading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    modalTitle() {
      if (this.partnerAdmin?.id)
        return `EDIT PARTNER ADMIN: ${this.partnerAdmin.name}`;

      return "CREATE PARTNER ADMIN";
    },
  },
  methods: {
    setForm(partnerAdmin) {
      if (!partnerAdmin) {
        this.form.reset();
        return;
      }

      this.form.name = partnerAdmin.name;
      this.form.email = partnerAdmin.email;
      this.form.partnerIds = partnerAdmin.partners?.map((x) => x.id) ?? [];
      this.active = !this.partnerAdmin.disabledAt;
    },
    savePartnerAdmin() {
      if (this.loading) return;

      this.loading = true;

      if (this.partnerAdmin?.id) {
        this.form
          .transform((data) =>
            serialize(data, {
              dotsForObjectNotation: true,
              noFilesWithArrayNotation: true,
            })
          )
          .put(this.route("partner-admins.update", this.partnerAdmin.id), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
              this.form.reset();
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      } else {
        this.form
          .transform((data) =>
            serialize(data, {
              dotsForObjectNotation: true,
              noFilesWithArrayNotation: true,
            })
          )
          .post(this.route("partner-admins.store"), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
              this.form.reset();
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      }
    },
    toggleActive() {
      if (this.toggleActiveLoading || !this.partnerAdmin) return;

      this.toggleActiveLoading = true;

      this.$inertia
        .form({ active: !this.active })
        .transform((data) => serialize(data))
        .patch(
          this.route("partner-admins.active.toggle", this.partnerAdmin.id),
          {
            preserveState: true,
            preserveScroll: true,
            onFinish: () => {
              this.toggleActiveLoading = false;

              this.$emit("reload", this.partnerAdmin.id);
            },
          }
        );
    },
  },
  watch: {
    partnerAdmin: function (partnerAdmin) {
      this.setForm(partnerAdmin);
    },
  },
};
</script>
