<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Integration data counter</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row no-gutters>
      <v-col cols="12">
        <form @submit.prevent="submit">
          <v-card elevation="4" :loading="formLoading">
            <v-card-title>Search parameters</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <dialog-form-select-input
                    v-model="organizationId"
                    title="Organization"
                    :items="organizations"
                    :disabled="formLoading"
                    item-text="name"
                    item-value="id"
                    hide-details="auto"
                    class="mb-4"
                    @change="handleOrganizationIdChanged"
                  ></dialog-form-select-input>
                  <dialog-form-select-input
                    v-model="integrationId"
                    title="Integration"
                    :items="integrations"
                    :disabled="!organizationId || formLoading"
                    :item-text="formatIntegration"
                    item-value="id"
                    hide-details="auto"
                    class="mb-4"
                    @change="handleIntegrationIdChanged"
                  ></dialog-form-select-input>
                  <dialog-form-select-input
                    v-model="financialAccountId"
                    title="Financial account"
                    :items="financialAccounts"
                    :disabled="!integrationId || formLoading"
                    :item-text="formatFinancialAccount"
                    item-value="id"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                </v-col>
                <v-col cols="5" offset="2">
                  <dialog-form-date-selector
                    v-model="from"
                    title="From date"
                    :disabled="formLoading"
                    class="mb-4"
                    clearable
                    @change="handleFromChanged"
                  ></dialog-form-date-selector>
                  <dialog-form-date-selector
                    v-model="to"
                    title="To date"
                    :disabled="formLoading"
                    :min="from"
                    class="mb-4"
                    clearable
                  ></dialog-form-date-selector>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!formIsFilledOut || formLoading"
                :loading="formLoading"
                @click="submit"
              >
                <v-icon class="black--text">mdi-magnify</v-icon>
                <p class="teal--text text--darken-4 mb-0">Search</p>
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
      <v-col cols="12" class="mt-4" v-if="searchResult !== null">
        <v-card elevation="4">
          <v-card-title>Search results</v-card-title>
          <v-card-text>
            <v-alert
              v-if="!searchResultParamsMatch"
              dense
              outlined
              type="warning"
            >
              Search parameters changed since last search
            </v-alert>
            <v-row class="d-flex align-center text-center grow">
              <v-col cols="12">
                <h1 class="text-h1 black--text">{{ searchResult.entryCount }}</h1>
                <v-spacer></v-spacer>
                <p class="subtitle-1">Entries</p>
                <v-spacer></v-spacer>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";
import { removeTimezoneOffset } from "@/util/dateTime";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";
import DialogFormDateSelector from "../../Components/Dialog/inputs/DialogFormDateSelector.vue";

export default {
  layout: appLayout({ title: "Data counter" }),
  components: {
    DialogFormSelectInput,
    DialogFormDateSelector,
  },
  props: {
    organizations: Array,
  },
  data() {
    return {
      organizationId: null,
      integrationId: null,
      financialAccountId: null,
      from: null,
      to: null,

      integrations: [],
      financialAccounts: [],

      formLoading: false,
      searchResult: null,
    };
  },
  computed: {
    formIsFilledOut() {
      return !!this.organizationId;
    },
    searchResultParamsMatch() {
      if (!this.searchResult) return true;

      const { organizationId, integrationId, financialAccountId, from, to } =
        this.searchResult;

      return (
        this.organizationId === organizationId &&
        this.integrationId === integrationId &&
        this.financialAccountId === financialAccountId &&
        this.from?.toISOString() === from?.toISOString() &&
        this.to?.toISOString() === to?.toISOString()
      );
    },
  },
  methods: {
    formatIntegration(integration) {
      let integrationSystem = integration.system;

      if (!integrationSystem) {
        integrationSystem = "unknown";
      }

      return `#${integration.referenceKey} - ${integrationSystem}`;
    },
    formatFinancialAccount(financialAccount) {
      return `${financialAccount.accountNumber} - ${financialAccount.name}`;
    },
    formatDateString(date) {
      if (!date) return null;

      const normalizedDate = removeTimezoneOffset(new Date(date));

      return normalizedDate.toISOString().split("T")[0];
    },
    handleOrganizationIdChanged(organizationId) {
      this.integrationId = null;
      this.financialAccountId = null;

      this.getIntegrations(organizationId);
    },
    handleIntegrationIdChanged(integrationId) {
      this.financialAccountId = null;

      this.getFinancialAccounts(integrationId);
    },
    handleFromChanged(value) {
      if (value > this.to) {
        this.to = null;
      }
    },
    getIntegrations(organizationId) {
      this.integrations = [];
      this.financialAccounts = [];

      if (!organizationId) return;

      this.formLoading = true;

      fetch(this.route("api.integrations.organization", organizationId))
        .then((res) => res.json())
        .then((data) => {
          this.integrations = data;
        })
        .finally(() => (this.formLoading = false));
    },
    getFinancialAccounts(integrationId) {
      this.financialAccounts = [];

      if (!integrationId) return;

      this.formLoading = true;

      fetch(this.route("api.integrations.financial-accounts", integrationId))
        .then((res) => res.json())
        .then((data) => {
          this.financialAccounts = data;
        })
        .finally(() => (this.formLoading = false));
    },
    submit() {
      if (!this.formIsFilledOut) return;

      this.formLoading = true;

      fetch(
        this.route("api.integrations.entries.count", {
          organizationId: this.organizationId,
          integrationId: this.integrationId,
          financialAccountId: this.financialAccountId,
          from: this.formatDateString(this.from),
          to: this.formatDateString(this.to),
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.searchResult = {
            ...data,
            from: data.from ? new Date(data.from) : null,
            to: data.to ? new Date(data.to) : null,
          };
        })
        .finally(() => (this.formLoading = false));
    },
  },
};
</script>
