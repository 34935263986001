<template>
  <v-tab-item class="fill-width min-h-104">
    <div class="fill-height fill-width">
      <v-row>
        <v-col md="4">
          <v-autocomplete
            v-model="defaultCurrencyIdProxy"
            :items="currencies"
            item-text="currencyCode"
            item-value="id"
            filled
            hide-details
            label="Standard Currency"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          <v-autocomplete
            v-model="partnerIdProxy"
            :items="partners"
            :disabled="userIsPartner"
            item-text="name"
            item-value="id"
            filled
            hide-details
            label="Partner"
          />
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
import AuthHelpers from "../../../mixins/AuthHelpers.vue";

export default {
  mixins: [AuthHelpers],
  props: {
    defaultCurrencyId: String,
    partnerId: String,
    currencies: Array,
    partners: Array,
  },
  emits: ["updateForm"],
  computed: {
    defaultCurrencyIdProxy: {
      get() {
        return this.defaultCurrencyId;
      },
      set(value) {
        this.$emit("updateForm", "defaultCurrencyId", value);
      },
    },
    partnerIdProxy: {
      get() {
        return this.partnerId;
      },
      set(value) {
        this.$emit("updateForm", "partnerId", value);
      },
    },
    userIsPartner() {
      return this.getCurrentUserRoleName() === "PARTNER ADMIN";
    },
  },
};
</script>
