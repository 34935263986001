export const addTimezoneOffset = (date) => {
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const removeTimezoneOffset = (date) => {
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - userTimezoneOffset);
};

export const normalizeISOString = (isoString) => {
  const longFormRegex =
    /\d{4}-[0-1][0-9]-[0-2][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9]\.\d*/;

  if (isoString.length === 10) {
    isoString = `${isoString}T00:00:00Z`;
  } else if (isoString.length === 19 || longFormRegex.test(isoString)) {
    isoString = `${isoString}Z`;
  }

  return isoString;
};

export const toLocaleUTCString = (date) => {
  if (Object.prototype.toString.call(date) === "[object String]") {
    date = new Date(date);
  }

  const adjustedDate = addTimezoneOffset(date);

  return `${adjustedDate.toLocaleString()} UTC`;
};

export const toLocaleUTCDateString = (date, adjustOffset = true) => {
  if (Object.prototype.toString.call(date) === "[object String]") {
    date = new Date(date);
  }

  if (adjustOffset) date = addTimezoneOffset(date);

  return date.toLocaleDateString();
};
