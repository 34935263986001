<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">GPT</h4>
      <v-spacer></v-spacer>
    </div>
    <form @submit.prevent="submit">
      <v-card elevation="4">
        <v-card-title>Settings</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="5">
              <dialog-form-number-input
                title="Temperature"
                placeholder="Default: 1"
                hint="More deterministic 0 <-> 2 More random"
                persistent-hint
                v-model="form.temperature"
                :min-value="0"
                :max-value="2"
                step=".1"
                :hide-details="false"
                class="mb-4"
              ></dialog-form-number-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="blue darken-1"
            :disabled="formLoading"
            :loading="formLoading"
            @click="submit"
          >
            <v-icon class="black--text">mdi-content-save-outline</v-icon>
            <p class="teal--text text--darken-4 mb-0">Save</p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>
<script>
import { serialize } from "object-to-formdata";

import { appLayout } from "../../util/layout";

import DialogFormNumberInput from "../../Components/Dialog/inputs/DialogFormNumberInput.vue";

export default {
  layout: appLayout({ title: "OpenAI Settings" }),
  components: { DialogFormNumberInput },
  props: {
    settings: Object,
  },
  data() {
    return {
      form: this.$inertia.form({
        temperature: this.$props.settings.temperature,
      }),
      formLoading: false,
    };
  },
  methods: {
    submit() {
      this.formLoading = true;

      this.form
        .transform((data) => serialize(data))
        .put(this.route("open-ai-settings.update"), {
          onFinish: () => {
            this.formLoading = false;
          },
        });
    },
  },
};
</script>
