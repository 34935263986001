<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Translations</h4>
      <v-spacer></v-spacer>
    </div>
    <v-card elevation="2" min-height="17.625rem" class="d-flex flex-column">
      <v-card-text class="pa-0 flex-grow-1">
        <v-row>
          <v-col align="center">
            <h5 class="text-h5 black--text">Upload terms</h5>
            <p class="text-body">
              Takes the current english language keys and uploads them to PO
              Editor
            </p>
            <v-btn
              color="primary"
              class="mt-7"
              :disabled="uploadLoading"
              :loading="uploadLoading"
              @click="uploadTerms"
            >
              <v-icon>mdi-upload</v-icon>
              Upload
            </v-btn>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col align="center">
            <h5 class="text-h5 black--text">Download new translations</h5>
            <p class="text-body">
              Fetches the translations for a given language from PO Editor and
              makes them available on the website
            </p>
            <v-row>
              <v-col cols="6" offset="3">
                <v-autocomplete
                  v-model="downloadLanguage"
                  :items="formattedLanguages"
                  solo
                  hide-details
                  dense
                  class="mt-4"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              class="mt-4"
              :loading="downloadLoading"
              :disabled="!downloadLanguage || downloadLoading"
              @click="downloadTranslations"
            >
              <v-icon>mdi-download</v-icon>
              Download
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { appLayout } from "@/util/layout";

import BackofficeEn from "../../../../../Verarca.Backoffice/client/src/locales/en";

export default {
  layout: appLayout({ title: "Translations" }),
  props: {
    languages: Array,
  },
  data() {
    return {
      downloadLanguage: null,
      uploadLoading: false,
      downloadLoading: false,
    };
  },
  computed: {
    formattedLanguages() {
      return this.languages.map((x) => ({
        text: this.getLanguageName(x),
        value: x,
      }));
    },
  },
  methods: {
    getLanguageName(languageCode) {
      switch (languageCode) {
        case "en":
          return "English";
        case "da":
          return "Danish";
        default:
          return "Unknown";
      }
    },
    uploadTerms() {
      this.uploadLoading = true;

      this.$inertia.post(
        this.route("translations.terms.upload"),
        {
          terms: {
            backoffice: BackofficeEn,
          },
        },
        {
          onFinish: () => (this.uploadLoading = false),
        }
      );
    },
    downloadTranslations() {
      if (!this.downloadLanguage) return;

      this.downloadLoading = true;

      this.$inertia.post(
        this.route("translations.languages.update"),
        {
          languageCode: this.downloadLanguage,
        },
        {
          onSuccess: () => (this.downloadLanguage = null),
          onFinish: () => (this.downloadLoading = false),
        }
      );
    },
  },
};
</script>
