<template>
  <page-modal v-model="show" :title="modalTitle" grayBackground>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
        @click="$emit('clear')"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-plus</v-icon>
          <p class="teal--text text--darken-4 mb-0">CREATE</p>
        </div>
      </v-btn>
    </template>
    <template #body>
      <v-tabs v-model="tab">
        <v-tab v-for="contentTab in tabs" :key="contentTab.id">
          {{ contentTab.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="fill-height fill-width px-4">
        <tab-general :form="form" @updateForm="updateForm" />
        <tab-modules
          :form="form"
          :depreciation-methods="depreciationMethods"
          :fiscal-years="fiscalYears"
          :start-of-depreciations="startOfDepreciations"
          :esg-modules="esgModules"
          @updateForm="updateForm"
        />
        <tab-settings
          :default-currency-id="form.defaultCurrencyId"
          :partner-id="form.partnerId"
          :currencies="currencies"
          :partners="partners"
          @updateForm="updateForm"
        />
        <tab-billing
          :paymentTierIds="paymentTierIds"
          :organizationTrialData="trialData"
          @updateForm="updateForm"
        />
        <tab-integration
          :organization-sync-period-data="organizationSyncPeriodData"
          :integration-sync-periods="integrationSyncPeriods"
          @updateForm="updateForm"
        />
      </v-tabs-items>
    </template>
    <template #actions>
      <v-btn
        v-if="organization && organization.id"
        color="blue darken-1"
        text
        :disabled="toggleActiveLoading"
        :loading="toggleActiveLoading"
        @click="toggleActive"
      >
        <v-icon class="black--text">{{
          organization && organization.active
            ? "mdi-toggle-switch-outline"
            : "mdi-toggle-switch-off-outline"
        }}</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ organization && organization.active ? "DEACTIVATE" : "ACTIVATE" }}
        </p>
      </v-btn>
      <v-btn v-if="organization && organization.id" color="blue darken-1" text>
        <v-icon class="black--text">mdi-account-group-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">USERS</p>
      </v-btn>
      <v-btn color="blue darken-1" text @click="show = false">
        <v-icon class="black--text">mdi-close</v-icon>
        <p class="teal--text text--darken-4 mb-0">CLOSE</p>
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="loading"
        :loading="loading"
        @click="saveOrganization"
      >
        <v-icon class="black--text">mdi-content-save-outline</v-icon>
        <p class="teal--text text--darken-4 mb-0">
          {{ organization && organization.id ? "SAVE" : "CREATE" }}
        </p>
      </v-btn>
    </template>
  </page-modal>
</template>
<script>
import { serialize } from "object-to-formdata";
import { stripeFreeProductId } from "../../util/constants";

import PageModal from "@/Components/PageModal.vue";
import TabGeneral from "./FormTabs/TabGeneral.vue";
import TabModules from "./FormTabs/TabModules.vue";
import TabSettings from "./FormTabs/TabSettings.vue";
import TabBilling from "./FormTabs/TabBilling.vue";
import TabIntegration from "./FormTabs/TabIntegration.vue";

export default {
  components: {
    PageModal,
    TabGeneral,
    TabModules,
    TabSettings,
    TabBilling,
    TabIntegration,
  },
  props: {
    organization: Object,
    currencies: Array,
    partners: Array,
    fiscalYears: Array,
    startOfDepreciations: Array,
    depreciationMethods: Array,
    value: Boolean,
    paymentTierIds: Object,
    organizationTrialData: Object,
    organizationSyncPeriodData: Object,
    integrationSyncPeriods: Array,
    esgModules: Array,
  },
  data() {
    const defaultCurrency = this.currencies.find((x) =>
      x.currencyCode.includes("DKK")
    );

    return {
      form: this.$inertia.form({
        taxNumber: null,
        name: null,
        address: null,
        email: null,
        contactName: null,
        contactTitle: null,
        contactEmail: null,
        adminUserEmail: null,
        defaultCurrencyId: defaultCurrency?.id ?? null,
        partnerId: null,
        defaultDepreciationMethodId: null,
        fiscalYear: null,
        defaultStartOfDepreciationId: null,
        defaultAiModel: null,
        moduleFixedAssets: false,
        moduleCo2: true,
        moduleAssessments: false,
        modulePrecursora: false,
        moduleEsgActive: false,
        moduleEsgOption: "vsme",
        moduleEsgNarrativePat: false,
        moduleEsgBusinessPartners: false,
        trialSubscriptionTierId: null,
        trialEndDate: null,
        forceTrial: false,
        syncPeriod: null,
        integrationId: null,
      }),
      tab: null,
      active: null,
      loading: false,
      toggleActiveLoading: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    modalTitle() {
      if (this.organization && this.organization.id)
        return `EDIT ORGANIZATION: ${this.organization.name}`;

      return "CREATE ORGANIZATION";
    },
    trialData() {
      if (!this.organization) {
        return null;
      }
      return this.organizationTrialData;
    },
    currentUserIsAdmin() {
      return this.$inertia.page.props.auth.isAdmin;
    },
    tabs() {
      let tabs = [
        {
          id: 1,
          name: "GENERAL",
        },
        {
          id: 2,
          name: "MODULES",
        },
        {
          id: 3,
          name: "SETTINGS",
        },
      ];

      if (!this.currentUserIsAdmin) return tabs;

      tabs = [
        ...tabs,
        {
          id: 4,
          name: "BILLING",
        },
        {
          id: 5,
          name: "INTEGRATION",
        },
      ];

      return tabs;
    },
  },
  methods: {
    setForm(organization) {
      if (!organization) {
        this.form.reset();
        this.active = null;
        return;
      }

      this.form.taxNumber = organization.taxNumber;
      this.form.name = organization.name;
      this.form.address = organization.address;
      this.form.email = organization.email;
      this.form.contactName = organization.contactName;
      this.form.contactTitle = organization.contactTitle;
      this.form.contactEmail = organization.contactEmail;
      this.form.adminUserEmail = organization.adminUser?.email;
      this.form.defaultCurrencyId = organization.settings.defaultCurrencyId;
      this.form.partnerId = organization.partnerId;
      this.form.defaultStartOfDepreciationId =
        organization.settings.defaultStartOfDepreciationId;
      this.form.fiscalYear = organization.settings.fiscalYear;
      this.form.defaultDepreciationMethodId =
        organization.settings.defaultDepreciationMethodId;
      this.form.defaultAiModel = organization.settings.defaultAiModel;
      this.form.moduleFixedAssets = false;
      this.form.moduleCo2 = organization.settings.moduleCo2;
      this.form.moduleAssessments = false;
      this.form.modulePrecursora = organization.settings.modulePrecursora;
      this.form.moduleEsgActive =
        organization.settings.moduleESGActive ?? false;
      this.form.moduleEsgOption = organization.settings.moduleESGOption ?? 0;
      this.form.moduleEsgNarrativePat =
        organization.settings.moduleESGNarrativePAT ?? false;
      this.form.moduleEsgBusinessPartners =
        organization.settings.moduleESGBusinessPartners ?? false;

      this.form.trialSubscriptionTierId = stripeFreeProductId;
      this.form.trialEndDate = null;
      this.form.forceTrial = this.organizationTrialData?.forceTrial;
      this.form.syncPeriod = null;
      this.form.integrationId = null;
      this.active = organization.active;
    },
    saveOrganization() {
      if (this.loading) return;

      this.loading = true;

      if (this.organization?.id) {
        this.form
          .transform((data) => serialize(data))
          .put(this.route("organizations.update", this.organization.id), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.$emit("clear");
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      } else {
        this.form
          .transform((data) => serialize(data))
          .post(this.route("organizations.store"), {
            preserveScroll: true,
            onSuccess: () => {
              this.show = false;
              this.setForm(null);
              this.$emit("clear");
            },
            onFinish: () => {
              this.loading = false;
            },
          });
      }
    },
    toggleActive() {
      if (this.toggleActiveLoading || !this.organization) return;

      this.toggleActiveLoading = true;

      this.$inertia
        .form({ active: !this.active })
        .transform((data) => serialize(data))
        .patch(
          this.route("organizations.active.toggle", this.organization.id),
          {
            preserveState: true,
            preserveScroll: true,
            onFinish: () => {
              this.toggleActiveLoading = false;

              this.$emit("reload", this.organization.id);
            },
          }
        );
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
  },
  watch: {
    organization: function (organization) {
      this.setForm(organization);
    },
  },
};
</script>
