<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Referral payments</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="space-between" class="d-flex align-center">
      <v-col md="3">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1 row-pointer"
      :headers="headers"
      :items="referralPayments.data"
      :server-items-length="referralPayments.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      must-sort
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
    >
      <template v-slot:item.invoiceAmountPaid="{ item }">
        <span>{{ formatCurrency(item.invoiceAmountPaid) }}</span>
      </template>
      <template v-slot:item.amountToPayout="{ item }">
        <span>{{ formatCurrency(item.amountToPayout) }}</span>
      </template>
      <template v-slot:item.availableAt="{ item }">
        <span>{{ new Date(item.availableAt).toLocaleDateString() }}</span>
      </template>
      <template #item.action="{ item }">
        <td class="fixed">
          <v-btn
            v-if="!item.paidOutAt"
            color="primary"
            @click="submitPayout(item.id)"
          >
            Payout now
          </v-btn>
          <span v-else>
            {{ new Date(item.paidOutAt).toLocaleDateString() }}
          </span>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { footerProps } from "@/util/dataTable";
import { appLayout } from "@/util/layout";
import { roundedNumberFormatter } from "../../util/formatters";
import qs from "qs";

export default {
  layout: appLayout({ title: "Partner admins" }),
  props: {
    referralPayments: Object,
    reloadFilters: Object,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      headers: [
        { text: "Kickback from (organization)", value: "organization.name" },
        { text: "Invoiced amount", value: "invoiceAmountPaid" },
        { text: "Payout to (partner)", value: "partner.name" },
        { text: "Payout amount", value: "amountToPayout" },
        { text: "Target date", value: "availableAt" },
        {
          text: "Payout",
          value: "action",
          width: 120,
          sortable: false,
          align: "center fixed",
          class: "fixed",
        },
      ],
      pagination: {
        page: this.referralPayments?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? "availableAt"],
        sortDesc: [searchParams.sortDesc !== "false"],
      },
      tableFooterProps: footerProps,
      filters: this.reloadFilters ?? {},
    };
  },
  computed: {},
  methods: {
    resetFilter() {
      this.filters = {};
    },
    changePage(options) {
      const query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(this.route("referral-payments.index", query), {
        preserveState: true,
      });
    },
    formatCurrency(number) {
      const formattedNumber = roundedNumberFormatter.format(number);

      return `${formattedNumber} DKK`;
    },
    submitPayout(referralPaymentId) {
      this.$inertia.patch(
        this.route("referral-payments.payout", { id: referralPaymentId })
      );
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = { page: 1, sortBy: ["name"], sortDesc: [false] };
        this.changePage(this.pagination);
      }, 500),
      deep: true,
    },
  },
};
</script>
