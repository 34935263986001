<template>
  <v-tab-item :value="tabValue">
    <v-row dense>
      <v-col
        cols="6"
        v-for="attribute in filteredAttributes"
        :key="attribute.id"
        class="px-4"
      >
        <attribute-link-input
          :attribute="attribute"
          :value="dynamicValues[attribute.id]"
          :second-value="getDynamicSecondValue(attribute.id)"
          :currencies="currencies"
          @input="handleInputEvent(attribute, $event)"
          @input_secondValue="
            $emit('setDynamicSecondValue', attribute.id, $event)
          "
          @decimal_changed="handleDecimalChangedEvent(attribute, $event)"
          @dateTime_changed="handleInputEvent(attribute, $event)"
        />
      </v-col>
    </v-row>
  </v-tab-item>
</template>
<script>
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";
import { fixedFieldAttributes } from "../../../util/fixedAssetData";

export default {
  components: {
    AttributeLinkInput,
  },
  props: {
    tabValue: Number,
    attributeClass: Object,
    attributes: Array,
    currencies: Array,

    getDynamicValue: Function,
    getDynamicSecondValue: Function,
  },
  emits: ["setDynamicValue", "setDynamicSecondValue"],
  computed: {
    filteredAttributes() {
      return this.attributes.filter(
        (x) =>
          x.attributeClassId == this.attributeClass.id &&
          !Object.values(fixedFieldAttributes).includes(x.id)
      );
    },
    dynamicValues() {
      let obj = {};

      this.filteredAttributes.forEach((attribute) => {
        // Triggers rerun of computed prop when we increment counter
        this.rerenderCounter;

        obj[attribute.id] = this.getDynamicValue(attribute.id);
      });

      return obj;
    },
  },
  data() {
    return {
      rerenderCounter: 0,
    };
  },
  methods: {
    handleInputEvent(attribute, value) {
      if (attribute.format === "Number") return;

      this.$emit("setDynamicValue", attribute.id, value);
    },
    handleDecimalChangedEvent(attribute, value) {
      if (attribute.format !== "Number") return;

      this.$emit("setDynamicValue", attribute.id, value);

      this.rerenderCounter++;
    },
  },
};
</script>
