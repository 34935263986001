<template>
  <div></div>
</template>
<script>
import { appLayout } from "@/util/layout";

export default {
  layout: appLayout({ title: "Settings" }),
};
</script>
