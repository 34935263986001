<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">CT AI</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row no-gutters>
      <v-col cols="12" lg="8">
        <form @submit.prevent="submit">
          <v-card elevation="4">
            <v-card-title>
              {{ form.id ? "Update rule" : "Create new rule" }}
            </v-card-title>
            <v-card-text>
              <v-row no-gutters class="align-start mt-4">
                <v-col cols="4">
                  <v-subheader>Upload file</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-file-input
                    v-if="!form.id"
                    v-model="documentFile"
                    :loading="documentFileLoading"
                    accept=".pdf"
                    prepend-icon=""
                    hide-details
                    class="rounded-0 mb-4"
                    background-color="#F7F7F7"
                    hide-selected
                    solo
                    flat
                    filled
                    light
                    @change="handleDocumentFileUpload"
                  />
                  <v-text-field
                    v-else
                    :value="form.documentName"
                    readonly
                    hide-details
                    class="rounded-0 mb-4"
                    background-color="#F7F7F7"
                    hide-selected
                    solo
                    flat
                    filled
                    light
                  />
                </v-col>
              </v-row>
              <trigger-section
                :field="form.triggerField"
                :sub-field="form.triggerSubField"
                :value="form.triggerValue"
                :notNull="form.triggerNotNull"
                :fieldOptions="form.fieldOptions"
                :subFieldOptions="getSubFieldOptions(form.triggerField)"
                @fieldChanged="form.triggerField = $event"
                @subFieldChanged="form.triggerSubField = $event"
                @valueChanged="form.triggerValue = $event"
                @notNullChanged="form.triggerNotNull = $event"
              />
              <trigger-section
                v-if="form.secondTriggerLogicOperator"
                :field="form.secondTriggerField"
                :sub-field="form.secondTriggerSubField"
                :value="form.secondTriggerValue"
                :notNull="form.secondTriggerNotNull"
                :logic-operator="form.secondTriggerLogicOperator"
                :fieldOptions="form.fieldOptions"
                :subFieldOptions="getSubFieldOptions(form.secondTriggerField)"
                @fieldChanged="form.secondTriggerField = $event"
                @subFieldChanged="form.secondTriggerSubField = $event"
                @valueChanged="form.secondTriggerValue = $event"
                @notNullChanged="form.secondTriggerNotNull = $event"
              />
              <trigger-section
                v-if="form.thirdTriggerLogicOperator"
                :field="form.thirdTriggerField"
                :sub-field="form.thirdTriggerSubField"
                :value="form.thirdTriggerValue"
                :notNull="form.thirdTriggerNotNull"
                :logic-operator="form.thirdTriggerLogicOperator"
                :fieldOptions="form.fieldOptions"
                :subFieldOptions="getSubFieldOptions(form.thirdTriggerField)"
                @fieldChanged="form.thirdTriggerField = $event"
                @subFieldChanged="form.thirdTriggerSubField = $event"
                @valueChanged="form.thirdTriggerValue = $event"
                @notNullChanged="form.thirdTriggerNotNull = $event"
              />
              <section-divider left v-if="!form.thirdTriggerLogicOperator">
                <div class="d-flex">
                  <v-btn
                    text
                    color="blue darken-1"
                    :loading="formLoading"
                    @click="addTrigger('AND')"
                  >
                    <p class="teal--text text--darken-4 mb-0">AND</p>
                  </v-btn>
                  <v-btn
                    text
                    color="blue darken-1"
                    :loading="formLoading"
                    @click="addTrigger('OR')"
                  >
                    <p class="teal--text text--darken-4 mb-0">OR</p>
                  </v-btn>
                  <v-btn
                    text
                    color="blue darken-1"
                    :loading="formLoading"
                    @click="addTrigger('EXCEPT')"
                  >
                    <p class="teal--text text--darken-4 mb-0">EXCEPT</p>
                  </v-btn>
                </div>
              </section-divider>
              <section-divider v-else full class="mt-2 mb-6"></section-divider>
              <v-row no-gutters class="align-start mt-4">
                <v-col cols="4">
                  <v-subheader>Custom extraction model</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-autocomplete
                    v-model="form.customExtractionModel"
                    :items="customExtractionModels"
                    :error-messages="form.errors.customExtractionModel"
                    hide-details="auto"
                    class="rounded-0 mb-4"
                    background-color="#F7F7F7"
                    hide-selected
                    solo
                    flat
                    filled
                    light
                  />
                </v-col>
              </v-row>
              <section-divider full class="mt-2"></section-divider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="form.id"
                text
                color="blue darken-1"
                @click="resetForm"
              >
                <v-icon class="black--text">mdi-cancel</v-icon>
                <p class="teal--text text--darken-4 mb-0">Cancel</p>
              </v-btn>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!formIsFilledOut || formLoading"
                :loading="formLoading"
                @click="submit"
              >
                <v-icon class="black--text">mdi-content-save-outline</v-icon>
                <p class="teal--text text--darken-4 mb-0">Save</p>
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
      <v-col cols="12" lg="8" class="mt-4">
        <v-card elevation="4">
          <v-card-title>Existing rules</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="existingRuleHeaders"
              :items="documentRules.data"
              :server-items-length="documentRules.totalCount"
              :options.sync="pagination"
              :footer-props="tableFooterProps"
              must-sort
              @dblclick:row="(_, { item }) => editRule(item)"
              @update:page="changePage(pagination)"
              @update:items-per-page="changePage(pagination)"
              @update:sort-by="changePage(pagination)"
              @update:sort-desc="changePage(pagination)"
            >
              <template #item.trigger="{ item }">
                <span
                  v-for="(trigger, index) in getTriggerList(item)"
                  :key="index"
                >
                  <span class="font-weight-bold">
                    {{ formatTriggerField(trigger) }}:
                  </span>
                  {{ formatTriggerValue(trigger)
                  }}{{ index + 1 < getTriggerList(item).length ? "," : "" }}
                </span>
              </template>
              <template #item.actions="{ item }">
                <v-btn small outlined color="primary" @click="editRule(item)">
                  <v-icon class="black--text">mdi-pencil</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Edit</p>
                </v-btn>
                <v-btn
                  small
                  outlined
                  class="ml-2"
                  color="primary"
                  @click="deleteRule(item)"
                >
                  <v-icon class="black--text">mdi-trash-can-outline</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Delete</p>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { appLayout } from "../../util/layout";
import { footerProps } from "@/util/dataTable";
import qs from "qs";
import { serialize } from "object-to-formdata";

import TriggerSection from "../../Components/DocumentRule/TriggerSection.vue";
import SectionDivider from "../../Components/SectionDivider.vue";

export default {
  layout: appLayout({ title: "CT AI" }),
  components: {
    TriggerSection,
    SectionDivider,
  },
  props: {
    documentRules: Object,
    customExtractionModels: Array,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      pagination: {
        page: this.assetRules?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? "ID"],
        sortDesc: [searchParams.sortDesc !== "false"],
      },
      tableFooterProps: footerProps,
      form: this.$inertia.form({
        id: null,
        triggerField: null,
        triggerSubField: null,
        triggerValue: null,
        triggerNotNull: null,
        secondTriggerField: null,
        secondTriggerSubField: null,
        secondTriggerValue: null,
        secondTriggerNotNull: null,
        secondTriggerLogicOperator: null,
        thirdTriggerField: null,
        thirdTriggerSubField: null,
        thirdTriggerValue: null,
        thirdTriggerNotNull: null,
        thirdTriggerLogicOperator: null,
        customExtractionModel: null,
        documentName: null,
        fieldOptions: [],
        subFieldOptions: [],
      }),

      documentFile: null,
      documentFileLoading: false,

      existingRuleHeaders: [
        { text: "ID", value: "referenceKey" },
        { text: "Trigger", value: "trigger" },
        { text: "CEM", value: "customExtractionModel" },
        { text: "Actions", value: "actions", sortable: false, width: 240 },
      ],
      formLoading: false,
      rerenderCounter: 0,
    };
  },
  methods: {
    submit() {
      this.formLoading = true;

      const transformedForm = this.form.transform((data) =>
        serialize(
          {
            ...data,
            triggerNotNull: data.triggerNotNull?.toString() ?? "false",
            secondTriggerNotNull:
              data.secondTriggerNotNull?.toString() ?? "false",
            thirdTriggerNotNull:
              data.thirdTriggerNotNull?.toString() ?? "false",
          },
          { indices: true, dotsForObjectNotation: true }
        )
      );

      if (this.form.id) {
        transformedForm.put(this.route("document-rules.update", this.form.id), {
          onSuccess: () => {
            this.resetForm();
          },
          onFinish: () => {
            this.formLoading = false;
          },
        });
        return;
      }

      transformedForm.post(this.route("document-rules.store"), {
        onSuccess: () => {
          this.resetForm();
        },
        onFinish: () => {
          this.formLoading = false;
        },
      });
    },
    resetForm() {
      this.form = this.$inertia.form({
        id: null,
        triggerField: null,
        triggerSubField: null,
        triggerValue: null,
        triggerNotNull: null,
        secondTriggerField: null,
        secondTriggerSubField: null,
        secondTriggerValue: null,
        secondTriggerNotNull: null,
        secondTriggerLogicOperator: null,
        thirdTriggerField: null,
        thirdTriggerSubField: null,
        thirdTriggerValue: null,
        thirdTriggerNotNull: null,
        thirdTriggerLogicOperator: null,
        customExtractionModel: null,
        documentName: null,
        fieldOptions: [],
        subFieldOptions: [],
      });
    },
    changePage(options) {
      const query = {
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("asset-rules.index", query),
        {},
        { preserveState: true }
      );
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    editRule(item) {
      this.scrollToTop();
      const confirmText =
        "Are you sure you want to continue? Any changes in the form will be discarded.";

      if (this.form.isDirty && !confirm(confirmText)) return;

      this.form = this.$inertia.form({
        id: item.id,

        triggerField: item.triggerField,
        triggerSubField: item.triggerSubField,
        triggerValue: item.triggerValue,
        triggerNotNull: item.triggerNotNull,

        secondTriggerField: item.secondTriggerField,
        secondTriggerSubField: item.secondTriggerSubField,
        secondTriggerValue: item.secondTriggerValue,
        secondTriggerNotNull: item.secondTriggerNotNull,
        secondTriggerLogicOperator: item.secondTriggerLogicOperator,

        thirdTriggerField: item.thirdTriggerField,
        thirdTriggerSubField: item.thirdTriggerSubField,
        thirdTriggerValue: item.thirdTriggerValue,
        thirdTriggerNotNull: item.thirdTriggerNotNull,
        thirdTriggerLogicOperator: item.thirdTriggerLogicOperator,

        customExtractionModel: item.customExtractionModel,

        documentName: item.documentName,
        fieldOptions: item.fieldOptions,
        subFieldOptions: item.subFieldOptions,
      });
    },
    deleteRule(item) {
      if (!confirm("Are you sure you want to delete the selected rule?"))
        return;

      this.$inertia.delete(this.route("document-rules.destroy", item.id), {
        preserveState: true,
      });

      // if editing for with item id clear form
      if (this.form.id === item.id) {
        this.resetForm();
      }
    },
    addTrigger(logicOperator) {
      if (!this.form.secondTriggerLogicOperator) {
        this.form.secondTriggerLogicOperator = logicOperator;
        return;
      }

      if (!this.form.thirdTriggerLogicOperator) {
        this.form.thirdTriggerLogicOperator = logicOperator;
        return;
      }
    },
    getTriggerList(item) {
      const triggers = [];

      if (item.triggerField) {
        triggers.push({
          field: item.triggerField,
          subField: item.triggerSubField,
          value: item.triggerValue,
          notNull: item.triggerNotNull,
        });
      }

      if (item.secondTriggerField) {
        triggers.push({
          field: item.secondTriggerField,
          subField: item.secondTriggerSubField,
          value: item.secondTriggerValue,
          notNull: item.secondTriggerNotNull,
        });
      }

      if (item.thirdTriggerField) {
        triggers.push({
          field: item.thirdTriggerField,
          subField: item.thirdTriggerSubField,
          value: item.thirdTriggerValue,
          notNull: item.thirdTriggerNotNull,
        });
      }

      return triggers;
    },
    formatTriggerField(trigger) {
      let fieldText = trigger.field;

      if (trigger.subField) {
        fieldText = `${fieldText} -> ${trigger.subField}`;
      }

      return fieldText;
    },
    formatTriggerValue(trigger) {
      if (trigger.notNull) {
        return "Not Null";
      }

      return trigger.value;
    },
    getSubFieldOptions(fieldName) {
      const field = this.form.fieldOptions.find((x) => x.name === fieldName);

      if (!field) return [];

      return this.form.subFieldOptions.filter((x) => x.index === field.index);
    },
    handleDocumentFileUpload() {
      this.documentFileLoading = true;

      const formData = serialize(
        { documentFile: this.documentFile },
        { noFilesWithArrayNotation: true }
      );

      fetch(this.route("api.document-rules.document.fields.extract"), {
        method: "POST",
        body: formData,
      })
        .then((res) => (res.ok ? res.json() : Promise.reject()))
        .then((data) => {
          this.form.documentName = data.documentName;
          this.form.fieldOptions = data.fields;
          this.form.subFieldOptions = data.subFields;
        })
        .finally(() => (this.documentFileLoading = false));
    },
  },
  computed: {
    formIsFilledOut() {
      const hasTriggerField = !!this.form.triggerField;
      const hasTriggerValue =
        (this.form.triggerValue ?? "").trim() !== "" ||
        this.form.triggerNotNull;

      const modelFilledOut =
        (this.form.customExtractionModel ?? "").trim() !== "";

      return hasTriggerField && hasTriggerValue && modelFilledOut;
    },
  },
};
</script>
