<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Partners</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="space-between" class="d-flex align-center">
      <partner-form
        v-model="showModal"
        :partner="partner"
        :partner-types="partnerTypes"
        :app-url="appUrl"
        :picture-allowed-mime-types="pictureAllowedMimeTypes"
        @clear="partner = null"
        @reload="reloadPartner"
      />
      <v-col md="3">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1 row-pointer"
      :headers="headers"
      :items="partners.data"
      :server-items-length="partners.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      :must-sort="true"
      @dblclick:row="(_, { item }) => editPartner(item)"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
    >
      <template #item.action="{ item }">
        <td class="fixed">
          <v-row class="actionrow" justify="center">
            <action-menu-button
              title="Edit"
              icon="mdi-pencil"
              @click="editPartner(item)"
            />
            <action-menu-button
              title="Delete"
              icon="mdi-delete"
              @click="deletePartner(item)"
            />
          </v-row>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { footerProps } from "@/util/dataTable";
import { appLayout } from "@/util/layout";
import ActionMenuButton from "@/Components/ActionMenuButton";
import PartnerForm from "./Form.vue";

export default {
  layout: appLayout({ title: "Partners" }),
  components: {
    ActionMenuButton,
    PartnerForm,
  },
  props: {
    reloadFilters: Object,
    partners: Object,
    partnerTypes: Array,
    appUrl: String,
    pictureAllowedMimeTypes: Array,
  },
  data() {
    const searchParams = new URLSearchParams(window.location.search);

    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Email", value: "email" },
        { text: "Tax number", value: "taxNumber" },
        {
          text: "Actions",
          value: "action",
          width: 120,
          sortable: false,
          align: "center fixed",
          class: "fixed",
        },
      ],
      pagination: {
        page: this.organizations?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") || "name"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
      },
      tableFooterProps: footerProps,
      filters: this.reloadFilters ?? {},
      partner: null,
      showModal: false,
    };
  },
  methods: {
    resetFilter() {
      this.filters = {};
    },
    changePage(options) {
      const query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("partners.index", query),
        {},
        { preserveState: true }
      );
    },
    reloadPartner(partnerId) {
      this.partner = this.partners.data.find((x) => x.id === partnerId) ?? null;

      if (!this.partner) this.showModal = false;
    },
    editPartner(partner) {
      this.partner = { ...partner };
      this.showModal = true;
    },
    deletePartner(partner) {
      if (
        !partner?.id ||
        !confirm(
          `Are you sure you want to delete the partner named '${partner.name}'?`
        )
      )
        return;

      this.$inertia.delete(this.route("partners.destroy", partner.id), {
        preserveState: true,
        preserveScroll: true,
      });
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          page: 1,
          itemsPerPage: 50,
          sortBy: ["name"],
          sortDesc: [false],
        };
        this.changePage(this.pagination);
      }, 250),
      deep: true,
    },
  },
};
</script>
