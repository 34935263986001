<template>
  <div>
    <v-row no-gutters class="d-flex align-center mb-4">
      <v-col>
        <h4 class="text-h4">Logs</h4>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row dense>
      <v-col>
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="1" class="d-flex flex-row justify-end">
        <v-btn icon @click="resetFilters" title="Clear filters">
          <v-icon>mdi-filter-off-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="logs.data"
      :server-items-length="logs.totalCount"
      :options.sync="pagination"
      :must-sort="true"
      :footer-props="tableFooterProps"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
    >
      <template #body.prepend>
        <tr>
          <td></td>
          <timestamp-filter
            v-model="filterMenus.timestamp"
            :filters="filters"
            :loading="loading"
            @submit="applyFilters"
          />
          <event-filter
            v-model="filterMenus.action"
            :filters="filters"
            :loading="loading"
            :eventTypes="eventTypes"
            @submit="applyFilters"
          />
          <source-filter
            v-model="filterMenus.source"
            :filters="filters"
            :loading="loading"
            :sources="sources"
            @submit="applyFilters"
          />
          <freeform-text-filter
            :filter="textFilters.user.filter"
            :search="textFilters.user.search"
            @input:filter="textFilters.user.filter = $event"
            @input:search="textFilters.user.search = $event"
          />
          <freeform-text-filter
            :filter="textFilters.organization.filter"
            :search="textFilters.organization.search"
            @input:filter="textFilters.organization.filter = $event"
            @input:search="textFilters.organization.search = $event"
          />
          <td></td>
        </tr>
      </template>
      <template #item.timestamp="{ item }">
        {{ formatTimestamp(item.timestamp) }}
      </template>
      <template #item.action="{ item }">
        {{ formatEventType(item.action) }}
      </template>
      <template #item.source="{ item }">
        {{ formatSource(item.source) }}
      </template>
      <template #item.organization="{ item }">
        {{ formatOrganization(item) }}
      </template>
      <template #item.info="{ item }">
        {{ formatInfo(item.action, item.parameters) }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import pickBy from "lodash/pickBy";
import throttle from "lodash/throttle";

import { DateTime } from "luxon";
import { appLayout } from "@/util/layout";
import { footerProps } from "@/util/dataTable";
import { logSourceLookup, logEventTypeLookup } from "@/util/lookup";
import { toLocaleUTCString, normalizeISOString } from "@/util/dateTime";
import { filterTypes } from "@/util/dataTypes";

import EventFilter from "./Filters/EventFilter.vue";
import TimestampFilter from "./Filters/TimestampFilter.vue";
import FreeformTextFilter from "./Filters/FreeformTextFilter.vue";
import SourceFilter from "./Filters/SourceFilter.vue";
import { formatLogEntryInfo } from "../../util/formatters";

export default {
  layout: appLayout({ title: "Logs" }),
  components: {
    TimestampFilter,
    FreeformTextFilter,
    EventFilter,
    SourceFilter,
  },
  props: {
    logs: Object,
  },
  data() {
    const searchParams = new URLSearchParams(window.location.search);

    const from = searchParams.get("filters[from]");
    const to = searchParams.get("filters[to]");
    const eventType = searchParams.get("filters[eventType]");
    const source = searchParams.get("filters[source]");

    return {
      headers: [
        {
          text: "ID",
          value: "referenceKey",
        },
        {
          text: "DATE TIME",
          value: "timestamp",
        },
        {
          text: "EVENT",
          value: "action",
          sortable: false,
        },
        {
          text: "SOURCE",
          value: "source",
        },
        {
          text: "USER",
          value: "user.name",
        },
        {
          text: "ORGANIZATION",
          value: "organization",
        },
        {
          text: "INFO",
          value: "info",
          sortable: false,
        },
      ],
      pagination: {
        page: this.logs.currentPage,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") ?? "timestamp"],
        sortDesc: [
          searchParams.get("sortDesc") === "true" ||
            searchParams.get("sortDesc") === null,
        ],
      },
      tableFooterProps: footerProps,
      filterMenus: {
        source: false,
      },
      filters: {
        from: from ? DateTime.fromISO(from).toJSDate() : null,
        to: to ? DateTime.fromISO(to).toJSDate() : null,
        eventType: eventType,
        source: source,
      },
      textFilters: {
        user: {
          filter: searchParams.get("filters[user-filter]"),
          search: searchParams.get("filters[user-search]"),
        },
        organization: {
          filter: searchParams.get("filters[organization-filter]"),
          search: searchParams.get("filters[organization-search]"),
        },
      },
      loading: false,
    };
  },
  computed: {
    sources() {
      return Object.keys(logSourceLookup);
    },
    eventTypes() {
      return Object.keys(logEventTypeLookup);
    },
  },
  methods: {
    getEventTypeFilters() {
      if (
        this.filters.eventType === null ||
        this.filters.eventType === undefined
      ) {
        return [];
      } else if (this.filters.eventType.length > 1) {
        return this.filters.eventType.join(",");
      } else {
        return this.filters.eventType[0];
      }
    },
    getSourceFilters() {
      if (this.filters.source === null || this.filters.source === undefined) {
        return [];
      } else if (this.filters.source.length > 1) {
        return this.filters.source.join(",");
      } else {
        return this.filters.source[0];
      }
    },
    changePage(options) {
      const filters = {
        ...this.filters,
        from: this.filters.from
          ? DateTime.fromJSDate(this.filters.from).toISODate()
          : null,
        to: this.filters.to
          ? DateTime.fromJSDate(this.filters.to).toISODate()
          : null,
        eventType: this.getEventTypeFilters(),
        source: this.getSourceFilters(),
      };

      const query = {
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
        filters: pickBy(filters),
      };

      this.loading = true;

      this.$inertia.get(
        this.route("logs.index", query),
        {},
        {
          preserveState: true,
          onFinish: () => {
            this.loading = false;
          },
        }
      );
    },
    formatTimestamp(timestamp) {
      timestamp = normalizeISOString(timestamp);

      return toLocaleUTCString(timestamp);
    },
    formatSource(source) {
      return logSourceLookup[source] ?? source;
    },
    formatEventType(eventType) {
      return logEventTypeLookup[eventType] ?? eventType;
    },
    formatInfo(action, parameters) {
      return formatLogEntryInfo(action, parameters);
    },
    formatOrganization(logEntry) {
      if (logEntry.organizationGroup) {
        return `${logEntry.organizationGroup.name} (Group)`;
      }

      return logEntry.organization?.name;
    },
    applyFilters(filters) {
      Object.keys(this.filterMenus).forEach((filter) => {
        this.filterMenus[filter] = false;
      });

      this.filters = {
        ...this.filters,
        ...filters,
      };
    },
    resetFilters() {
      const resetFilters = {};

      Object.keys(resetFilters).forEach((x) => {
        if (x.includes("-filter") || x.includes("-search")) return;

        resetFilters[x] = null;
      });

      this.filters = resetFilters;
    },
    handleTextFilters(newFilters) {
      let result = {};

      const {
        user: { filter: userFilter, search: userSearch },
      } = newFilters;

      const {
        organization: {
          filter: organizationFilter,
          search: organizationSearch,
        },
      } = newFilters;

      result = this.handleTextFilterAdjustments(
        result,
        "user",
        userFilter,
        userSearch
      );

      result = this.handleTextFilterAdjustments(
        result,
        "organization",
        organizationFilter,
        organizationSearch
      );

      if (Object.keys(result).length === 0) return;

      this.applyFilters(result);
    },
    handleTextFilterAdjustments(updateObject, property, filter, search) {
      const { filter: existingFilter, search: existingSearch } =
        this.textFilters[property];

      if (filter && search) {
        updateObject[`${property}-filter`] = filter;
        updateObject[`${property}-search`] = search;
      } else if (search && !filter) {
        updateObject[`${property}-filter`] = filterTypes.include;
        updateObject[`${property}-search`] = search;
        this.textFilters[property].filter = filterTypes.include;
      } else if (!search && (existingFilter || existingSearch)) {
        updateObject[`${property}-filter`] = null;
        updateObject[`${property}-search`] = null;
        this.textFilters[property] = {
          filter: null,
          search: null,
        };
      }

      return updateObject;
    },
  },
  watch: {
    filters: {
      handler: throttle(function () {
        this.pagination = {
          page: 1,
          itemsPerPage: 50,
          sortBy: [],
          sortDesc: [],
        };
        this.changePage(this.pagination);
      }, 150),
      deep: true,
    },
    textFilters: {
      handler: function (newFilters) {
        this.handleTextFilters(newFilters);
      },
      deep: true,
    },
  },
};
</script>
