<template>
  <div>
    <div v-if="title" class="d-flex flex-row align-center">
      <p class="text-caption grey--text my-2 flex-grow-1">
        {{ title }}
      </p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped></style>
