<template>
  <v-dialog v-model="show" scrollable hide-overlay max-width="500px">
    <v-card>
      <v-card-title>Select Organization</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <v-list subheader two-line>
          <v-list-item
            v-for="(organization, index) in organizations"
            :key="organization.id"
          >
            <v-list-item-avatar>
              <img
                v-if="organization.logoFile"
                :src="
                  route('api.files.content', {
                    id: organization.logoFile.id,
                    filename: organization.logoFile.originalName,
                  })
                "
                alt="Organization logo"
                class="organization-logo"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ organization.name }}</v-list-item-title>
              <v-list-item-subtitle v-if="organization.subName">
                {{ organization.subName }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                text
                :disabled="loadingIndex !== null"
                :loading="loadingIndex === index"
                @click="makeSelection(user, organization)"
              >
                <v-icon color="primary">mdi-login</v-icon>
                Login
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = false">
          <v-icon class="black--text">mdi-close</v-icon>
          <p class="teal--text text--darken-4 mb-0">CLOSE</p>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    organizations: Array,
    user: Object,
    value: Boolean,
  },
  data() {
    return {
      loadingIndex: null,
    };
  },
  methods: {
    makeSelection(user, organization) {
      this.loadingIndex = this.organizations.indexOf(organization);

      this.$emit("selection", user, organization.id);
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    show: function () {
      this.loadingIndex = null;
    },
  },
};
</script>
<style scoped>
.organization-logo {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}
</style>
