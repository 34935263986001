export const logSourceLookup = {
  ["AdminModule"]: "ADMIN MODULE",
  ["Verarca"]: "VERARCA",
  ["Prime"]: "PRIME",
};

export const logEventTypeLookup = {
  ["Login"]: "ACCOUNT LOGIN",
  ["Logout"]: "ACCOUNT LOGOUT",
  ["CreateLocation"]: "CREATE LOCATION",
  ["ResetIntegration"]: "RESET INTEGRATION",
};

export const integrationSystemLookup = {
  ["economic"]: "E-conomic",
};

export const integrationTypeLookup = {
  ["accounting"]: "Financial Accounting",
};
