<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Organization Groups</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="space-between" class="d-flex align-center">
      <organization-group-form
        v-model="showModal"
        :organizationGroup="organizationGroup"
        :organizations="organizations"
        @clear="organizationGroup = null"
        @reload="reloadOrganizationGroup"
      />
      <v-col md="3">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1 row-pointer"
      :headers="headers"
      :items="organizationGroups.data"
      :server-items-length="organizationGroups.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      :must-sort="true"
      @dblclick:row="(event, { item }) => editOrganizationGroup(item)"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
    >
      <template #item.active="{ item }">
        {{ item.active ? "Active" : "Inactive" }}
      </template>
      <template #item.action="{ item }">
        <td class="fixed">
          <v-row class="actionrow" justify="center">
            <info-popup :item="item" align="left" />
            <action-menu-button title="Log" icon="mdi-text-long" />
            <action-menu-button
              title="Edit"
              icon="mdi-pencil"
              @click="editOrganizationGroup(item)"
            />
          </v-row>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { footerProps } from "@/util/dataTable";
import { appLayout } from "@/util/layout";
import InfoPopup from "@/Components/InfoPopup";
import ActionMenuButton from "@/Components/ActionMenuButton";
import OrganizationGroupForm from "./Form.vue";

export default {
  layout: appLayout({ title: "Organization Groups" }),
  components: {
    InfoPopup,
    ActionMenuButton,
    OrganizationGroupForm,
  },
  props: {
    reloadFilters: Object,
    organizationGroups: Object,
    organizations: Array,
  },
  data() {
    const searchParams = new URLSearchParams(window.location.search);

    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Status", value: "active" },
        {
          text: "Actions",
          value: "action",
          width: 120,
          sortable: false,
          align: "center fixed",
          class: "fixed",
        },
      ],
      pagination: {
        page: this.organizationGroups?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") || "name"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
      },
      tableFooterProps: footerProps,
      filters: this.reloadFilters ?? {},
      organizationGroup: null,
      showModal: false,
    };
  },
  methods: {
    resetFilter() {
      this.filters = {};
    },
    changePage(options) {
      const query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("organization-groups.index", query),
        {},
        { preserveState: true }
      );
    },
    editOrganizationGroup(organizationGroup) {
      this.organizationGroup = organizationGroup;
      this.showModal = true;
    },
    reloadOrganizationGroup(organizationGroupId) {
      this.organizationGroup =
        this.organizationGroups.data.find(
          (x) => x.id === organizationGroupId
        ) ?? null;

      if (!this.organizationGroup) this.showModal = false;
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          page: 1,
          itemsPerPage: 50,
          sortBy: ["name"],
          sortDesc: [false],
        };
        this.changePage(this.pagination);
      }, 250),
      deep: true,
    },
  },
};
</script>
