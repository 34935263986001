<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Custom energy factors</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row no-gutters>
      <v-col cols="12">
        <form @submit.prevent="submit">
          <v-card elevation="4">
            <v-card-title>
              {{
                form.id ? "Update energy factor" : "Create new energy factor"
              }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <dialog-form-text-input
                    title="Activity name"
                    v-model="form.activityName"
                    hide-details="auto"
                    :error-messages="form.errors.activityName"
                  ></dialog-form-text-input>
                  <dialog-form-select-input
                    title="Calculation method"
                    :items="calculationMethods"
                    v-model="selectedCalculationMethod"
                    hide-details="auto"
                    class="mb-4"
                    @change="handleCalculationMethodChanged"
                  ></dialog-form-select-input>
                  <dialog-form-text-input
                    v-if="form.id"
                    title="UUID"
                    :value="form.id"
                    hide-details
                    disabled
                  ></dialog-form-text-input>
                  <dialog-form-text-input
                    title="Activity id"
                    v-model="form.activityId"
                    hide-details="auto"
                    :error-messages="form.errors.activityId"
                    class="mb-4"
                  ></dialog-form-text-input>
                  <dialog-form-select-input
                    title="Activity unit"
                    :items="activityUnits"
                    item-text="value"
                    item-value="value"
                    v-model="form.activityUnit"
                    :error-messages="form.errors.activityUnit"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                  <dialog-form-select-input
                    title="Energy type"
                    :items="selectEnergyTypes"
                    v-model="form.energyTypeId"
                    :item-text="(item) => item.name"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                  <dialog-form-number-input
                    title="Year released"
                    v-model="form.yearReleased"
                    integer-input
                    hide-details="auto"
                    :error-messages="form.errors.yearReleased"
                    class="mb-4"
                  ></dialog-form-number-input>
                  <dialog-form-text-input
                    title="Years valid"
                    v-model="form.yearsValid"
                    hide-details="auto"
                    :error-messages="form.errors.yearsValid"
                    class="mb-4"
                  ></dialog-form-text-input>
                  <v-checkbox
                    v-model="form.disabled"
                    label="Enable in Frontend"
                    hide-details="auto"
                    :true-value="false"
                    :false-value="true"
                    :error-messages="form.errors.disabled"
                    class="mt-8 py-2"
                  ></v-checkbox>
                </v-col>
                <v-col cols="5" offset="2">
                  <dialog-form-number-input
                    title="MWh"
                    v-model="form.mwh"
                    hide-details="auto"
                    :suffix="energySuffix()"
                    :error-messages="form.errors.mwh"
                    class="mb-4"
                  ></dialog-form-number-input>
                  <dialog-form-text-input
                    title="Source"
                    v-model="form.source"
                    hide-details="auto"
                    :error-messages="form.errors.source"
                    class="mb-4"
                  ></dialog-form-text-input>
                  <dialog-form-text-input
                    title="Source link"
                    v-model="form.sourceLink"
                    hide-details="auto"
                    :error-messages="form.errors.sourceLink"
                    class="mb-4"
                  ></dialog-form-text-input>
                  <dialog-form-input-wrapper title="Documentation">
                    <div
                      v-if="form.existingDocumentationFile"
                      class="d-flex flex-column"
                    >
                      <div class="d-flex flex-row align-center mb-1">
                        <a
                          class="text-decoration-none"
                          :href="
                            route('api.files.content', {
                              id: form.existingDocumentationFile.id,
                              filename:
                                form.existingDocumentationFile.originalName,
                            })
                          "
                          target="_blank"
                        >
                          <v-icon>mdi-attachment</v-icon>
                          {{ form.existingDocumentationFile.originalName }}
                        </a>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          small
                          class="ml-2"
                          @click="removeExistingDocumentation"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <v-file-input
                      prepend-icon=""
                      hide-details="auto"
                      v-model="form.documentationFile"
                      show-size
                      :rules="fileSizeRules"
                      solo
                      flat
                      filled
                      style="cursor: pointer"
                      background-color="#F7F7F7"
                      :error-messages="form.errors.documentationFile"
                      class="mb-4"
                    />
                  </dialog-form-input-wrapper>
                  <dialog-form-text-area-input
                    title="Description"
                    v-model="form.description"
                    :error-messages="form.errors.description"
                  ></dialog-form-text-area-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="form.id"
                text
                color="blue darken-1"
                @click="resetForm"
              >
                <v-icon class="black--text">mdi-cancel</v-icon>
                <p class="teal--text text--darken-4 mb-0">Cancel</p>
              </v-btn>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!formIsFilledOut || formLoading"
                :loading="formLoading"
                @click="submit()"
              >
                <v-icon class="black--text">mdi-content-save-outline</v-icon>
                <p class="teal--text text--darken-4 mb-0">Save</p>
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
      <v-col cols="12" class="mt-4">
        <v-card elevation="4">
          <v-card-title>Existing custom energy factors</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="existingFactorsHeaders"
              :items="energyFactors.data"
              :server-items-length="energyFactors.totalCount"
              :options.sync="pagination"
              :footer-props="tableFooterProps"
              @dblclick:row="(_, { item }) => editFactor(item)"
              @update:page="changePage(pagination)"
              @update:items-per-page="changePage(pagination)"
              @update:sort-by="changePage(pagination)"
              @update:sort-desc="changePage(pagination)"
            >
              <template #item.actions="{ item }">
                <v-btn small outlined color="primary" @click="editFactor(item)">
                  <v-icon class="black--text">mdi-pencil</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Edit</p>
                </v-btn>
                <v-btn
                  small
                  outlined
                  class="ml-2"
                  color="primary"
                  @click="deleteFactor(item)"
                >
                  <v-icon class="black--text">mdi-trash-can-outline</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Delete</p>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import qs from "qs";
import { serialize } from "object-to-formdata";

import { appLayout } from "../../util/layout";
import { footerProps } from "@/util/dataTable";

import DialogFormInputWrapper from "../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DialogFormTextAreaInput from "../../Components/Dialog/inputs/DialogFormTextAreaInput.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";
import DialogFormNumberInput from "../../Components/Dialog/inputs/DialogFormNumberInput.vue";

export default {
  layout: appLayout({ title: "Custom energy factors" }),
  components: {
    DialogFormInputWrapper,
    DialogFormTextInput,
    DialogFormTextAreaInput,
    DialogFormSelectInput,
    DialogFormNumberInput,
  },
  props: {
    energyFactors: Object,
    energyTypes: Array,
    currencies: Array,
    averageDataUnits: Array,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      form: this.$inertia.form({
        id: null,
        activityName: null,
        activityId: null,
        activityUnit: null,
        energyTypeId: null,
        mwh: null,
        yearReleased: null,
        yearsValid: null,
        source: null,
        sourceLink: null,
        description: null,
        existingDocumentationFile: null,
        documentationFile: null,
        documentationFileId: null,
        disabled: false,
      }),

      calculationMethods: ["Spend-based", "Average-data"],
      selectedCalculationMethod: "Spend-based",

      fileSizeRules: [
        (value) =>
          !value ||
          value.size < 30000000 ||
          "File size should be less than 26 MB",
      ],

      formLoading: false,

      pagination: {
        page: this.energyFactors?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? null],
        sortDesc: [searchParams.sortDesc === "true"],
      },
      tableFooterProps: footerProps,

      existingFactorsHeaders: [
        { text: "UUID", value: "id" },
        { text: "Activity name", value: "activityName" },
        { text: "Unit type", value: "activityUnit" },
        { text: "MWh", value: "mWh" },
        {
          text: "Number of attached emission factor",
          value: "emissionFactorCount",
        },
        { text: "", value: "actions" },
      ],
    };
  },
  computed: {
    calculationMethod() {
      var calculationMethod = "average";

      if (this.isSpendBasedMethod) {
        calculationMethod = "spend";
      } else if (this.isSupplierSpecificMethod) {
        calculationMethod = "supplier";
      }

      return calculationMethod;
    },
    activityUnits() {
      if (this.selectedCalculationMethod === "Spend-based") {
        return this.currencies.map((x) => ({
          value: x.currencyCode,
        }));
      }

      return this.averageDataUnits.map((x) => ({
        value: x.value,
      }));
    },
    selectEnergyTypes() {
      return this.energyTypes
        ? this.energyTypes.map((x) => ({
            value: x.id,
            name: x.name,
          }))
        : [];
    },
    formIsFilledOut() {
      const { activityName, activityUnit, energyTypeId, mwh } = this.form;
      return !!activityName && !!activityUnit && !!mwh && !!energyTypeId;
    },
  },
  methods: {
    removeExistingDocumentation() {
      this.form.existingDocumentationFile = null;
      this.form.documentationFileId = null;
    },
    submit() {
      this.formLoading = true;

      const transformedForm = this.form.transform((data) => {
        return serialize(data, { indices: true, dotsForObjectNotation: true });
      });

      if (this.form.id) {
        transformedForm.put(
          this.route("energy-factors.custom.energy.update", this.form.id),
          {
            onSuccess: () => {
              this.resetForm();
            },
            onFinish: () => {
              this.formLoading = false;
            },
          }
        );
      } else {
        transformedForm.post(this.route("energy-factors.custom.energy.store"), {
          onSuccess: () => {
            this.resetForm();
          },
          onFinish: () => {
            this.formLoading = false;
          },
        });
      }
    },
    resetForm() {
      this.form = this.$inertia.form({
        id: null,
        activityName: null,
        activityId: null,
        activityUnit: null,
        mwh: null,
        energyTypeId: null,
        yearReleased: null,
        yearsValid: null,
        source: null,
        sourceLink: null,
        description: null,
        existingDocumentationFile: null,
        documentationFile: null,
        documentationFileId: null,
        disabled: false,
      });

      this.selectedCalculationMethod = "Spend-based";
    },
    editFactor(item) {
      const confirmText =
        "Are you sure you want to continue? Any changes in the form will be discarded.";

      if (this.form.isDirty && !confirm(confirmText)) return;

      this.selectedCalculationMethod =
        this.getCalculationMethodFromFactor(item);

      this.form = this.$inertia.form({
        id: item.id,
        activityName: item.activityName,
        activityId: item.activityId,
        activityUnit: item.activityUnit,
        mwh: item.mWh,
        energyTypeId: item.energyTypeId,
        yearReleased: item.yearReleased,
        yearsValid: item.yearsValid,
        source: item.source,
        sourceLink: item.sourceLink,
        description: item.description,
        existingDocumentationFile: item.documentationFile,
        documentationFile: null,
        documentationFileId: item.documentationFile?.id ?? null,
        disabled: !!item.disabledAt,
      });
    },
    deleteFactor(item) {
      if (
        !confirm("Are you sure you want to delete the selected energy factor?")
      )
        return;

      this.$inertia.delete(
        this.route("energy-factors.custom.energy.destroy", item.id),
        {
          preserveState: true,
        }
      );
    },
    getCalculationMethodFromFactor(item) {
      if (this.averageDataUnits.some((x) => x.value === item.activityUnit)) {
        return "Average-data";
      }

      return "Spend-based";
    },
    energySuffix() {
      const activityUnit = this.form.activityUnit ?? "?";
      return `MWh / ${activityUnit}`;
    },
    handleCalculationMethodChanged() {
      this.form.activityUnit = null;
    },
    changePage(options) {
      const query = {
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("energy-factors.custom.energy", query),
        {},
        { preserveState: true, preserveScroll: true }
      );
    },
  },
};
</script>
