<script>
const ROLE_CLAIM_TYPE =
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

export default {
  methods: {
    getCurrentUserRoleName() {
      const claims = this.auth.user.claims;

      return (
        claims.find((x) => x.type === ROLE_CLAIM_TYPE)?.value?.toUpperCase() ??
        null
      );
    },
    currentUserHasClaim(claimType) {
      const claims = this.auth.user.claims;

      return claims.some((x) => x.type === claimType);
    },
  },
  computed: {
    auth() {
      return this.$inertia.page.props.auth;
    },
  },
};
</script>
