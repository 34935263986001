<template>
  <v-tab-item :value="tabValue">
    <v-row dense>
      <v-col cols="6" class="px-4">
        <attribute-link-input
          v-model="emissionSource"
          :attribute="emissionSourceAttribute"
          @input="handleEmissionSourceChanged"
        />
        <asset-rule-fixed-input
          v-if="emissionSource"
          v-model="ghgCategory"
          :items="filteredGhgCategories"
          label="GHG category"
        />
        <attribute-link-input
          v-model="calculationMethod"
          :attribute="calculationMethodAttribute"
          @input="handleCalculationMethodChanged"
        />
      </v-col>
      <v-col cols="6" class="px-4">
        <asset-rule-fixed-input
          v-model="sector"
          :items="sectors"
          label="Sector"
          :loading="sectorsLoading"
          @input="handleSectorChanged"
        />
        <asset-rule-fixed-input
          v-model="category"
          :items="categories"
          label="Category"
          :loading="categoriesLoading"
          @input="handleCategoryChanged"
        />
        <template v-if="!isSupplierSpecificMethod">
          <asset-rule-fixed-input
            v-model="region"
            :items="regions"
            item-text="countryName"
            label="Region"
            :loading="regionsLoading"
            @input="handleRegionChanged"
          />
          <asset-rule-fixed-input
            v-model="emissionFactorName"
            :items="emissionFactors"
            :loading="emissionFactorsLoading"
            label="Activity name"
          >
            <template #selectItem="{ item }">
              <div class="d-flex flex-column">
                <div>{{ item.name }} {{ getFactorVersionLabel(item) }}</div>
                <div class="grey--text text--lighten-1 caption">
                  {{ getEmissionsForFactor(item) }}
                </div>
              </div>
            </template>
          </asset-rule-fixed-input>
        </template>
        <template v-else>
          <attribute-link-input
            :value="emissions"
            :attribute="emissionsAttribute"
            @decimal_changed="
              $emit('setDynamicValue', emissionsAttribute.id, $event)
            "
          />
          <attribute-link-input
            v-model="documentation"
            :attribute="documentationAttribute"
          />
        </template>
      </v-col>
    </v-row>
  </v-tab-item>
</template>
<script>
import AssetRuleFixedInput from "../AssetRuleFixedInput.vue";
import AttributeLinkInput from "../../../Components/Attribute/AttributeLinkInput.vue";

import {
  assetRuleActionProperty,
  co2MethodOptions,
  fixedAttributes,
} from "../../../util/fixedAssetData";
import {
  formatEmissionFactor,
  formatEmissionFactorName,
} from "../../../util/formatters";

export default {
  components: {
    AssetRuleFixedInput,
    AttributeLinkInput,
  },
  props: {
    currentRuleId: String,
    tabValue: Number,
    initialSectors: Array,
    attributes: Array,
    ghgCategories: Array,

    rerenderCounter: Number,

    getFixedValue: Function,
    getDynamicValue: Function,
  },
  emits: ["setDynamicValue", "setFixedValue"],
  data() {
    return {
      sectors: this.initialSectors ?? [],
      categories: [],
      regions: [],
      emissionFactors: [],
      sectorsLoading: false,
      categoriesLoading: false,
      regionsLoading: false,
      emissionFactorsLoading: false,
    };
  },
  computed: {
    emissionSourceAttribute() {
      return this.getAttribute(fixedAttributes.co2EmissionSourceId);
    },
    calculationMethodAttribute() {
      return this.getAttribute(fixedAttributes.co2MethodId);
    },
    emissionsAttribute() {
      return this.getAttribute(fixedAttributes.co2EmissionsId);
    },
    documentationAttribute() {
      return this.getAttribute(fixedAttributes.co2DocumentationId);
    },
    emissionSource: {
      get() {
        return this.getDynamicValue(this.emissionSourceAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.emissionSourceAttribute.id, value);
      },
    },
    calculationMethod: {
      get() {
        return this.getDynamicValue(this.calculationMethodAttribute.id);
      },
      set(value) {
        this.$emit(
          "setDynamicValue",
          this.calculationMethodAttribute.id,
          value
        );
      },
    },
    emissions() {
      this.rerenderCounter;

      return this.getDynamicValue(this.emissionsAttribute.id);
    },
    documentation: {
      get() {
        return this.getDynamicValue(this.documentationAttribute.id);
      },
      set(value) {
        this.$emit("setDynamicValue", this.documentationAttribute.id, value);
      },
    },
    ghgCategory: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.ghgCategory);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.ghgCategory, value);
      },
    },
    sector: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.sector);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.sector, value);
      },
    },
    category: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.category);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.category, value);
      },
    },
    region: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.region);
      },
      set(value) {
        this.$emit("setFixedValue", assetRuleActionProperty.region, value);
      },
    },
    emissionFactorName: {
      get() {
        return this.getFixedValue(assetRuleActionProperty.emissionFactorName);
      },
      set(value) {
        this.$emit(
          "setFixedValue",
          assetRuleActionProperty.emissionFactorName,
          value
        );
      },
    },
    isSpendBasedMethod() {
      return this.calculationMethod === co2MethodOptions.spendBasedMethodId;
    },
    isSupplierSpecificMethod() {
      return (
        this.calculationMethod === co2MethodOptions.supplierSpecificMethodId
      );
    },
    filteredGhgCategories() {
      if (!this.emissionSource) return [];

      const categories = this.ghgCategories
        .filter((x) => x.scopeId === this.emissionSource)
        .sort((a, b) => ((a.sortOrder ?? 0) > (b.sortOrder ?? 0) ? 1 : -1));

      if (this.emissionSource === "cb483bd8-d20e-42a1-a252-c44ee12230ef") {
        this.$emit(
          "setFixedValue",
          assetRuleActionProperty.ghgCategory,
          "b0b345b4-87ad-4de5-b98f-4648d4c51edf"
        );
      }
      if (this.emissionSource === "6045ba53-b1c4-4ac0-a4be-7d4e4acc9f2b") {
        this.$emit(
          "setFixedValue",
          assetRuleActionProperty.ghgCategory,
          "e82fdc22-1ae0-4066-a662-ffa744de11a3"
        );
      }
      if (this.emissionSource === "6d9ea628-4dcf-4d77-ad8b-7e8ea4d72dde") {
        this.$emit(
          "setFixedValue",
          assetRuleActionProperty.ghgCategory,
          "a588c70c-a620-4d67-b65e-5ef7b11a8f87"
        );
      }
      return categories;
    },
    emissionFactor() {
      if (!this.emissionFactorName) return null;

      return this.emissionFactors.find((x) => x.id === this.emissionFactorName);
    },
    calculationMethodStr() {
      var _calculationMethod = "average";
      if (this.calculationMethod === co2MethodOptions.spendBasedMethodId) {
        _calculationMethod = "spend";
      } else if (
        this.calculationMethod === co2MethodOptions.supplierSpecificMethodId
      ) {
        _calculationMethod = "supplier";
      }
      return _calculationMethod;
    },
  },
  methods: {
    async fetchSectors() {
      this.sectorsLoading = true;

      return fetch(
        this.route("api.emission-sectors.index", {
          //spendBased,
          calculationMethod: this.calculationMethodStr,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.sectors = data))
        .finally(() => (this.sectorsLoading = false));
    },
    async fetchCategories(sectorId) {
      this.categoriesLoading = true;

      return fetch(
        this.route("api.emission-categories.index", {
          emissionSectorId: sectorId,
          //spendBased: this.isSpendBasedMethod,
          calculationMethod: this.calculationMethodStr,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.categories = data))
        .finally(() => (this.categoriesLoading = false));
    },
    async fetchRegions(categoryId) {
      this.regionsLoading = true;

      return fetch(
        this.route("api.emission-regions.index", {
          emissionCategoryId: categoryId,
          spendBased: this.isSpendBasedMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => (this.regions = data))
        .finally(() => (this.regionsLoading = false));
    },
    async fetchFactors(regionId) {
      this.emissionFactorsLoading = true;

      return fetch(
        this.route("api.emission-factors.index", {
          emissionSectorId: this.sector,
          emissionCategoryId: this.category,
          emissionRegionId: regionId,
          emissionFactorId: this.emissionFactorName,
          spendBased: this.isSpendBasedMethod,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionFactors = data.map((x) => ({
            ...x,
            name: formatEmissionFactorName(x),
          }));
        })
        .finally(() => (this.emissionFactorsLoading = false));
    },
    handleSectorChanged(sectorId) {
      this.category = null;
      this.region = null;
      this.emissionFactorName = null;

      this.categories = [];
      this.regions = [];
      this.emissionFactors = [];

      if (!sectorId) return;

      this.fetchCategories(sectorId, this.calculationMethodStr);
    },
    handleCategoryChanged(categoryId) {
      this.region = null;
      this.emissionFactorName = null;

      this.regions = [];
      this.emissionFactors = [];

      if (!categoryId) return;

      this.fetchRegions(categoryId);
    },
    handleRegionChanged(regionId) {
      this.emissionFactorName = null;

      this.emissionFactors = [];

      if (!regionId) return;

      this.fetchFactors(regionId);
    },
    handleCalculationMethodChanged() {
      this.sector = null;
      this.category = null;
      this.region = null;
      this.emissionFactorName = null;

      this.sectors = [];
      this.categories = [];
      this.regions = [];
      this.emissionFactors = [];

      this.fetchSectors();
    },
    handleEmissionSourceChanged() {
      this.ghgCategory = null;
    },
    getAttribute(attributeId) {
      return this.attributes.find((x) => x.id === attributeId);
    },
    getFactorVersionLabel(factor) {
      const {
        activityId,
        activityUnit,
        lcaActivity,
        yearReleased,
        yearsCalculatedFrom,
      } = this.emissionFactor ?? {};

      if (
        factor.activityId !== activityId ||
        factor.activityUnit !== activityUnit ||
        factor.lcaActivity !== lcaActivity
      )
        return "";

      const year = yearsCalculatedFrom ?? yearReleased;
      const factorYear = factor.yearsCalculatedFrom ?? factor.yearReleased;

      const labelText = factorYear > year ? "Updated" : "Outdated";

      return `(${labelText})`;
    },
    getEmissionsForFactor(factor) {
      return formatEmissionFactor(factor, true);
    },
  },
  watch: {
    currentRuleId() {
      this.fetchSectors();

      if (this.sector) {
        this.fetchCategories(this.sector);
      }

      if (this.category) {
        this.fetchRegions(this.category);
      }

      if (this.sector && this.category && this.region) {
        this.fetchFactors(this.region);
      }
    },
  },
};
</script>
