<template>
  <v-autocomplete
    v-model="partnerId"
    :items="partners"
    item-text="name"
    item-value="id"
    append-icon="mdi-unfold-more-horizontal"
    filled
    color="#00000080"
    background-color="transparent"
    hide-details
    class="partner-menu d-flex flex-row align-center"
    @update:search-input="(val) => (searchSync = val)"
    :search-input.sync="searchSync"
    auto-select-first
    :deletable-chips="false"
  >
    <template v-slot:selection="{ item }">
      <div
        v-if="searchSync == null || searchSync.length == 0"
        class="block flex-grow-1 pb-3"
        style="
          color: #000;
          font-size: 14px;
          white-space: nowrap;
          transition: opacity 0.3s linear;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          opacity: 1;
          padding-top: 16px;
        "
      >
        {{ item.name }}
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { serialize } from "object-to-formdata";
import { EventBus } from "@/eventBus";
export default {
  data() {
    return {
      searchSync: "",
    };
  },
  methods: {
    changeCurrentPartner(partnerId) {
      fetch(this.route("users.current.partners.selected.update"), {
        method: "PUT",
        body: serialize({ partnerId }),
      }).then(() => {
        this.$inertia.get(
          this.route("dashboard.index"),
          {},
          {
            onSuccess: () => {
              EventBus.$emit("partnerChanged");
            },
          }
        );
      });
    },
  },
  computed: {
    auth() {
      return this.$inertia.page.props.auth;
    },
    partnerId: {
      get() {
        return this.auth.partnerId;
      },
      set(partnerId) {
        if (!partnerId) return;
        this.searchSync = "";
        this.changeCurrentPartner(partnerId);
      },
    },
    partners() {
      return [...this.auth.partners];
    },
  },
};
</script>
<style scoped>
.partner-menu {
  z-index: 102;
}

.partner-menu:deep(.v-input__control .v-input__slot::before) {
  border-color: rgba(0, 0, 0, 0) !important;
}
.partner-menu:deep(.v-input__control .v-input__slot:hover::before) {
  border-color: rgba(0, 0, 0, 0);
}
.partner-menu:deep(.v-input__control .v-input__slot::after) {
  border-color: rgba(0, 0, 0, 0) !important;
  background-color: transparent !important;
}
.partner-menu:deep(.v-input__icon--append .v-icon) {
  transform: rotate(0deg) !important;
}
.partner-menu:deep(.v-input__slot) {
  padding: 0px 8px !important;
}
</style>
