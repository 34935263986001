<template>
  <dialog-form-input-wrapper :title="title">
    <v-text-field
      ref="numberInput"
      type="number"
      :key="rerenderCounter"
      clearable
      hide-spin-buttons
      :hide-details="hideDetails"
      class="rounded-0 mb-4"
      background-color="#F7F7F7"
      solo
      flat
      :label="placeholder"
      single-line
      filled
      :suffix="suffix"
      :hint="hint"
      :persistent-hint="persistentHint"
      :step="step"
      :disabled="disabled"
      :value="value"
      @input="$nextTick(() => handleNumberInputEvent($event))"
      @change="$emit('change', $event)"
      :error-messages="errorMessages"
      :append-icon="appendIcon"
    ></v-text-field>
  </dialog-form-input-wrapper>
</template>

<script>
import DialogFormInputWrapper from "./DialogFormInputWrapper.vue";
export default {
  components: { DialogFormInputWrapper },
  props: {
    title: String,
    placeholder: String,
    value: [String, Number],
    errorMessages: [Array, String],
    integerInput: Boolean,
    minValue: Number,
    maxValue: Number,
    suffix: String,
    hint: String,
    persistentHint: Boolean,
    hideDetails: [String, Boolean],
    step: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    appendIcon: null,
  },
  data() {
    return {
      rerenderCounter: 0,
    };
  },
  methods: {
    handleNumberInputEvent(value) {
      if (value == this.value || this.$refs.numberInput.badInput) return;

      if (isNaN(value) || value === "" || value === null) {
        this.setCurrentValue(null);
        return;
      }

      const parsedValue = this.integerInput
        ? parseInt(value)
        : parseFloat(value);

      if (this.minValue !== undefined) {
        const minValue = this.integerInput
          ? parseInt(this.minValue)
          : parseFloat(this.minValue);

        const isLowerThanMin = parsedValue < minValue;

        if (isLowerThanMin) {
          this.setCurrentValue(minValue);
          return;
        }
      }

      if (this.maxValue !== undefined) {
        const maxValue = this.integerInput
          ? parseInt(this.maxValue)
          : parseFloat(this.maxValue);

        const isHigherThanMax = parsedValue > maxValue;

        if (isHigherThanMax) {
          this.setCurrentValue(maxValue);
          return;
        }
      }

      this.setCurrentValue(parsedValue);
    },
    setCurrentValue(value) {
      this.$emit("input", value);

      this.rerenderCounter++;

      this.$nextTick(() => {
        this.$refs.numberInput.focus();
      });
    },
  },
  emits: ["update:value"],
};
</script>
