<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Data explorer</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="space-around" class="d-flex align-center">
      <v-col md="2">
        <dialog-form-select-input
          v-model="filterForm.attributeId"
          :items="attributes"
          item-text="name"
          item-value="id"
          placeholder="Attribute"
          clearable
          hide-details
          no-bottom-margin
        ></dialog-form-select-input>
      </v-col>
      <v-col md="2">
        <dialog-form-select-input
          v-model="filterForm.filter"
          :items="filterOptions"
          item-text="text"
          item-value="value"
          placeholder="Filter"
          clearable
          hide-details
          no-bottom-margin
        >
          <template v-slot:item="{ item }">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            <p class="mb-0">{{ item.text }}</p>
          </template>
          <template v-slot:selection="{ item }">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            <p class="mb-0">{{ item.text }}</p>
          </template>
        </dialog-form-select-input>
      </v-col>
      <v-col md="2">
        <dialog-form-text-input
          v-model="filterForm.filterBy"
          placeholder="Filter by"
          clearable
          single-line
          hide-details
          no-bottom-margin
        ></dialog-form-text-input>
      </v-col>
      <v-col md="2">
        <dialog-form-select-input
          v-model="filterForm.sortDesc"
          :items="sortingOptions"
          item-text="text"
          item-value="value"
          clearable
          hide-details
          no-bottom-margin
        ></dialog-form-select-input>
      </v-col>
      <v-col md="2">
        <v-btn
          color="primary"
          class="fill-width"
          large
          :disabled="!filterFormFilled"
          @click="addFilter"
        >
          Add filter
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="filters.length">
      <h6 class="text-h6 mt-2">Applied filters</h6>
      <v-row
        v-for="(filter, index) in filters"
        :key="index"
        justify="space-around"
        class="d-flex align-center"
      >
        <v-col md="2" class="py-0">
          <p class="mb-0">{{ getAttributeName(filter.attributeId) }}</p>
        </v-col>
        <v-col md="2" class="py-0">
          <p class="mb-0">{{ getFilterText(filter.filter) }}</p>
        </v-col>
        <v-col md="2" class="py-0">
          <p class="mb-0">{{ filter.filterBy }}</p>
        </v-col>
        <v-col md="2" class="py-0">
          <p class="mb-0">{{ getSortingText(filter.sortDesc) }}</p>
        </v-col>
        <v-col md="2" class="py-0">
          <v-btn icon @click="removeFilter(index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-data-table
      :headers="headers"
      :items="assetRows.data"
      :server-items-length="assetRows.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      :loading="tableLoading"
      disable-sort
      class="asset-table mt-2"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
    >
      <template #item.referenceKey="{ item }">
        <truncated-text :text="item.referenceKey" top :open-delay="250" />
        <truncated-text
          :text="item.organization.name"
          top
          :open-delay="250"
          secondary
        />
      </template>
      <template #item.assetName="{ item }">
        <truncated-text
          :text="getAttributeValue(item, fixedAttributes.generalNameId)"
          top
          :open-delay="250"
        />
        <truncated-text
          :text="item.emissionFactor?.name"
          top
          :open-delay="250"
          secondary
        />
      </template>
      <template #item.purchaseDate="{ item }">
        <truncated-text
          :text="getAttributeValue(item, fixedAttributes.generalPurchaseDateId)"
          top
          :open-delay="250"
        />
        <truncated-text
          :text="getCalculationMethod(item.emissionFactor)"
          top
          :open-delay="250"
          secondary
        />
      </template>
      <template #item.purchasePrice="{ item }">
        <truncated-text
          :text="
            getAttributeValue(item, fixedAttributes.generalPurchasePriceId)
          "
          top
          :open-delay="250"
        />
        <truncated-text
          :text="item.emissionFactor?.sector?.name"
          top
          :open-delay="250"
          secondary
        />
      </template>
      <template #item.quantity="{ item }">
        <truncated-text
          :text="getAttributeValue(item, fixedAttributes.generalQuantityId)"
          top
          :open-delay="250"
        />
        <truncated-text
          :text="item.emissionFactor?.category?.name"
          top
          :open-delay="250"
          secondary
        />
      </template>
      <template #item.unit="{ item }">
        <truncated-text
          :text="getAttributeValue(item, fixedAttributes.generalUnitTypeId)"
          top
          :open-delay="250"
        />
        <truncated-text
          :text="item.emissionFactor?.region?.countryName"
          top
          :open-delay="250"
          secondary
        />
      </template>
      <template #item.scope="{ item }">
        <truncated-text
          :text="getAttributeValue(item, fixedAttributes.co2EmissionSourceId)"
          top
          :open-delay="250"
        />
        <truncated-text
          :text="formatEmissionFactor(item.emissionFactor, true)"
          top
          :open-delay="250"
          secondary
        />
      </template>
      <template #item.status="{ item }">
        <truncated-text
          :text="
            getAttributeValue(
              item,
              fixedFieldAttributes.co2EmissionFactorStatusId
            )
          "
          top
          :open-delay="250"
        />
        <truncated-text
          :text="formatAssetEmissions(item)"
          top
          :open-delay="250"
          secondary
        />
      </template>
      <template #item.supplier="{ item }">
        <truncated-text
          :text="getAttributeValue(item, fixedAttributes.infoSupplierId)"
          top
          :open-delay="250"
        />
      </template>
      <template #item.invoice="{ item }">
        <v-btn
          v-if="item.createdFromFile"
          icon
          :href="
            route('api.files.content', {
              id: item.createdFromFile.id,
              filename: item.createdFromFile.originalName,
            })
          "
          target="_blank"
        >
          <v-icon>mdi-file-document</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import qs from "qs";
import { serialize } from "object-to-formdata";
import { appLayout } from "../../util/layout";
import { footerProps } from "@/util/dataTable";

import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";
import TruncatedText from "../../Components/TruncatedText.vue";
import Formatters from "../../mixins/Formatters.vue";
import { formatAttributeLink } from "../../util/formatters";
import {
  fixedAttributes,
  fixedFieldAttributes,
} from "../../util/fixedAssetData";

export default {
  layout: appLayout({ title: "Data explorer" }),
  mixins: [Formatters],
  components: {
    DialogFormTextInput,
    DialogFormSelectInput,
    TruncatedText,
  },
  props: {
    attributes: Array,
    currencyCodes: Array,
    assets: Object,
  },
  data() {
    return {
      abortController: new AbortController(),

      pagination: {
        page: this.assets?.currentPage ?? 1,
        itemsPerPage: this.assets?.pageSize ?? 50,
      },
      tableFooterProps: footerProps,

      tableLoading: false,

      assetRows: this.assets,

      filterForm: {
        attributeId: null,
        filter: null,
        filterBy: null,
        sortDesc: null,
      },

      filters: [],

      filterOptions: [
        {
          text: "Include",
          value: "Include",
          icon: "mdi-alphabetical-variant",
        },
        {
          text: "Do not include",
          value: "NotInclude",
          icon: "mdi-alphabetical-variant-off",
        },
        {
          text: "Starts with",
          value: "Starts",
          icon: "mdi-format-letter-starts-with",
        },
        {
          text: "Ends with",
          value: "Ends",
          icon: "mdi-format-letter-ends-with",
        },
        {
          text: "Equal to",
          value: "Equal",
          icon: "mdi-equal",
        },
        {
          text: "Does not equal to",
          value: "NotEqual",
          icon: "mdi-not-equal-variant",
        },
      ],

      sortingOptions: [
        { text: "Ascending", value: false },
        { text: "Descending", value: true },
        { text: "No sorting", value: null },
      ],

      headers: [
        { text: "Asset ID", value: "referenceKey", width: 75 },
        { text: "Asset name", value: "assetName", width: 250 },
        { text: "Purchase date", value: "purchaseDate", width: 125 },
        { text: "Purchase price", value: "purchasePrice", width: 150 },
        { text: "Quantity", value: "quantity", width: 150 },
        { text: "Unit", value: "unit", width: 100 },
        { text: "Scope", value: "scope", width: 150 },
        { text: "Status", value: "status", width: 100 },
        { text: "Supplier", value: "supplier", width: 90 },
        { text: "Invoice", value: "invoice", width: 50 },
      ],

      fixedAttributes,
      fixedFieldAttributes,
    };
  },
  computed: {
    filterFormFilled() {
      const { attributeId, filter, filterBy, sortDesc } = this.filterForm;

      const filterFilled =
        filter !== null && filterBy !== null && filterBy.trim() !== "";

      const sortingFilled = sortDesc !== null;

      return attributeId !== null && (filterFilled || sortingFilled);
    },
  },
  methods: {
    changePage(options) {
      if (this.tableLoading) {
        this.abortController.abort();
        this.abortController = new AbortController();
      }

      this.tableLoading = true;

      const query = {
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      const url = this.route("assets.index", query);

      const filters = this.filters.map((filter) => ({
        ...filter,
        attribute: this.getAttribute(filter.attributeId),
      }));

      const body = serialize(
        { filters: filters },
        { indices: true, dotsForObjectNotation: true }
      );

      const currentSignal = this.abortController.signal;

      fetch(url, {
        method: "POST",
        body: body,
        signal: currentSignal,
      })
        .then((res) => res.json())
        .then((data) => {
          this.assetRows = data.assets;
          this.pagination.page = data.assets.page;
          this.pagination.itemsPerPage = data.assets.pageSize;

          this.saveNavigationParameters();
        })
        .finally(() => {
          if (currentSignal.aborted) return;

          this.tableLoading = false;
        });
    },
    addFilter() {
      if (!this.filterFormFilled) return;

      this.filters.push({ ...this.filterForm });

      this.changePage({ ...this.pagination, page: 1 });

      this.filterForm = {
        attributeId: null,
        filter: null,
        filterBy: null,
        sortDesc: null,
      };
    },
    removeFilter(removeIndex) {
      this.filters = this.filters.filter((_, index) => index !== removeIndex);

      this.changePage({ ...this.pagination, page: 1 });
    },
    getAttribute(attributeId) {
      return this.attributes.find((x) => x.id === attributeId) ?? null;
    },
    getAttributeName(attributeId) {
      return this.getAttribute(attributeId)?.name ?? "Unknown attribute";
    },
    getFilterText(filter) {
      if (!filter) return "No filter";

      return (
        this.filterOptions.find((x) => x.value === filter)?.text ??
        "Unknown filter"
      );
    },
    getSortingText(sortDesc) {
      return (
        this.sortingOptions.find((x) => x.value === sortDesc)?.text ??
        "Unknown sort"
      );
    },
    getAttributeValue(asset, attributeId) {
      const attributeLink = asset.assetAttributeLinks.find(
        (x) => x.attributeId === attributeId
      );

      if (!attributeLink) return "";

      return formatAttributeLink(attributeLink);
    },
    getCalculationMethod(emissionFactor) {
      const activityUnit = emissionFactor?.activityUnit;

      if (!activityUnit) return "";

      if (this.currencyCodes.includes(activityUnit)) return "Spend-based";

      return "Average-data";
    },
    saveNavigationParameters() {
      const searchParams = qs.parse(window.location.search.substring(1));

      const page = this.pagination.page;
      const pageSize = this.pagination.itemsPerPage;

      if (page !== 1) {
        searchParams.page = this.pagination.page;
      } else {
        delete searchParams.page;
      }

      if (pageSize !== 50) {
        searchParams.pageSize = pageSize;
      } else {
        delete searchParams.pageSize;
      }

      const url = `${window.location.pathname}?${qs.stringify(searchParams)}`;

      window.history.replaceState({}, "", url);
    },
  },
};
</script>
<style scoped>
.asset-table:deep(table) {
  table-layout: fixed;
}

.asset-table:deep(.max-w-450) {
  max-width: 450px;
}

.asset-table:deep(.expand) {
  width: 99%;
}
</style>
