<template>
  <div>
    <v-alert v-if="$page.props.flash.error && show" dense outlined type="error">
      {{ $page.props.flash.error }}
    </v-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },
  watch: {
    "$page.props.flash": {
      handler() {
        this.show = true;
      },
      deep: true,
    },
  },
};
</script>
