<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Custom emission factors</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row no-gutters>
      <v-col cols="12">
        <form @submit.prevent="submit">
          <v-card elevation="4">
            <v-card-title>
              {{ form.id ? "Update factor" : "Create new factor" }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="5">
                  <dialog-form-text-input
                    title="Activity name *"
                    v-model="form.name"
                    hide-details="auto"
                    :error-messages="form.errors.name"
                  ></dialog-form-text-input>
                  <dialog-form-text-input
                    v-if="form.id"
                    title="UUID"
                    :value="form.id"
                    hide-details
                    disabled
                  ></dialog-form-text-input>
                  <dialog-form-select-input
                    title="Calculation method"
                    :items="calculationMethods"
                    v-model="selectedCalculationMethod"
                    hide-details="auto"
                    class="mb-4"
                    @change="handleCalculationMethodChanged"
                  ></dialog-form-select-input>
                  <dialog-form-text-input
                    title="Activity id"
                    v-model="form.activityId"
                    hide-details="auto"
                    :error-messages="form.errors.activityId"
                    class="mb-4"
                  ></dialog-form-text-input>
                  <dialog-form-select-input
                    title="Activity unit *"
                    :items="activityUnits"
                    item-text="value"
                    item-value="value"
                    v-model="form.activityUnit"
                    :error-messages="form.errors.activityUnit"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                  <dialog-form-text-input
                    title="LCA activity"
                    v-model="form.lcaActivity"
                    hide-details="auto"
                    :error-messages="form.errors.lcaActivity"
                    class="mb-4"
                  ></dialog-form-text-input>
                  <dialog-form-number-input
                    title="Year released"
                    v-model="form.yearReleased"
                    integer-input
                    hide-details="auto"
                    :error-messages="form.errors.yearReleased"
                    class="mb-4"
                  ></dialog-form-number-input>
                  <dialog-form-text-input
                    title="Years valid"
                    v-model="form.yearsValid"
                    hide-details="auto"
                    :error-messages="form.errors.yearsValid"
                    class="mb-4"
                  ></dialog-form-text-input>
                  <dialog-form-input-wrapper title="Documentation">
                    <div
                      v-if="form.existingDocumentationFile"
                      class="d-flex flex-column"
                    >
                      <div class="d-flex flex-row align-center mb-1">
                        <a
                          class="text-decoration-none"
                          :href="
                            route('api.files.content', {
                              id: form.existingDocumentationFile.id,
                              filename:
                                form.existingDocumentationFile.originalName,
                            })
                          "
                          target="_blank"
                        >
                          <v-icon>mdi-attachment</v-icon>
                          {{ form.existingDocumentationFile.originalName }}
                        </a>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          small
                          class="ml-2"
                          @click="removeExistingDocumentation"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <v-file-input
                      prepend-icon=""
                      hide-details="auto"
                      v-model="form.documentationFile"
                      show-size
                      :rules="fileSizeRules"
                      solo
                      flat
                      filled
                      style="cursor: pointer"
                      background-color="#F7F7F7"
                      :error-messages="form.errors.documentationFile"
                      class="mb-4"
                    />
                  </dialog-form-input-wrapper>
                  <v-checkbox
                    v-model="form.disabled"
                    label="Enable in Frontend"
                    hide-details="auto"
                    :true-value="false"
                    :false-value="true"
                    :error-messages="form.errors.disabled"
                    class="mt-8 py-2"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="form.aiDisabled"
                    label="Include in AI categorization"
                    hide-details="auto"
                    :true-value="false"
                    :false-value="true"
                    :error-messages="form.errors.aiDisabled"
                    class="mt-4 py-2"
                  ></v-checkbox>
                </v-col>
                <v-col cols="5" offset="2">
                  <dialog-form-select-input
                    title="Sector *"
                    :items="emissionSectors"
                    item-text="name"
                    item-value="id"
                    v-model="form.sectorId"
                    :error-messages="form.errors.sectorId"
                    hide-details="auto"
                    class="mb-4"
                    @change="handleSectorChanged"
                  ></dialog-form-select-input>
                  <dialog-form-select-input
                    title="Category *"
                    :items="emissionCategories"
                    item-text="name"
                    item-value="id"
                    v-model="form.categoryId"
                    :error-messages="form.errors.categoryId"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                  <dialog-form-select-input
                    title="Region *"
                    :items="emissionRegions"
                    item-text="countryName"
                    item-value="id"
                    v-model="form.regionId"
                    :error-messages="form.errors.regionId"
                    hide-details="auto"
                    class="mb-4"
                  ></dialog-form-select-input>
                  <dialog-form-number-input
                    title="kgCO2e-AR6 */3"
                    v-model="form.kgCo2eAr6"
                    :suffix="formattedEmissionSuffix"
                    hide-details="auto"
                    :error-messages="form.errors.kgCo2EAr6"
                    class="mb-4"
                  ></dialog-form-number-input>
                  <dialog-form-number-input
                    title="kgCO2e-AR5 */3"
                    v-model="form.kgCo2eAr5"
                    :suffix="formattedEmissionSuffix"
                    hide-details="auto"
                    :error-messages="form.errors.kgCo2EAr5"
                    class="mb-4"
                  ></dialog-form-number-input>
                  <dialog-form-number-input
                    title="kgCO2e-AR4 */3"
                    v-model="form.kgCo2eAr4"
                    :suffix="formattedEmissionSuffix"
                    hide-details="auto"
                    :error-messages="form.errors.kgCo2EAr4"
                    class="mb-4"
                  ></dialog-form-number-input>
                  <dialog-form-select-input
                    title="Scope"
                    v-model="form.scope"
                    :items="['1', '2', '3']"
                    hide-details="auto"
                    :error-messages="form.errors.scope"
                    class="mb-4"
                  ></dialog-form-select-input>
                  <dialog-form-text-input
                    title="Source *"
                    v-model="form.source"
                    hide-details="auto"
                    :error-messages="form.errors.source"
                    class="mb-4"
                  ></dialog-form-text-input>
                  <dialog-form-text-input
                    title="Source link"
                    v-model="form.sourceLink"
                    hide-details="auto"
                    :error-messages="form.errors.sourceLink"
                    class="mb-4"
                  ></dialog-form-text-input>
                  <dialog-form-text-input
                    title="Contributor"
                    v-model="form.contributor"
                    hide-details="auto"
                    :error-messages="form.errors.contributor"
                    class="mb-4"
                  ></dialog-form-text-input>
                </v-col>
                <v-col cols="12">
                  <dialog-form-text-area-input
                    title="Description"
                    v-model="form.description"
                    :error-messages="form.errors.description"
                  ></dialog-form-text-area-input>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="form.id"
                text
                color="blue darken-1"
                @click="resetForm"
              >
                <v-icon class="black--text">mdi-cancel</v-icon>
                <p class="teal--text text--darken-4 mb-0">Cancel</p>
              </v-btn>
              <v-btn
                text
                color="blue darken-1"
                :disabled="!formIsFilledOut || formLoading"
                :loading="formLoading"
                @click="submit()"
              >
                <v-icon class="black--text">mdi-content-save-outline</v-icon>
                <p class="teal--text text--darken-4 mb-0">Save</p>
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
      <v-col cols="12" class="mt-4">
        <v-card elevation="4">
          <v-card-title>Existing custom factors</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="existingFactorsHeaders"
              :items="emissionFactors.data"
              :server-items-length="emissionFactors.totalCount"
              :options.sync="pagination"
              :footer-props="tableFooterProps"
              @dblclick:row="(_, { item }) => editFactor(item)"
              @update:page="changePage(pagination)"
              @update:items-per-page="changePage(pagination)"
              @update:sort-by="changePage(pagination)"
              @update:sort-desc="changePage(pagination)"
            >
              <template #item.calculationMethod="{ item }">
                {{ getCalculationMethodFromFactor(item) }}
              </template>
              <template #item.relations="{ item }">
                {{ getEmissionRelations(item) }}
              </template>
              <template #item.kgCo2eAr6="{ item }">
                {{ formatEmissions(item) }}
              </template>
              <template #item.actions="{ item }">
                <v-btn small outlined color="primary" @click="editFactor(item)">
                  <v-icon class="black--text">mdi-pencil</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Edit</p>
                </v-btn>
                <v-btn
                  small
                  outlined
                  class="ml-2"
                  color="primary"
                  @click="deleteFactor(item)"
                >
                  <v-icon class="black--text">mdi-trash-can-outline</v-icon>
                  <p class="teal--text text--darken-4 mb-0">Delete</p>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import qs from "qs";
import { serialize } from "object-to-formdata";

import { appLayout } from "../../util/layout";
import { footerProps } from "@/util/dataTable";

import DialogFormInputWrapper from "../../Components/Dialog/inputs/DialogFormInputWrapper.vue";
import DialogFormTextInput from "../../Components/Dialog/inputs/DialogFormTextInput.vue";
import DialogFormTextAreaInput from "../../Components/Dialog/inputs/DialogFormTextAreaInput.vue";
import DialogFormSelectInput from "../../Components/Dialog/inputs/DialogFormSelectInput.vue";
import DialogFormNumberInput from "../../Components/Dialog/inputs/DialogFormNumberInput.vue";

export default {
  layout: appLayout({ title: "Custom emission factors" }),
  components: {
    DialogFormInputWrapper,
    DialogFormTextInput,
    DialogFormTextAreaInput,
    DialogFormSelectInput,
    DialogFormNumberInput,
  },
  props: {
    emissionFactors: Object,
    currencies: Array,
    averageDataUnits: Array,
  },
  data() {
    const searchParams = qs.parse(window.location.search.substring(1));

    return {
      form: this.$inertia.form({
        id: null,
        name: null,
        activityId: null,
        activityUnit: null,
        lcaActivity: null,
        yearReleased: null,
        yearsValid: null,
        sectorId: null,
        categoryId: null,
        regionId: null,
        kgCo2eAr6: null,
        kgCo2eAr5: null,
        kgCo2eAr4: null,
        scope: null,
        source: null,
        sourceLink: null,
        contributor: null,
        description: null,
        existingDocumentationFile: null,
        documentationFile: null,
        documentationFileId: null,
        disabled: false,
        aiDisabled: false,
      }),

      emissionSectors: [],
      emissionCategories: [],
      emissionRegions: [],

      calculationMethods: ["Spend-based", "Average-data"],
      selectedCalculationMethod: "Spend-based",

      fileSizeRules: [
        (value) =>
          !value ||
          value.size < 30000000 ||
          "File size should be less than 26 MB",
      ],

      formLoading: false,

      pagination: {
        page: this.emissionFactors?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.sortBy ?? null],
        sortDesc: [searchParams.sortDesc === "true"],
      },
      tableFooterProps: footerProps,

      existingFactorsHeaders: [
        { text: "UUID", value: "id" },
        { text: "Activity name", value: "name" },
        {
          text: "Calculation method",
          value: "calculationMethod",
          sortable: false,
        },
        { text: "Sector", value: "sector.name" },
        { text: "Category", value: "category.name" },
        { text: "Region", value: "region.countryName" },
        { text: "Emission factor", value: "kgCo2eAr6" },
        { text: "", value: "actions" },
      ],
    };
  },
  computed: {
    calculationMethod() {
      var calculationMethod = "average";
      if (this.isSpendBasedMethod) {
        calculationMethod = "spend";
      } else if (this.isSupplierSpecificMethod) {
        calculationMethod = "supplier";
      }
      return calculationMethod;
    },
    activityUnits() {
      if (this.selectedCalculationMethod === "Spend-based") {
        return this.currencies.map((x) => ({
          value: x.currencyCode,
        }));
      }

      return this.averageDataUnits.map((x) => ({
        value: x.value,
      }));
    },
    formattedEmissionSuffix() {
      const unit = this.form.activityUnit ?? "?";

      return `kgCO2e / ${unit}`;
    },
    formIsFilledOut() {
      const {
        name,
        activityUnit,
        sectorId,
        categoryId,
        regionId,
        kgCo2eAr6,
        kgCo2eAr5,
        kgCo2eAr4,
        source,
      } = this.form;

      return (
        !!name &&
        !!activityUnit &&
        !!sectorId &&
        !!categoryId &&
        !!regionId &&
        !!source &&
        (!!kgCo2eAr6 || !!kgCo2eAr5 || !!kgCo2eAr4)
      );
    },
  },
  methods: {
    getEmissionSectors() {
      fetch(
        this.route("api.emission-sectors.index", {
          //spendBased: this.selectedCalculationMethod === "Spend-based",
          calculationMethod: this.calculationMethod,
          unitType: this.form.activityUnit,
          withFactors: "false",
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionSectors = data;
        });
    },
    getEmissionCategories(sectorId) {
      fetch(
        this.route("api.emission-categories.index", {
          emissionSectorId: sectorId,
          //spendBased: this.selectedCalculationMethod === "Spend-based",
          calculationMethod: this.calculationMethod,
          unitType: this.form.activityUnit,
          withFactors: "false",
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionCategories = data;
        });
    },
    getEmissionRegions() {
      fetch(
        this.route("api.emission-regions.index", {
          withFactors: "false",
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.emissionRegions = data;
        });
    },
    removeExistingDocumentation() {
      this.form.existingDocumentationFile = null;
      this.form.documentationFileId = null;
    },
    submit() {
      this.formLoading = true;

      const transformedForm = this.form.transform((data) => {
        const kgCo2eAr6 = (data.kgCo2eAr6 ?? "") + "";
        const kgCo2eAr5 = (data.kgCo2eAr5 ?? "") + "";
        const kgCo2eAr4 = (data.kgCo2eAr4 ?? "") + "";

        return serialize(
          {
            ...data,
            kgCo2eAr6: kgCo2eAr6?.replace(",", "."),
            kgCo2eAr5: kgCo2eAr5?.replace(",", "."),
            kgCo2eAr4: kgCo2eAr4?.replace(",", "."),
          },
          { indices: true, dotsForObjectNotation: true }
        );
      });

      if (this.form.id) {
        transformedForm.put(
          this.route("emission-factors.custom.emission.update", this.form.id),
          {
            onSuccess: () => {
              this.resetForm();
            },
            onFinish: () => {
              this.formLoading = false;
            },
          }
        );

        return;
      }

      transformedForm.post(
        this.route("emission-factors.custom.emission.store"),
        {
          onSuccess: () => {
            this.resetForm();
          },
          onFinish: () => {
            this.formLoading = false;
          },
        }
      );
    },
    resetForm() {
      this.form = this.$inertia.form({
        id: null,
        name: null,
        activityId: null,
        activityUnit: null,
        lcaActivity: null,
        yearReleased: null,
        yearsValid: null,
        sectorId: null,
        categoryId: null,
        regionId: null,
        kgCo2eAr6: null,
        kgCo2eAr5: null,
        kgCo2eAr4: null,
        scope: null,
        source: null,
        sourceLink: null,
        contributor: null,
        description: null,
        existingDocumentationFile: null,
        documentationFile: null,
        documentationFileId: null,
        disabled: false,
        aiDisabled: false,
      });

      this.selectedCalculationMethod = "Spend-based";
    },
    editFactor(item) {
      const confirmText =
        "Are you sure you want to continue? Any changes in the form will be discarded.";

      if (this.form.isDirty && !confirm(confirmText)) return;

      this.getEmissionCategories(item.sectorId);

      this.selectedCalculationMethod =
        this.getCalculationMethodFromFactor(item);

      this.form = this.$inertia.form({
        id: item.id,
        name: item.name,
        activityId: item.activityId,
        activityUnit: item.activityUnit,
        lcaActivity: item.lcaActivity,
        yearReleased: item.yearReleased,
        yearsValid: item.yearsValid,
        sectorId: item.sectorId,
        categoryId: item.categoryId,
        regionId: item.regionId,
        kgCo2eAr6: item.kgCo2eAr6 ? item.kgCo2eAr6 + "" : null,
        kgCo2eAr5: item.kgCo2eAr5 ? item.kgCo2eAr5 + "" : null,
        kgCo2eAr4: item.kgCo2eAr4 ? item.kgCo2eAr4 + "" : null,
        scope: item.scope,
        source: item.source,
        sourceLink: item.sourceLink,
        contributor: item.contributor,
        description: item.description,
        existingDocumentationFile: item.documentationFile,
        documentationFile: null,
        documentationFileId: item.documentationFile?.id ?? null,
        disabled: !!item.disabledAt,
        aiDisabled: !!item.aiDisabledAt,
      });
    },
    deleteFactor(item) {
      if (!confirm("Are you sure you want to delete the selected factor?"))
        return;

      this.$inertia.delete(
        this.route("emission-factors.custom.emission.destroy", item.id),
        {
          preserveState: true,
        }
      );
    },
    getCalculationMethodFromFactor(item) {
      if (this.averageDataUnits.some((x) => x.value === item.activityUnit)) {
        return "Average-data";
      }

      return "Spend-based";
    },
    formatEmissions(item) {
      let factor = item.kgCo2eAr6;

      if (!factor) factor = item.kgCo2eAr5;
      if (!factor) factor = item.kgCo2eAr4;
      if (!factor) factor = 0;

      const activityUnit = item.activityUnit ?? "Unknown";

      return `${factor} kgCO2e/${activityUnit}`;
    },
    handleSectorChanged(value) {
      this.form.categoryId = null;

      this.emissionCategories = [];

      if (!value) return;

      this.getEmissionCategories(value);
    },
    handleCalculationMethodChanged() {
      this.form.activityUnit = null;
      this.form.sectorId = null;
      this.form.categoryId = null;

      this.emissionCategories = [];
    },
    changePage(options) {
      const query = {
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("emission-factors.custom.emissions", query),
        {},
        { preserveState: true, preserveScroll: true }
      );
    },
  },
  mounted() {
    this.getEmissionSectors();
    this.getEmissionRegions();
  },
};
</script>
