function getKey(parent, property) {
  return parent ? parent + "[" + property + "]" : property;
}

function appendToFormData(formData, key, value) {
  if (value instanceof Date) {
    return formData.append(key, value.toISOString());
  }

  if (value instanceof File) {
    return formData.append(key, value, value.name);
  }

  if (typeof value === "boolean") {
    return formData.append(key, value ? "1" : "0");
  }

  if (value === null) {
    return formData.append(key, "");
  }

  if (typeof value !== "object") {
    return formData.append(key, value);
  }

  objectToFormData(value, formData, key);
}

export function objectToFormData(
  object,
  formData = new FormData(),
  parent = null
) {
  if (object === null || object === "undefined" || object.length === 0) {
    return formData.append(parent, object);
  }

  for (const property in object) {
    if (Object.prototype.hasOwnProperty.call(object, property)) {
      // dotnet mvc must be cannot be files[0], files[0]
      // must be files,files
      // See https://github.com/dotnet/aspnetcore/issues/12223
      var key = getKey(parent, property);

      if (
        parent != null &&
        Array.isArray(object[property]) &&
        isAllFiles(object[property])
      ) {
        key = `${parent}.${property}`;
      }

      if (parent != null && isFile(object[property])) {
        key = parent;
      }

      appendToFormData(formData, key, object[property]);
    }
  }

  return formData;
}

export function isFile(object) {
  return object instanceof File || object instanceof FileList;
}

export function isAllFiles(object) {
  return Object.keys(object).every((key) => isFile(object[key]));
}

export function hasFiles(object) {
  for (const property in object) {
    if (hasFilesDeep(object[property])) {
      return true;
    }
  }

  return false;
}

export function hasFilesDeep(object) {
  if (object === null) {
    return false;
  }

  if (typeof object === "object") {
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        if (hasFilesDeep(object[key])) {
          return true;
        }
      }
    }
  }

  if (Array.isArray(object)) {
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        return hasFilesDeep(object[key]);
      }
    }
  }

  return isFile(object);
}
