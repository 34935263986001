<template>
  <v-tab-item :value="tabValue" :key="tabValue">
    <div class="fill-height fill-width">
      <v-row>
        <v-col cols="5">
          <v-row dense>
            <v-col>
              <span>Discount</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-checkbox
                :input-value="discount"
                label="Enable"
                :error-messages="formErrors.discount"
                @change="updateForm('discount', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Discount percentage</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="discountPercentage"
                :disabled="!discount"
                solo
                dense
                type="number"
                step="1"
                max="100"
                min="0"
                suffix="%"
                :error-messages="formErrors.discountPercentage"
                @input="
                  updateForm('discountPercentage', $event ? $event * 1 : null)
                "
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col offset="2" cols="5">
          <v-row dense>
            <v-col>
              <span>Kickback</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-checkbox
                :input-value="kickback"
                label="Enable"
                :error-messages="formErrors.kickback"
                @change="updateForm('kickback', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Kickback percentage</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="kickbackPercentage"
                :disabled="!kickback"
                solo
                dense
                type="number"
                step="1"
                max="100"
                min="0"
                suffix="%"
                :error-messages="formErrors.kickbackPercentage"
                @input="
                  updateForm('kickbackPercentage', $event ? $event * 1 : null)
                "
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Kickback cutoff</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="kickbackCutoffInMonths"
                solo
                dense
                type="number"
                step="1"
                max="100"
                min="0"
                suffix="months"
                pattern="\d*"
                :error-messages="formErrors.kickbackCutoffInMonths"
                @input="
                  updateForm(
                    'kickbackCutoffInMonths',
                    $event ? $event * 1 : null
                  )
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    tabValue: Number,
    discount: Boolean,
    discountPercentage: Number,
    kickback: Boolean,
    kickbackPercentage: Number,
    kickbackCutoffInMonths: Number,
    formErrors: Object,
  },
  emits: ["updateForm"],
  methods: {
    updateForm(formKey, value) {
      this.$emit(`update:${formKey}`, value);
    },
  },
};
</script>
