<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h4 class="text-h4">Integrations</h4>
      <v-spacer></v-spacer>
    </div>
    <v-row justify="space-between" class="d-flex align-center">
      <v-spacer />
      <v-col md="3">
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          label="Search"
          clearable
          single-line
          dense
          solo
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1 row-pointer integration-table"
      :headers="headers"
      :items="integrations.data"
      :server-items-length="integrations.totalCount"
      :options.sync="pagination"
      :footer-props="tableFooterProps"
      :must-sort="true"
      @update:page="changePage(pagination)"
      @update:items-per-page="changePage(pagination)"
      @update:sort-by="changePage(pagination)"
      @update:sort-desc="changePage(pagination)"
    >
      <template #[`item.organization.name`]="{ value }">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <span
              v-on="on"
              v-bind="attrs"
              class="d-inline-block text-truncate fill-width"
            >
              {{ value }}
            </span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>
      <template #item.name="{ value }">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <span
              v-on="on"
              v-bind="attrs"
              class="d-inline-block text-truncate fill-width"
            >
              {{ value }}
            </span>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>
      <template #item.system="{ value }">
        {{ formatIntegrationSystem(value) }}
      </template>
      <template #item.disabledAt="{ value }">
        {{ value ? "Inactive" : "Active" }}
      </template>
      <template #item.lastSynchronizedAt="{ item }">
        {{
          item.lastSynchronizedAt ? formatDate(item.lastSynchronizedAt) : "N/A"
        }}
      </template>
      <template #item.entryCount="{ value }">
        {{ formatEntryCount(value) }}
      </template>
      <template #item.totalFileBytes="{ value }">
        {{ formatMegabytes(value) }}
      </template>
      <template #item.apiToken="{ item, value }">
        <div v-if="value" class="d-flex fill-width align-center">
          <span
            v-on="on"
            v-bind="attrs"
            class="d-inline-block text-truncate fill-width"
            style="width: 90px"
          >
            {{ value }}
          </span>
          <v-btn icon class="ml-3" @click="copyIntegrationToken(item)">
            <v-icon>
              {{
                selectedTokenIntegrationId === item.id
                  ? "mdi-check"
                  : "mdi-content-copy"
              }}
            </v-icon>
          </v-btn>
        </div>
      </template>
      <template #item.action="{ item }">
        <td class="fixed">
          <v-row class="actionrow" justify="center">
            <info-popup :item="item" align="left" />
            <action-menu-button
              title="Users"
              icon="mdi-account-group-outline"
            />
            <action-menu-button title="Log" icon="mdi-text-long" />
            <action-menu-button title="Edit" icon="mdi-pencil" />
          </v-row>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import pickBy from "lodash/pickBy";
import { footerProps } from "@/util/dataTable";
import { appLayout } from "@/util/layout";
import {
  formatMegabytes,
  formatDate,
  formatIntegrationSystem,
  roundedNumberFormatter,
} from "@/util/formatters";
import InfoPopup from "@/Components/InfoPopup";
import ActionMenuButton from "@/Components/ActionMenuButton";

export default {
  layout: appLayout({ title: "Integrations" }),
  components: {
    InfoPopup,
    ActionMenuButton,
  },
  props: {
    reloadFilters: Object,
    integrations: Object,
  },
  data() {
    const searchParams = new URLSearchParams(window.location.search);

    return {
      headers: [
        { text: "ID", value: "referenceKey", width: 64 },
        {
          text: "Organization",
          value: "organization.name",
          cellClass: "max-w-192",
          width: 192,
        },
        { text: "Name", value: "name", cellClass: "max-w-192", width: 192 },
        { text: "System", value: "system", width: 128 },
        { text: "Last updated", value: "lastSynchronizedAt", width: 128 },
        {
          text: "Entries - 12 months",
          value: "entryCount",
          width: 128,
          sortable: false,
        },
        {
          text: "Data - 12 months",
          value: "totalFileBytes",
          width: 128,
          sortable: false,
        },
        { text: "Token", value: "apiToken", width: 128, sortable: false },
        { text: "Status", value: "disabledAt", width: 64 },
        {
          text: "Actions",
          value: "action",
          width: 120,
          sortable: false,
          align: "center fixed",
          class: "fixed",
        },
      ],
      pagination: {
        page: this.integrations?.currentPage ?? 1,
        itemsPerPage: 50,
        sortBy: [searchParams.get("sortBy") || "organization.name"],
        sortDesc: [searchParams.get("sortDesc") === "true"],
      },
      tableFooterProps: footerProps,
      filters: this.reloadFilters ?? {},

      selectedTokenIntegrationId: null,
    };
  },
  methods: {
    formatMegabytes,
    formatDate,
    formatIntegrationSystem,
    formatEntryCount(entryCount) {
      return roundedNumberFormatter.format(entryCount);
    },
    changePage(options) {
      const query = {
        filters: pickBy(this.filters),
        sortBy: options.sortBy[0],
        sortDesc: options.sortDesc[0],
        page: options.page,
        pageSize: options.itemsPerPage,
      };

      this.$inertia.get(
        this.route("integrations.index", query),
        {},
        { preserveState: true }
      );
    },
    copyIntegrationToken(integration) {
      if (!integration?.apiToken) return;

      window.navigator.clipboard.writeText(integration.apiToken);

      this.selectedTokenIntegrationId = integration.id;

      setTimeout(() => (this.selectedTokenIntegrationId = null), 2000);
    },
  },
  watch: {
    filters: {
      handler: debounce(function () {
        this.pagination = {
          ...this.pagination,
          page: 1,
          itemsPerPage: 50,
        };
        this.changePage(this.pagination);
      }, 250),
      deep: true,
    },
  },
};
</script>
<style scoped>
.integration-table:deep(.max-w-192) {
  max-width: 192px;
}
</style>
