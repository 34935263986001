<template>
  <dialog-form-input-wrapper :title="title" :ai-status="aiStatus">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
      dark
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="rounded-0 textfield"
          background-color="#F7F7F7"
          solo
          flat
          :label="placeholder"
          single-line
          filled
          :hide-details="true"
          v-model="date"
          readonly
          v-bind="attrs"
          v-on="on"
          prepend-inner-icon="mdi-calendar"
          color="#000"
          :clearable="clearable"
          @click:prepend-inner="menu = true"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        @input="menu = false"
        scrollable
        :disabled="disabled"
        :min="minDate"
        :max="maxDate"
        color="#F25D3B"
        light
      >
      </v-date-picker>
    </v-menu>
  </dialog-form-input-wrapper>
</template>

<script>
import DialogFormInputWrapper from "./DialogFormInputWrapper.vue";
import { addTimezoneOffset, removeTimezoneOffset } from "@/util/dateTime";

export default {
  components: { DialogFormInputWrapper },
  props: {
    title: String,
    placeholder: String,
    aiStatus: String,
    value: Date,
    disabled: Boolean,
    min: Date,
    max: Date,
    clearable: Boolean,
  },
  data() {
    return {
      menu: false,
      date: this.convertDate(this.value),
      minDate: this.convertDate(this.min),
      maxDate: this.convertDate(this.max),
    };
  },
  methods: {
    convertDate(value) {
      if (typeof value === "undefined" || value === null) {
        return "";
      }

      const normalizedDate = removeTimezoneOffset(new Date(value));

      return normalizedDate.toISOString().split("T")[0];
    },
  },
  watch: {
    date(to) {
      const value = to ? addTimezoneOffset(new Date(to)) : null;

      this.$emit("change", value);
      this.$emit("input", value);
    },
    value(to) {
      this.date = this.convertDate(to);
    },
    min(to) {
      this.minDate = this.convertDate(to);
    },
    max(to) {
      this.maxDate = this.convertDate(to);
    },
  },
};
</script>

<style lang="scss" scoped>
.textfield:deep(input) {
  height: 100%;
  max-height: none;
}
</style>
