<template>
  <v-dialog
    style="z-index: 20001"
    v-model="show"
    max-width="80vw"
    hide-overlay
    persistent
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind:on="on" v-bind:attrs="attrs"></slot>
    </template>
    <v-card
      style="min-height: 470px"
      class="d-flex flex-column"
      :class="grayBackground ? 'gray-background' : 'base-background-color'"
    >
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text class="flex-grow-1 pb-0">
        <slot name="body"></slot>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row>
          <slot name="actions:start"></slot>
          <v-spacer></v-spacer>
          <slot name="actions"></slot>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    title: String,
    grayBackground: Boolean,
  },
  emits: ["input"],
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
