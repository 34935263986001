<template>
  <v-tab-item>
    <div class="fill-height fill-width">
      <v-row dense>
        <v-col cols="5">
          <v-row dense>
            <v-col>
              <span>Tax number</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="form.taxNumber"
                solo
                dense
                type="text"
                :error-messages="form.errors.taxNumber"
                @input="updateForm('taxNumber', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Organization name</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="form.name"
                solo
                dense
                type="text"
                :error-messages="form.errors.name"
                @input="updateForm('name', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Organization address</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="form.address"
                solo
                dense
                type="text"
                :error-messages="form.errors.address"
                @input="updateForm('address', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Organization email</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="form.email"
                solo
                dense
                type="email"
                :error-messages="form.errors.email"
                @input="updateForm('email', $event)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5" offset="2">
          <v-row dense>
            <v-col>
              <span>Primary contact name</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="form.contactName"
                solo
                dense
                type="text"
                :error-messages="form.errors.contactName"
                @input="updateForm('contactName', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Primary contact title</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="form.contactTitle"
                solo
                dense
                type="text"
                :error-messages="form.errors.contactTitle"
                @input="updateForm('contactTitle', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Primary contact email</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="form.contactEmail"
                solo
                dense
                type="email"
                :error-messages="form.errors.contactEmail"
                @input="updateForm('contactEmail', $event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <span>Admin user email</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                :value="form.adminUserEmail"
                solo
                dense
                type="email"
                :error-messages="form.errors.adminUserEmail"
                @input="updateForm('adminUserEmail', $event)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: {
    form: Object,
  },
  emits: ["updateForm"],
  methods: {
    updateForm(formKey, value) {
      this.$emit("updateForm", formKey, value);
    },
  },
};
</script>
