<template>
  <div class="flex d-flex justify-center align-center relative">
    <inertia-link id="back-arrow" :href="route('login')">
      <v-icon x-large>mdi-arrow-left</v-icon>
    </inertia-link>

    <v-form class="col-10 col-md-7" @submit.prevent="submit">
      <h3 class="text-h4 cyan--text text--darken-4 font-weight-light">
        Create new password
      </h3>
      <p class="caption mb-6">
        Please enter a new password that you want to use with your account.
      </p>
      <input
        type="text"
        name="email"
        autocomplete="username email"
        :value="userEmail"
        class="d-none"
      />
      <v-text-field
        v-model="form.password"
        label="Password"
        name="password"
        type="password"
        :error-messages="form.errors.password"
        autocomplete="new-password"
      />
      <v-text-field
        v-model="form.confirmPassword"
        label="Confirm password"
        name="password"
        type="password"
        :error-messages="form.errors.confirmPassword"
        autocomplete="new-password"
      />
      <div class="d-flex justify-end align-center mt-4">
        <v-btn type="submit" large color="primary" :disabled="form.processing">
          Update
        </v-btn>
      </div>
      <v-alert
        type="error"
        :class="{ hidden: !form.errors.alert }"
        class="mt-8"
      >
        {{ form.errors.alert }}
      </v-alert>
    </v-form>
  </div>
</template>

<script>
import Layout from "@/Shared/AuthLayout";
import { objectToFormData } from "@/util/formData";

export default {
  metaInfo: { title: "Reset password" },
  layout: Layout,
  props: {
    userId: String,
    resetToken: String,
    userEmail: String,
    redirectToApp: Boolean,
    redirectUrl: String,
  },
  data() {
    return {
      form: this.$inertia.form({
        userId: this.userId,
        resetToken: this.resetToken,
        password: null,
        confirmPassword: null,
        redirectToApp: this.redirectToApp,
      }),
    };
  },
  methods: {
    submit() {
      this.form
        .transform((data) =>
          objectToFormData({
            ...data,
            redirectToApp: data.redirectToApp ? "true" : "false",
          })
        )
        .post(this.route("password.update"), {
          onSuccess: () => {
            if (!this.redirectUrl) return;

            window.location.href = this.redirectUrl;
          },
        });
    },
  },
};
</script>
<style scoped>
.relative {
  position: relative;
}

#back-arrow {
  position: absolute;
  top: 0;
  left: 0;
  text-decoration: none;
}

.hidden {
  visibility: hidden;
}
</style>
